import { ELenderAPIType } from '../../interface';
import CASProposal from '.';
import { createTypeSafeIds } from '../../utils';

export const getCASIds = () => {
  return createTypeSafeIds(CASProposal, (_) => ({
    assets: [
      {
        vat: {
          FieldDefinitionId: 21166,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        model: {
          FieldDefinitionId: 18310,
          isReadonly: false,
          required: true
        },
        quantity: {
          FieldDefinitionId: 18313,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          },
          required: true
        },
        balloonAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        nonVatItemsAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        costExVat: {
          FieldDefinitionId: 21167,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          },
          required: true
        },
        isNewAsset: {
          FieldDefinitionId: 18311,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['New', 'Used']
          }
        },
        supplierId: {
          FieldDefinitionId: 21944,
          isReadonly: false,
          required: true
        },
        description: {
          FieldDefinitionId: 18308,
          isReadonly: false,
          required: true
        },
        manufacturer: {
          FieldDefinitionId: 18309,
          isReadonly: false,
          required: true
        },
        yearOfManufacture: {
          FieldDefinitionId: 18312,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        }
      }
    ],
    remoteId: {
      FieldDefinitionId: 0,
      isReadonly: true,
      info: 'This field is filled out by the Funder'
    },
    sourceId: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: true,
      forcedValue: '123456'
    },
    directors: [
      {
        nameFirst: {
          FieldDefinitionId: 18420,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [
              ELenderAPIType.EntityChange,
              ELenderAPIType.ConcatenatedBySpace
            ],
            newIdList: [
              { entityType: 'Solo Trader', FieldDefinitionId: 23497 },
              { entityType: 'Partnership', FieldDefinitionId: 23977 },
              { entityType: 'Private Individual', FieldDefinitionId: 21810 }
            ]
          }
        },
        email: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 255,
              pattern:
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
              message: 'Please enter a valid email address'
            }
          }
        },
        nameLast: {
          FieldDefinitionId: 18427,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [
              ELenderAPIType.EntityChange,
              ELenderAPIType.ConcatenatedBySpace
            ],
            newIdList: [
              { entityType: 'Solo Trader', FieldDefinitionId: 23497 },
              { entityType: 'Partnership', FieldDefinitionId: 23977 },
              { entityType: 'Private Individual', FieldDefinitionId: 21810 }
            ]
          }
        },
        address: {
          addressLine1: {
            FieldDefinitionId: 18414,
            isReadonly: false,
            required: true
          },
          addressLine2: {
            FieldDefinitionId: 18429,
            isReadonly: false
          },
          city: {
            FieldDefinitionId: 18417,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                { entityType: 'Solo Trader', FieldDefinitionId: 23684 },
                { entityType: 'Partnership', FieldDefinitionId: 23984 },
                { entityType: 'Private Individual', FieldDefinitionId: 23962 }
              ]
            }
          },
          country: {
            FieldDefinitionId: 0,
            isReadonly: true,
            forcedValue: 'United Kingdom'
          },
          postCode: {
            FieldDefinitionId: 18425,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                { entityType: 'Solo Trader', FieldDefinitionId: 23684 },
                { entityType: 'Partnership', FieldDefinitionId: 23984 },
                { entityType: 'Private Individual', FieldDefinitionId: 23962 }
              ]
            }
          },
          date: {
            FieldDefinitionId: 23700,
            isReadonly: false,
            FieldDefinition: {
              type: [
                ELenderAPIType.EntityChange,
                ELenderAPIType.DateTime,
                ELenderAPIType.Requirement
              ],
              newIdList: [
                { entityType: 'Solo Trader', FieldDefinitionId: 23972 },
                { entityType: 'Private Individual', FieldDefinitionId: 23963 }
              ],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          occupancy: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['owner', 'tenant']
            }
          }
        },
        typename: {
          FieldDefinitionId: 23702,
          isReadonly: false,
          required: true,
          forcedValue: 'director',
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Solo Trader',
                FieldDefinitionId: 0,
                forcedValue: 'proprietor'
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 0,
                forcedValue: 'partner'
              }
            ]
          }
        },
        dateOfBirth: {
          FieldDefinitionId: 18416,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [
              ELenderAPIType.EntityChange,
              ELenderAPIType.DateTime,
              ELenderAPIType.Requirement
            ],
            newIdList: [
              { entityType: 'Solo Trader', FieldDefinitionId: 23501 },
              { entityType: 'Partnership', FieldDefinitionId: 23980 },
              { entityType: 'Private Individual', FieldDefinitionId: 21811 }
            ],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        isGuarantor: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },
        nationality: {
          FieldDefinitionId: 18422,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              { entityType: 'Solo Trader', FieldDefinitionId: 23502 },
              { entityType: 'Partnership', FieldDefinitionId: 23981 },
              { entityType: 'Private Individual', FieldDefinitionId: 23968 }
            ]
          }
        },
        contactNumber: {
          FieldDefinitionId: 23704,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.EntityChange, ELenderAPIType.Phone],
            newIdList: [
              { entityType: 'Solo Trader', FieldDefinitionId: 23500 },
              { entityType: 'Partnership', FieldDefinitionId: 23979 },
              { entityType: 'Private Individual', FieldDefinitionId: 23624 }
            ]
          }
        },
        consentedToSearch: {
          FieldDefinitionId: 21397,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        }
      }
    ],
    suppliers: [
      {
        id: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        name: {
          FieldDefinitionId: 18412,
          isReadonly: false,
          required: true
        },
        type: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['LLP', 'LTD', 'partnership', 'PLC', 'sole trader']
          }
        },
        address: {
          addressLine1: {
            FieldDefinitionId: 20699,
            isReadonly: false,
            required: true,
            info: 'If an address is provided, please break it down into the appropriate fields.'
          },
          city: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          country: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          postCode: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          }
        }
      }
    ],
    typename: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: true,
      forcedValue: 'Proposal'
    },
    background: {
      FieldDefinitionId: 21641,
      required: true,
      isReadonly: false
    },
    salesPerson: {
      name: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        forcedValue: 'Asset Finance Solutions UK Ltd'
      },
      email: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        forcedValue: 'BrokerEmail',
        FieldDefinition: {
          type: ELenderAPIType.Requirement,
          requirement: {
            maxLength: 255,
            pattern:
              '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            message: 'Please enter a valid email address'
          }
        }
      },
      contactNumber: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Phone
        }
      }
    },
    brokerReference: {
      FieldDefinitionId: 0,
      isReadonly: false
    },
    customerCompany: {
      name: {
        FieldDefinitionId: 18317,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.EntityChange,
          newIdList: [
            { entityType: 'Solo Trader', FieldDefinitionId: 21866 },
            { entityType: 'Partnership', FieldDefinitionId: 21866 },
            { entityType: 'Private Individual', FieldDefinitionId: 21810 }
          ]
        }
      },
      type: {
        FieldDefinitionId: 21745,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: [
            'CIC',
            'LLP',
            'LTD',
            'partnership',
            'PLC',
            'Public Sector',
            'RC',
            'social club',
            'sole trader',
            'Unlimited'
          ]
        }
      },
      address: {
        addressLine1: {
          FieldDefinitionId: 18431,
          isReadonly: false,
          required: true
        },
        addressLine2: {
          FieldDefinitionId: 18432,
          isReadonly: false
        },
        city: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              { entityType: 'Solo Trader', FieldDefinitionId: 23662 },
              { entityType: 'Partnership', FieldDefinitionId: 23662 },
              { entityType: 'Private Individual', FieldDefinitionId: 23962 }
            ]
          }
        },
        country: {
          FieldDefinitionId: 18437,
          isReadonly: false
        },
        postCode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true
        },
        typename: {
          FieldDefinitionId: 18430,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['both', 'registered', 'trading']
          }
        }
      },
      contactNameFirst: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true
      },
      contactNameLast: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true
      },
      contactNumber: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Phone
        }
      },
      contactEmail: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Requirement,
          requirement: {
            maxLength: 255,
            pattern:
              '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            message: 'Please enter a valid email address'
          }
        }
      },
      registrationNumber: {
        FieldDefinitionId: 18315,
        isReadonly: false
      },
      dateTrading: {
        FieldDefinitionId: 18450,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [
            ELenderAPIType.EntityChange,
            ELenderAPIType.DateTime,
            ELenderAPIType.Requirement
          ],
          newIdList: [
            { entityType: 'Solo Trader', FieldDefinitionId: 23533 },
            { entityType: 'Partnership', FieldDefinitionId: 23533 },
            { entityType: 'Private Individual', FieldDefinitionId: 23533 }
          ],
          requirement: {
            pattern: '^\\d{4}-\\d{2}-\\d{2}$'
          }
        }
      }
    },
    facility: {
      fees: [
        {
          name: {
            FieldDefinitionId: 0,
            isReadonly: true,
            forcedValue: 'option_to_purchase'
          },
          amount: {
            FieldDefinitionId: 23868,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          }
        },
        {
          name: {
            FieldDefinitionId: 0,
            isReadonly: true,
            forcedValue: 'documentation'
          },
          amount: {
            FieldDefinitionId: 23864,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          }
        }
      ],
      term: {
        frequency: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['Monthly', 'Quarterly', 'Annually']
          }
        },
        number_of_advance_installments: {
          FieldDefinitionId: 18348,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        number_of_periodic_installments: {
          FieldDefinitionId: 23271,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        }
      },
      deposit: {
        FieldDefinitionId: 18325,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      commission: {
        FieldDefinitionId: 18335,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      lenderYield: {
        FieldDefinitionId: 18343,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Percentage
        }
      },
      vatDeferral: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['upfront', 'deferred']
        }
      },
      typeOfProduct: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['Hire Purchase', 'Lease - Finance', 'Loan']
        }
      },
      balloonPayment: {
        FieldDefinitionId: 23853,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      repaymentAmount: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      }
    },
    guarantors: [
      {
        name: {
          FieldDefinitionId: 20713,
          isReadonly: false,
          required: true
        },
        type: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        address: {
          addressLine1: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          city: {
            FieldDefinitionId: 18429,
            isReadonly: false,
            required: true
          },
          country: {
            FieldDefinitionId: 0,
            isReadonly: true,
            forcedValue: 'United Kingdom'
          },
          postCode: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          date: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Requirement, ELenderAPIType.DateTime],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          occupancy: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['owner', 'tenant']
            }
          }
        },
        nameFirst: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        nameLast: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        email: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 255,
              pattern:
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
              message: 'Please enter a valid email address'
            }
          }
        },
        dateOfBirth: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement, ELenderAPIType.DateTime],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        contactNumber: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Phone
          }
        },
        consentedToSearch: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        }
      }
    ],
    rationaleForFinance: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true
    }
  }));
};
