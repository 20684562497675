import { IPeriodColumns } from 'components/Calculator/Calculator/functions/setInitPeriods';
import { Extra } from 'components/Calculator/Calculator/interfaces';
import moment from 'moment';
import {
  Calculation,
  OverridePayment,
  PaymentFrequency
} from 'types/calculatorInterfaces';
import { IExtra, IRowData } from '../newIndex';

const getMonthsMultiplier = (paymentFrequency: string): number => {
  switch (paymentFrequency) {
    case PaymentFrequency.Annually:
      return 12;
    case PaymentFrequency.SemiAnnually:
      return 6;
    case PaymentFrequency.Quarterly:
      return 3;
    case PaymentFrequency.Monthly:
    default:
      return 1;
  }
};

const formatDateToISO = (date: Date) => {
  if (date instanceof Date) return date.toISOString().split('T')[0];
  return '';
};

const calculateTotalMonthsAcrossAllRows = (
  rows: IRowData[],
  state: Calculation
): string => {
  let totalMonths = 0;
  let totalNumber = 0;

  rows.forEach((row) => {
    const paymentFrequency = state.PaymentFrequency;
    const number = parseInt(row['Number'] as string) || 0;

    totalNumber += number;
    totalMonths += number * getMonthsMultiplier(paymentFrequency.toString());
  });

  const isMonth = state.PaymentFrequency === PaymentFrequency.Monthly;
  return `Total Instalments: ${isMonth ? totalMonths : totalNumber} ${
    state.PaymentFrequency
  }`;
};

const getNumberHelperText = (state: Calculation): string => {
  return `(${state.PaymentFrequency})`;
};

const getTotalMonthsText = (
  paymentFrequency: string,
  number: number
): string => {
  const totalMonths = number * getMonthsMultiplier(paymentFrequency);
  return `(${totalMonths} Months)`;
};

const calculateAndSetEndDate = (rows: IRowData[], rowIndex: number) => {
  const row = rows[rowIndex];
  const frequency = row['PaymentFrequency'] || '1';
  let number = parseInt(row['Number'] as string) || 0;

  number *= getMonthsMultiplier(frequency.toString());

  if (row['StartPeriod']) {
    const startDate = new Date(row['StartPeriod'] as string);
    let endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + number);

    rows[rowIndex]['EndPeriod'] = endDate.toISOString().split('T')[0];
  }
};

const getDatePickerViews = (
  paymentFrequency: string
): ('year' | 'month' | 'date')[] => {
  switch (paymentFrequency) {
    case PaymentFrequency.Annually:
      return ['year'];
    case PaymentFrequency.SemiAnnually:
      return ['month', 'year'];
    case PaymentFrequency.Quarterly:
      return ['month', 'year'];
    case PaymentFrequency.Monthly:
    default:
      return ['month', 'year'];
  }
};

const getDateFormat = (paymentFrequency: string) => {
  switch (paymentFrequency) {
    case '4':
      return 'yyyy';
    case '3':
      return 'MM/yyyy';
    case '2':
      return 'MM/yyyy';
    case '1':
    default:
      return 'MM/yyyy';
  }
};

const createEmptyRow = (
  columns: IPeriodColumns[],
  existingRows: IRowData[],
  contractStartDate: string | Date,
  state: Calculation
): IRowData => {
  const emptyRow: IRowData = {};
  const lastRow = existingRows[existingRows.length - 1];
  const currentDate = new Date(contractStartDate);

  columns.forEach((column) => {
    if (column.field === 'StartPeriod') {
      if (lastRow && lastRow['EndPeriod']) {
        const lastEndDate = new Date(lastRow['EndPeriod']);
        const nextStartDate = new Date(lastEndDate);

        if (state.PaymentFrequency === PaymentFrequency.Monthly)
          nextStartDate.setMonth(lastEndDate.getMonth() + 1);

        emptyRow[column.field] = formatDateToISO(nextStartDate);
      } else {
        emptyRow[column.field] = formatDateToISO(currentDate);
      }
    } else if (column.title === 'Type') {
      emptyRow[column.field] = '2';
    } else if (column.title === 'Frequency') {
      emptyRow[column.field] = '1';
    } else {
      emptyRow[column.field] = '';
    }
  });

  return emptyRow;
};

const recalculatePeriodsFromIndex = (
  rows: IRowData[],
  startRowIndex: number
): void => {
  for (let i = startRowIndex; i < rows.length; i++) {
    const currentRow = rows[i];
    const previousRowEndDate = i === 0 ? null : rows[i - 1]['EndPeriod'];
    const frequency = currentRow['PaymentFrequency'] || '1';
    const number = parseInt(currentRow['Number'] as string) || 0;
    const totalMonths = number * getMonthsMultiplier(frequency.toString());

    if (previousRowEndDate && !currentRow['StartPeriod']) {
      const startDate = new Date(previousRowEndDate);
      startDate.setDate(startDate.getDate());
      currentRow['StartPeriod'] = formatDateToISO(startDate);
    }

    if (currentRow['StartPeriod']) {
      const startDate = new Date(currentRow['StartPeriod'] as string);
      let endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + totalMonths);
      endDate.setDate(startDate.getDate());
      currentRow['EndPeriod'] = formatDateToISO(endDate);
    } else {
      currentRow['EndPeriod'] = '';
    }
  }
};

const transformDataForExtras = (
  rows: IRowData[],
  contractStartDate: string | Date,
  state: Calculation
): IExtra[] => {
  return rows.map((row) => {
    const paymentFrequency = state.PaymentFrequency;
    const monthsMultiplier = getMonthsMultiplier(paymentFrequency.toString());
    const contractStart = new Date(contractStartDate);
    const rowStart = new Date(row['StartPeriod'] as string);

    const calculatePeriodFromDate = (contractStartDate: Date, date: Date) => {
      const start = new Date(contractStartDate);
      const end = new Date(date);
      return (
        (end.getFullYear() - start.getFullYear()) * 12 +
        end.getMonth() -
        start.getMonth()
      );
    };

    const startPeriod = calculatePeriodFromDate(contractStart, rowStart);
    let endPeriod = startPeriod;

    if (paymentFrequency === PaymentFrequency.Monthly) {
      const numberOfMonths = row['Number']
        ? parseInt(row['Number'] as string) * monthsMultiplier || 0
        : 0;
      endPeriod = startPeriod + numberOfMonths;
    }

    const amountString = row['Amount']?.toString() || '0';
    const amountNumber = parseFloat(amountString) || 0;

    return {
      Amount: amountNumber,
      StartPeriod: startPeriod,
      EndPeriod: endPeriod,
      ExPayOverRide: row['ExPayOverRide'] as string,
      IsMonth: true
    };
  });
};

const validateRowSequence = (rows: IRowData[]): boolean => {
  for (let i = 0; i < rows.length - 1; i++) {
    const currentEndDate = new Date(rows[i]['EndPeriod'] as string);
    const nextStartDate = new Date(rows[i + 1]['StartPeriod'] as string);

    if (currentEndDate >= nextStartDate) {
      return false;
    }
  }
  return true;
};

const getMinStartDateForNextRow = (rows: IRowData[]): Date | null => {
  if (rows.length === 0) return null;

  const lastRow = rows[rows.length - 1];
  const lastEndDate = lastRow['EndPeriod'] as string;

  if (lastEndDate) {
    const minStartDate = new Date(lastEndDate);
    return minStartDate;
  }

  return null;
};

const formatOverridePaymentsToRows = (
  payments: Extra[] | OverridePayment[],
  startDate: string | Date,
  paymentFrequency: PaymentFrequency
): IRowData[] => {
  const start = new Date(startDate);
  const normalizedPayments = payments.map((payment) => ({
    ...payment,
    Amount: isNaN(payment.Amount) ? 0 : payment.Amount
  }));

  const sortedPayments = normalizedPayments.sort(
    (a, b) => a.StartPeriod - b.StartPeriod
  );
  const filteredPayments = sortedPayments.filter(
    (payment) => payment.Amount >= 0
  );

  const getPeriodLength = (frequency: PaymentFrequency): number => {
    switch (frequency) {
      case PaymentFrequency.Quarterly:
        return 3;
      case PaymentFrequency.SemiAnnually:
        return 6;
      case PaymentFrequency.Annually:
        return 12;
      case PaymentFrequency.Monthly:
      default:
        return 1;
    }
  };

  const periodLength = getPeriodLength(paymentFrequency);
  const formattedRows = filteredPayments.map((payment) => {
    const startPeriodDate = new Date(start);
    const endPeriodDate = new Date(start);

    startPeriodDate.setMonth(start.getMonth() + payment.StartPeriod);

    if (paymentFrequency !== PaymentFrequency.Monthly) {
      endPeriodDate.setMonth(startPeriodDate.getMonth() + periodLength);
      return {
        StartPeriod: startPeriodDate.toISOString().split('T')[0],
        EndPeriod: endPeriodDate.toISOString().split('T')[0],
        Number: 1,
        Amount: payment.Amount,
        PaymentFrequency: paymentFrequency.toString(),
        ExPayOverRide: payment.ExPayOverRide || '2'
      };
    }

    endPeriodDate.setMonth(endPeriodDate.getMonth() + payment.EndPeriod);
    return {
      StartPeriod: startPeriodDate.toISOString().split('T')[0],
      EndPeriod: endPeriodDate.toISOString().split('T')[0],
      Number: payment.EndPeriod - payment.StartPeriod,
      Amount: payment.Amount,
      PaymentFrequency: paymentFrequency.toString(),
      ExPayOverRide: payment.ExPayOverRide || '2'
    };
  });

  return formattedRows;
};

const checkForOverlappingMonths = (rows: IRowData[]): number | null => {
  for (let i = 0; i < rows.length - 1; i++) {
    const currentRow = rows[i];
    const currentEndPeriod = new Date(currentRow['EndPeriod'] || new Date());
    for (let j = i + 1; j < rows.length; j++) {
      const nextRow = rows[j];
      const nextStartPeriod = new Date(nextRow['StartPeriod'] || new Date());
      if (currentEndPeriod >= nextStartPeriod) {
        return j + 1;
      }
    }
  }
  return null;
};

const checkForMissingAmounts = (rows: IRowData[]): number | null => {
  for (let i = 0; i < rows.length; i++) {
    const currentRow = rows[i];
    if (
      !currentRow['Amount'] ||
      currentRow['Amount'].toString().trim() === ''
    ) {
      return i + 1;
    }
  }
  return null;
};

const cascadeStartDates = (
  rows: IRowData[],
  startIndex: number,
  state: Calculation
): void => {
  for (let i = startIndex; i < rows.length; i++) {
    const currentRow = rows[i];
    const previousRow = i > 0 ? rows[i - 1] : null;

    const frequency = state.PaymentFrequency;
    const number = parseInt(currentRow['Number'] as string) || 0;
    const monthMultiplier = getMonthsMultiplier(frequency.toString());
    const totalMonths = number * monthMultiplier;

    if (i === startIndex) {
      if (currentRow['StartPeriod']) {
        const startDate = moment(currentRow['StartPeriod']);
        const monthsToAdd =
          frequency === PaymentFrequency.Monthly
            ? totalMonths - 1
            : totalMonths;
        const endDate = moment(startDate).add(monthsToAdd, 'months');
        currentRow['EndPeriod'] = endDate.format('YYYY-MM-DD');
      }
      continue;
    }

    if (previousRow && previousRow['EndPeriod']) {
      const previousEndDate = moment(previousRow['EndPeriod']);

      // Adjust start date based on frequency
      const monthsToAddStart = frequency === PaymentFrequency.Monthly ? 1 : 0;
      monthMultiplier;
      const newStartDate = moment(previousEndDate).add(
        monthsToAddStart,
        'months'
      );
      currentRow['StartPeriod'] = newStartDate.format('YYYY-MM-DD');

      // Calculate end date
      const monthsToAdd =
        frequency === PaymentFrequency.Monthly ? totalMonths - 1 : totalMonths;
      const newEndDate = moment(newStartDate).add(monthsToAdd, 'months');
      currentRow['EndPeriod'] = newEndDate.format('YYYY-MM-DD');
    }
  }
};

export {
  getDatePickerViews,
  calculateTotalMonthsAcrossAllRows,
  getNumberHelperText,
  getTotalMonthsText,
  getDateFormat,
  calculateAndSetEndDate,
  createEmptyRow,
  recalculatePeriodsFromIndex,
  transformDataForExtras,
  validateRowSequence,
  getMinStartDateForNextRow,
  getMonthsMultiplier,
  formatOverridePaymentsToRows,
  checkForOverlappingMonths,
  checkForMissingAmounts,
  cascadeStartDates
};
