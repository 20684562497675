export const validateEmail = (email: string): string => {
  email = email.trim();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

  if (!emailRegex.test(email)) {
    return 'Please enter a valid email (e.g. user@email.com)';
  }

  return '';
};

export const extractDomainFromEmail = (input = '') => {
  return input.split('@')[1] || '';
};
