import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Xpansion from 'components/Xpansion';
import SingleObject from '../SingleObject';
import RepeatingObject from '../RepeatingObject';
import CalculatorObjectOverview from '../CalculatorObject';
import {
  CompleteObjectDefinition,
  FieldInstance,
  CompleteProcessStepDefinition
} from 'types/interfaces';
import { useProcess } from 'hooks/useProcess';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  xpansion: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none'
  },
  stepsection: {
    width: '100%',
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(4)
  }
}));

interface IObject {
  odid: number;
  psdid: number;
}

const DetailView = () => {
  const classes = useStyles();
  const { currentProcess, activeObjects } = useProcess();
  const { ProcessStepDefinitionSequence } = currentProcess;

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>
        {ProcessStepDefinitionSequence &&
          ProcessStepDefinitionSequence.map((index: number) => {
            const step: CompleteProcessStepDefinition =
              currentProcess.CompleteProcessStepDefinitionDict[index];
            const { Id } = step.ProcessStepDefinition;
            const isActive = activeObjects
              ?.map((object: IObject) => object.psdid)
              .includes(Id);

            const summary = step.ProcessStepDefinition.Title;
            if (activeObjects && isActive) {
              return (
                <Xpansion
                  key={index}
                  className={classes.xpansion}
                  defaultExpanded
                  icon
                  selected={false}
                  style={undefined}
                  summary={summary}>
                  <Step activeObjects={activeObjects} key={index} step={step} />
                </Xpansion>
              );
            }
            return null;
          })}
      </Grid>
    </div>
  );
};

export default DetailView;

interface StepInterface {
  activeObjects: IObject[];
  step: CompleteProcessStepDefinition;
  SpecialObject?: number | string;
}

export const Step = ({ step, activeObjects, SpecialObject }: StepInterface) => {
  const classes = useStyles();
  const { user } = useTypedSelector((s) => s.user);
  const subsystemUser = user.SystemAccess >= 4;

  return (
    <Grid item>
      {step.CompleteObjectDefinitionDict &&
        Object.values(step.CompleteObjectDefinitionDict).map(
          (CompleteObjectDefinition: CompleteObjectDefinition, i: number) => {
            const { ObjectDefinition, FieldDefinitionDict } =
              CompleteObjectDefinition;
            const { Id } = CompleteObjectDefinition.ObjectDefinition;
            const isSpecial = SpecialObject
              ? Id.toString() === SpecialObject
              : true;
            const isActive = activeObjects
              ?.map((object: IObject) => object.odid)
              .includes(Id);

            console.log({ CompleteObjectDefinition });

            if (!isActive || !isSpecial) return null;
            if (ObjectDefinition.ObjectDescription.includes('calculator')) {
              return (
                <div className={classes.stepsection} key={i}>
                  <Typography color="textPrimary" variant="h2">
                    {ObjectDefinition.Title}
                  </Typography>
                  <br />

                  <CalculatorObjectOverview
                    isOverview
                    ObjectDefinition={ObjectDefinition}
                    FieldDefinitionDict={FieldDefinitionDict}
                    display={subsystemUser ? 'clientView' : 'systemView'}
                  />
                </div>
              );
            }

            if (ObjectDefinition.ObjectRepeat === 1) {
              return (
                <div className={classes.stepsection} key={i}>
                  <Typography color="textPrimary" variant="h2">
                    {ObjectDefinition.Title}
                  </Typography>
                  <br />
                  <SingleObject object={CompleteObjectDefinition} />
                </div>
              );
            }

            return (
              <div className={classes.stepsection} key={i}>
                <Typography color="textPrimary" variant="h2">
                  {CompleteObjectDefinition.ObjectDefinition.Title}
                </Typography>
                <br />
                <Divider />
                <br />
                <RepeatingObject object={CompleteObjectDefinition} />
              </div>
            );
          }
        )}
    </Grid>
  );
};
