import React, { useEffect, useLayoutEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { firebase } from 'redux/firebase';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from 'redux/reducers';
import NoticeBoard from 'views/Profile/NoticeBoard';
import { BugTracker } from 'Utils/Bugtracker';
import { useDispatch } from 'react-redux';
import { getNoticeboardData } from 'services/NoticeBoardService';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = () => {
  if (window.disableNoticeBoard) return null;
  const dispatch = useDispatch();
  const { user } = useTypedSelector((s) => s.user);
  const noticeboard = useTypedSelector((s) => s.noticeboard);
  const { BrandList } = useTypedSelector((s) => s.config.settings);

  const subSystemUser = user.SystemAccess <= 4;

  const [open, setOpen] = React.useState(false);
  const [dismissed, setDismissed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleClose = () => setOpen(false);

  const handleDismissal = async () => {
    try {
      setDismissed(true);
      setOpen(false);
      const data = {
        AllBoardsModified: {
          InstanceBoardLastModified: noticeboard?.host?.timestamp || '',
          BrandBoardLastModified: noticeboard?.brand?.timestamp || '',
          GlobalBoardLastModified: noticeboard?.global?.timestamp || ''
        }
      };

      await firebase
        .firestore()
        .collection('userAccount')
        .doc(user.Id.toString())
        .set(data, { merge: true });
    } catch (error) {
      console.error('Error during dismissal:', error);
      BugTracker.notify(error);
    }
  };

  /**
   * useEffect hook to manage the notice board visibility state based on updates across different board types.
   * This checks for updates in three different notice board levels: host-specific, global, and brand-specific.
   *
   * Performs the following operations:
   * 1. Fetches the current notice board data from Firestore
   * 2. Retrieves the user's stored timestamps for each board type
   * 3. Compares stored timestamps with current timestamps, only when both values are defined
   * 4. Updates the UI state based on whether any board has new content
   *
   * @requires user - Current user object containing Id
   * @requires noticeboard - Notice board state object
   * @requires BrandList - Comma-separated string of brand IDs
   *
   * @fires setOpen - Sets the notice board modal visibility
   * @fires setDismissed - Sets the dismissed state of the notice board
   * @fires setLoading - Controls loading state during async operations
   */
  useEffect(() => {
    const checkNoticeBoard = async () => {
      if (!user.Id || !noticeboard) return;

      try {
        const quickNoticeBoard = await getNoticeboardData({
          BrandList,
          dispatch,
          setIsLoading,
          BugTracker
        });

        const snapshot = await firebase
          .firestore()
          .collection('userAccount')
          .doc(user.Id.toString())
          .get();

        if (snapshot.exists) {
          const data = snapshot.data();
          const storedData = data?.AllBoardsModified || {};

          const storedHost = storedData.InstanceBoardLastModified;
          const storedGlobal = storedData.GlobalBoardLastModified;
          const storedBrand = storedData.BrandBoardLastModified;

          const hostname = window.location.hostname.replace(/[^a-zA-Z ]/g, '');
          const currentHost =
            quickNoticeBoard?.[hostname]?.currentNoticeboardInstance?.timestamp;
          const currentGlobal =
            quickNoticeBoard?.currentNoticeboardInstance?.timestamp;
          const brandsArray = BrandList?.split(',') || [];
          const brandId = brandsArray[0];
          const currentBrand =
            quickNoticeBoard?.[brandId]?.currentNoticeboardInstance?.timestamp;

          const hasUpdates =
            (storedHost !== undefined &&
              currentHost &&
              storedHost !== currentHost) ||
            (storedGlobal !== undefined &&
              currentGlobal &&
              storedGlobal !== currentGlobal) ||
            (storedBrand !== undefined &&
              currentBrand &&
              storedBrand !== currentBrand);

          if (hasUpdates) {
            setOpen(true);
            setDismissed(false);
          } else {
            setDismissed(true);
          }
        } else {
          setDismissed(true);
        }
      } catch (error) {
        console.error('Error checking notice board:', error);
        BugTracker.notify(error);
      } finally {
        setLoading(false);
      }
    };

    checkNoticeBoard();
  }, []);

  if (subSystemUser || loading || dismissed) return null;
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <NoticeBoard />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismissal} color="primary">
          Dismiss Noticeboard
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          data-cy="noticeboard_close_btn">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogSlide;
