import {
  CompleteObjectInstance,
  CompleteObjectDefinition,
  FieldInstance,
  CompleteProcessDefinition,
  CompleteProcessInstance,
  FieldDefinition,
  CompleteProcessStepDefinition,
  CompleteObjectDefinitionDict,
  FieldDefinitionDict
} from 'types/interfaces';

import { HideShowFields } from './hideshowFields';
import { getFieldValueWithFieldDefinitionTitle } from 'components/Stepper/functions';
import { regulatedFilter } from 'components/Stepper/components/Steps/ActiveStep/helper/regulatedFilter';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export interface CompleteObjectDefinitionAndInstance {
  CompleteObjectDefinition: CompleteObjectDefinition;
  CompleteObjectInstance: CompleteObjectInstance;
}

export const stepProgress = ({
  currentDeal,
  currentProcess,
  parsed,
  regulated,
  entityType,
  currentStepId,
  hiddenFields,
  landingPage
}: {
  currentDeal: CompleteProcessInstance;
  currentProcess: CompleteProcessDefinition;
  parsed:
    | { piid: string; psdid: string; psiid: string; sc: string; uiid: string }
    | undefined;
  currentStepId: number;
  entityType: string;
  hiddenFields: number[];
  regulated: {
    hasEntity?: boolean | undefined;
    isRegulatedParty?: boolean | undefined;
  };
  landingPage?: boolean;
}) => {
  /**
   * 1. SET UP
   * */
  let _reqfieldsnum = [] as FieldInstance[];
  let _requiredfieldscomp = 0;
  let _allfieldsnum = 0;
  let _allfieldscomp = 0;

  const RelevantFieldDefinitionIdList = [] as number[];
  const RelevantFieldDefinitionList = [] as FieldDefinition[];
  const TotalFieldInstanceList = [] as FieldInstance[];
  const RelevantFieldInstanceList = [] as FieldInstance[];
  const RelevantRequiredFieldInstanceList = [] as FieldInstance[];
  const RelevantRequiredCompletedFieldInstanceList = [] as FieldInstance[];
  const RelevantCompletedFieldInstanceList = [] as FieldInstance[];

  const soleTraderObjectDefinitionIds = [3482, 3691];
  const privateIndividualObjectDefinitionIds = [3474];
  const partnershipObjectDefinitionIds = [3482, 3722];

  const soleTraders = ['Sole Trader'];
  const privateIndividuals = [
    'Private Individual',
    'Private Individual - High Net Worth'
  ];
  const partnerships = ['Partnership of 3 and less'];

  // GET RELEVANT FIELD DEFINITIONS
  const getRelevantFieldDefinitions = (
    list: CompleteObjectDefinitionAndInstance[]
  ) =>
    list.map((item: CompleteObjectDefinitionAndInstance) =>
      Object.values(item.CompleteObjectDefinition.FieldDefinitionDict).map(
        (FieldDefinition: FieldDefinition) => {
          RelevantFieldDefinitionIdList.push(FieldDefinition.Id);
          RelevantFieldDefinitionList.push(FieldDefinition);
        }
      )
    );

  // GET TOTAL FIELD INSTANCES
  const getTotalFieldInstance = (list: CompleteObjectDefinitionAndInstance[]) =>
    list.forEach((item: CompleteObjectDefinitionAndInstance) => {
      Object.values(item.CompleteObjectDefinition.FieldDefinitionDict).forEach(
        (FieldDefinition: FieldDefinition) => {
          const FieldInstanceList = getFieldInstances(
            item.CompleteObjectInstance
          );

          FieldInstanceList.find((FieldInstance: FieldInstance) => {
            if (FieldInstance.FieldDefinitionId === FieldDefinition.Id) {
              TotalFieldInstanceList.push(FieldInstance);
            }
          });
        }
      );
    });

  // GET RELEVANT, REQUIRED AND COMPLETED FIELD INSTANCES
  const getRelevantFieldInstances = (TotalFieldInstanceList: FieldInstance[]) =>
    TotalFieldInstanceList.forEach((FieldInstance: FieldInstance) => {
      const Id = FieldInstance.FieldDefinitionId;
      const isFactFindForm = currentStepId === 1585;
      if (hiddenFields.includes(Id)) return;

      const shouldSkipEntityType = () => {
        if (soleTraders.includes(entityType)) {
          return !soleTraderObjectDefinitionIds.includes(
            FieldInstance.ObjectDefinitionId
          );
        }
        if (privateIndividuals.includes(entityType)) {
          return !privateIndividualObjectDefinitionIds.includes(
            FieldInstance.ObjectDefinitionId
          );
        }
        if (partnerships.includes(entityType)) {
          return !partnershipObjectDefinitionIds.includes(
            FieldInstance.ObjectDefinitionId
          );
        }
        return false;
      };

      if (!landingPage && !isFactFindForm && shouldSkipEntityType()) {
        return;
      }

      if (isFactFindForm && shouldSkipEntityType()) {
        return;
      }

      const FieldDefinition: FieldDefinition | undefined =
        RelevantFieldDefinitionList.find(
          (FieldDefinition: FieldDefinition) =>
            FieldDefinition.Id === FieldInstance.FieldDefinitionId
        );

      // RELEVANT FIELDS
      const isRelevant = RelevantFieldDefinitionIdList.includes(Id);
      if (isRelevant && FieldDefinition) {
        RelevantFieldInstanceList.push(FieldInstance);

        // RELEVANT COMPLETED FIELDS
        let value = FieldInstance.FieldValue;
        if (value !== 'false' && value !== '') {
          RelevantCompletedFieldInstanceList.push(FieldInstance);
        }
      }

      // REQUIRED FIELDS
      const isRequired = FieldDefinition?.FieldRequired;
      if (isRequired && isRelevant) {
        RelevantRequiredFieldInstanceList.push(FieldInstance);

        // RELEVANT REQUIRED AND COMPLETED FIELDS
        let value = FieldInstance.FieldValue;
        if (value !== 'false' && value !== '') {
          RelevantRequiredCompletedFieldInstanceList.push(FieldInstance);
        }
      }
    });

  /**
   * 2. ARRANGING THE DATA
   */

  const landingpageStepId: string | undefined = parsed?.psdid;
  const currentStepIdString =
    currentStepId !== undefined ? currentStepId.toString() : undefined;

  // Use nullish coalescing with a default value to ensure we always have a string
  const stepId: string = landingpageStepId ?? currentStepIdString ?? 'default';

  // Add error handling for missing step ID
  if (stepId === 'default') {
    console.warn('Neither landingpageStepId nor currentStepId were defined');
  }

  // Get the current step definition from the process dictionary
  const currentStep: CompleteProcessStepDefinition =
    currentProcess?.CompleteProcessStepDefinitionDict?.[stepId];

  // Add validation to ensure we have a valid step
  if (!currentStep) {
    console.warn(`No step definition found for stepId: ${stepId}`);
  }

  // Remove hidden fields based on multiple criteria
  const hiddenFieldHandledList: CompleteObjectDefinitionAndInstance[] =
    removeHiddenFields({
      CompleteObjectDefinitionDict: currentStep?.CompleteObjectDefinitionDict,
      currentDeal,
      entityType,
      regulated
    });

  // Start with all visible fields
  let RelevantCompleteObjectDefinitionsAndInstances = hiddenFieldHandledList;

  // Get the Deal Type from the current step
  const DealType: string =
    getFieldValueWithFieldDefinitionTitle({
      stepdefdict: currentStep,
      currentDeal,
      FieldDefinitionTitle: 'Deal Type'
    }) ?? ''; // Provide default empty string if undefined

  // Filter objects based on deal type and Fact Find Form tag
  RelevantCompleteObjectDefinitionsAndInstances = hiddenFieldHandledList.filter(
    (item: CompleteObjectDefinitionAndInstance) => {
      // Check if the object is a Fact Find Form
      const isFFF_OBJECT =
        item.CompleteObjectDefinition.ObjectDefinition.ObjectDescription.includes(
          '#Fact Find Form'
        );

      // If it's not a Fact Find Form, include it in results
      if (!isFFF_OBJECT) {
        return true;
      }

      // If it is a Fact Find Form and we have a Deal Type, check if it matches
      if (DealType !== '') {
        const { ObjectDescription } =
          item.CompleteObjectDefinition.ObjectDefinition;
        const split = ObjectDescription.split(',');
        const dealTypeEntries = split.filter((e) => e.includes('&Deal='));

        // If there are deal type entries, check if any match the current deal type
        if (dealTypeEntries.length > 0) {
          return dealTypeEntries.some((el) =>
            el.toUpperCase().includes(DealType.toUpperCase())
          );
        }
      }

      // Include the item if no deal type matching was necessary
      return true;
    }
  );

  /**
   * 3. AFTERMATH
   */
  getRelevantFieldDefinitions(RelevantCompleteObjectDefinitionsAndInstances);
  getTotalFieldInstance(hiddenFieldHandledList);
  getRelevantFieldInstances(TotalFieldInstanceList);

  _allfieldsnum = RelevantFieldInstanceList.length;
  _allfieldscomp = RelevantCompletedFieldInstanceList.length;
  _reqfieldsnum = RelevantRequiredFieldInstanceList;
  _requiredfieldscomp = RelevantRequiredCompletedFieldInstanceList.length;

  // PERCENTAGES

  let _Percent_Required_Fields_Complete = Math.round(
    (_requiredfieldscomp / _reqfieldsnum.length) * 100
  );

  if (_reqfieldsnum.length === 0 && _requiredfieldscomp === 0) {
    _Percent_Required_Fields_Complete = 100;
  }

  if (isNaN(_Percent_Required_Fields_Complete))
    _Percent_Required_Fields_Complete = 0;

  let _Percent_All_Fields_Complete = Math.round(
    (_allfieldscomp / _allfieldsnum) * 100
  );

  if (isNaN(_Percent_All_Fields_Complete)) _Percent_All_Fields_Complete = 0;

  const obj = {
    _Percent_Required_Fields_Complete,
    _Percent_All_Fields_Complete,
    _requiredfieldscomp,
    _reqfieldsnum,
    _allfieldsnum,
    _allfieldscomp
  };

  return obj;
};

/**
 * Removes hidden fields from object definitions and handles instance creation
 *
 * @param {Object} params - Function parameters
 * @param {CompleteObjectDefinitionDict} params.CompleteObjectDefinitionDict - Dictionary of object definitions
 * @param {CompleteProcessInstance} params.currentDeal - Current process instance
 * @param {string} params.entityType - Type of entity
 * @param {Object} params.regulated - Regulation status
 * @param {boolean} [params.regulated.hasEntity] - Whether entity exists
 * @param {boolean} [params.regulated.isRegulatedParty] - Whether party is regulated
 *
 * @returns {CompleteObjectDefinitionAndInstance[]} List of processed object definitions and instances
 */
const removeHiddenFields = ({
  CompleteObjectDefinitionDict,
  currentDeal,
  entityType,
  regulated
}: {
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  currentDeal: CompleteProcessInstance;
  entityType: string;
  regulated: {
    hasEntity?: boolean | undefined;
    isRegulatedParty?: boolean | undefined;
  };
}): CompleteObjectDefinitionAndInstance[] => {
  const CompleteObjectDefinitionAndInstanceList: CompleteObjectDefinitionAndInstance[] =
    [];

  // Safety check for CompleteObjectDefinitionDict
  if (!CompleteObjectDefinitionDict) {
    return CompleteObjectDefinitionAndInstanceList;
  }

  Object.values(CompleteObjectDefinitionDict).forEach(
    (CompleteObjectDefinition: CompleteObjectDefinition) => {
      const canDisplayBasedOnParty = regulatedFilter({
        item: CompleteObjectDefinition,
        entityType,
        regulated
      });

      const { ObjectDefinition } = CompleteObjectDefinition;
      const { Id } = ObjectDefinition;
      let { FieldDefinitionDict } = CompleteObjectDefinitionDict[Id];

      if (canDisplayBasedOnParty) {
        // Safety check for CompleteObjectInstanceDict
        const completeObjectInstanceDict =
          currentDeal?.CompleteObjectInstanceDict || {};

        let CompleteObjectInstances: CompleteObjectInstance[] = Object.values(
          completeObjectInstanceDict
        ).filter(
          (CompleteObjectInstance: CompleteObjectInstance) =>
            CompleteObjectInstance?.ObjectInstance?.ObjectDefinitionId ===
            CompleteObjectDefinition?.ObjectDefinition.Id
        );

        // Rest of your existing code...
        CompleteObjectInstances.forEach(
          (CompleteObjectInstance: CompleteObjectInstance) => {
            const FieldDefinitions: FieldDefinition[] = HideShowFields({
              FieldDefinitionList: Object.values(
                CompleteObjectDefinition.FieldDefinitionDict || {}
              ),
              CompleteObjectInstance
            });

            FieldDefinitionDict = Object.fromEntries(
              FieldDefinitions.map((t) => [t.Id, t])
            );

            CompleteObjectDefinitionAndInstanceList.push({
              CompleteObjectDefinition: {
                FieldDefinitionDict,
                ObjectDefinition
              },
              CompleteObjectInstance
            });
          }
        );

        // Handle required fields case
        if (CompleteObjectInstances.length === 0) {
          const containsAtLeastOneRequiredField = Object.values(
            CompleteObjectDefinition.FieldDefinitionDict || {}
          ).find(
            (FieldDefinition: FieldDefinition) => FieldDefinition.FieldRequired
          );

          if (containsAtLeastOneRequiredField) {
            // Create empty entry with default values
            CompleteObjectDefinitionAndInstanceList.push({
              CompleteObjectDefinition: {
                FieldDefinitionDict:
                  CompleteObjectDefinition.FieldDefinitionDict,
                ObjectDefinition: CompleteObjectDefinition.ObjectDefinition
              },
              CompleteObjectInstance: createEmptyObjectInstance({
                Id,
                ProcessInstanceId: currentDeal?.ProcessInstance?.Id || 0,
                FieldDefinitionDict:
                  CompleteObjectDefinition?.FieldDefinitionDict || {}
              })
            });
          }
        }
      }
    }
  );

  return CompleteObjectDefinitionAndInstanceList;
};

/**
 * Creates an empty object instance with default values
 *
 * @param {Object} params - Parameters for creating empty instance
 * @param {number|string} params.Id - Object definition ID
 * @param {number|string} params.ProcessInstanceId - Process instance ID
 * @param {Object} params.FieldDefinitionDict - Field definitions dictionary
 * @returns {CompleteObjectInstance} Empty object instance
 */
const createEmptyObjectInstance = ({
  Id,
  ProcessInstanceId,
  FieldDefinitionDict
}: {
  Id: number | string;
  ProcessInstanceId: number | string;
  FieldDefinitionDict: FieldDefinitionDict;
}): CompleteObjectInstance => ({
  ObjectInstance: {
    Id: 0,
    ObjectDefinitionId: parseInt(Id.toString()),
    ItemOrder: 0,
    ProcessInstanceId: parseInt(ProcessInstanceId.toString()),
    Selected: false,
    Title: '',
    UserDefinitionId: 0,
    UserInstanceId: 0
  },
  FieldInstanceDict: Object.fromEntries(
    Object.keys(FieldDefinitionDict || {}).map((t) => {
      const FieldDefinition: FieldDefinition = FieldDefinitionDict[t];
      return [
        FieldDefinition.Id,
        {
          Id: 0,
          Title: '',
          ProcessInstanceId: 0,
          FieldInstanceId: 0,
          FieldDefinitionId: FieldDefinition.Id,
          ObjectDefinitionId: parseInt(Id.toString()),
          ObjectInstanceId: 0,
          UserInstanceId: 0,
          UserDefinitionId: 0,
          FieldValue: ''
        }
      ];
    })
  ),
  FieldInstanceList: []
});
