export const Bips1 = [
  'http://localhost:3000',
  'http://localhost:3001',
  'https://aalservices.bips.tech',
  'https://absolutebusinessfinanceuk.bips.tech',
  'https://adlamfinancial.bips.tech',
  'https://afcommercial.bips.tech',
  'https://afsblackburn.bips.tech',
  'https://afssouthern.bips.tech',
  'https://ajhfinance.bips.tech',
  'https://amigafinance.bips.tech',
  'https://approvedbusinessfinance.bips.tech',
  'https://assetandbusinessfinance.bips.tech',
  'https://assetfinancespecialists.bips.tech',
  'https://assetfinancialservices.bips.tech',
  'https://barkersfinance.bips.tech',
  'https://bespokefundingsolutions.bips.tech',
  'https://blackwellfinancialsolutions.bips.tech',
  'https://boltonbusinessfinance.bips.tech',
  'https://busbyfinance.bips.tech',
  'https://cftfinance.bips.tech',
  'https://circlebusinessfinance.bips.tech',
  'https://clevercommercialfinance.bips.tech',
  'https://commercialfinanceoptions.bips.tech',
  'https://completecommercialfinance.bips.tech',
  'https://completefundingsolutions.bips.tech',
  'https://cprprofessionalservices.bips.tech',
  'https://crestcommercial.bips.tech',
  'https://crownbusinessfinance.bips.tech',
  'https://ctcommercialfinance.bips.tech',
  'https://custombusinessfinance.bips.tech',
  'https://cyllidcymrufinance.bips.tech',
  'https://davidgould.bips.tech',
  'https://daytonaassetfinance.bips.tech',
  'https://dmkpropertyfinance.bips.tech',
  'https://drivenassetfinance.bips.tech',
  'https://eliteprofessionalfinance.bips.tech',
  'https://financeboutiqueuk.bips.tech',
  'https://financesimplified.bips.tech',
  'https://finchbusinessfinance.bips.tech',
  'https://fitzassetfinance.bips.tech',
  'https://garyhorner.bips.tech',
  'https://greenlightassetfinance.bips.tech',
  'https://harewoodassetfinance.bips.tech',
  'https://hensleystephens.bips.tech',
  'https://hsedirect.bips.tech',
  'https://imagineassetfinance.bips.tech',
  'https://impactcommercialfinance.bips.tech',
  'https://joannehayton.bips.tech',
  'https://kingsgatefinance.bips.tech',
  'https://lancashireassetfinance.bips.tech',
  'https://langfinance.bips.tech',
  'https://leegodwin.bips.tech',
  'https://luxeassetfinance.bips.tech',
  'https://majorassetfinanceandleasing.bips.tech',
  'https://michaelsabini.bips.tech',
  'https://midlandsbusinessfinancesolutions.bips.tech',
  'https://mightyoakbusinessfinance.bips.tech',
  'https://mikejones.bips.tech',
  'https://moltenfinance.bips.tech',
  'https://monctonfinancial.bips.tech',
  'https://nextstepassetfinance.bips.tech',
  'https://northcommercialfinance.bips.tech',
  'https://northwestcommercialfinance.bips.tech',
  'https://northwestcommercialsolutions.bips.tech',
  'https://oakemerefinancial.bips.tech',
  'https://oakmerefinancial.bips.tech',
  'https://omnicommercial.bips.tech',
  'https://onechaincapital.bips.tech',
  'https://originbusinessfinance.bips.tech',
  'https://owsten.bips.tech',
  'https://parkerfrancisassociates.bips.tech',
  'https://paulbarker.bips.tech',
  'https://pauledwards.bips.tech',
  'https://pearceassetfinance.bips.tech',
  'https://postfinancialservices.bips.tech',
  'https://process-builder.commercial-tech.com',
  'https://productivityfinance.bips.tech',
  'https://professionalsystemsfinance.bips.tech',
  'https://redchillifinance.bips.tech',
  'https://rickerbycooper.bips.tech',
  'https://rlacapital.bips.tech',
  'https://rozacapital.bips.tech',
  'https://salinascorporatefinance.bips.tech',
  'https://scollickbusinessfinance.bips.tech',
  'https://selectcommercial.bips.tech',
  'https://simplifyvehicles.bips.tech',
  'https://simplyfundingassets.bips.tech',
  'https://sixteenfinance.bips.tech',
  'https://staging-bips.netlify.app',
  'https://stepar.bips.tech',
  'https://synergy.bips.tech',
  'https://test.bips.tech',
  'https://thefmfgroup.bips.tech',
  'https://threeonethreecommercial.bips.tech',
  'https://tibbscommercialfinance.bips.tech',
  'https://timeandtidecommercialfinance.bips.tech',
  'https://towerbridgecommercial.bips.tech',
  'https://trustedbusinesspartner.bips.tech',
  'https://waterbearbusinessfinance.bips.tech',
  'https://wtwofinance.bips.tech',
  'https://yourfinancesolutions.bips.tech'
];

export const Bips2 = [
  'https://acorncommercialfinance.bips.tech',
  'https://actioncommercialfinance.bips.tech',
  'https://adaptivemoney.bips.tech',
  'https://aintu.bips.tech',
  'https://alliedbusinessfinance.bips.tech',
  'https://apollocapitalgroup.bips.tech',
  'https://approvedpropertyfinance.bips.tech',
  'https://assetandvehiclefinance.bips.tech',
  'https://assetfinanceni.bips.tech',
  'https://avalonfinancialsolutions.bips.tech',
  'https://bizfirst.bips.tech',
  'https://bluebadgerfinancial.bips.tech',
  'https://bluestormcommercial.bips.tech',
  'https://broadscopefinance.bips.tech',
  'https://businessfinanceexperts.bips.tech',
  'https://businessfinancehouse.bips.tech',
  'https://bwfinance.bips.tech',
  'https://chenalfame.bips.tech',
  'https://cheshireassetfinance.bips.tech',
  'https://citygatecommercialfinance.bips.tech',
  'https://claritycommercialfinance.bips.tech',
  'https://coastalassetfinance.bips.tech',
  'https://copiousfinance.bips.tech',
  'https://davidbowen.bips.tech',
  'https://davidyitzchakkopciel.bips.tech',
  'https://drummondfinance.bips.tech',
  'https://eastgatecf.bips.tech',
  'https://eastgatecommercial.bips.tech',
  'https://elitefinancesolutions.bips.tech',
  'https://findenbusinessfinance.bips.tech',
  'https://firstfinancegrouppartners.bips.tech',
  'https://franklincommercialfinance.bips.tech',
  'https://fullcircleassetfinance.bips.tech',
  'https://fundedfinance.bips.tech',
  'https://fundicommercialfinance.bips.tech',
  'https://fundingflex.bips.tech',
  'https://gippingfinance.bips.tech',
  'https://goldcliffeprofessional.bips.tech',
  'https://griffincommerce.bips.tech',
  'https://hennertonaf.bips.tech',
  'https://htwohbusinessfinance.bips.tech',
  'https://icecubedpropertyfinance.bips.tech',
  'https://jamesmurrayfinancialservices.bips.tech',
  'https://jbcommercialfinance.bips.tech',
  'https://jecbusinessfinance.bips.tech',
  'https://jkktwelve.bips.tech',
  'https://kadfinance.bips.tech',
  'https://kfivebusinessfinance.bips.tech',
  'https://kirkwoodassetfinance.bips.tech',
  'https://ldfinancesolutions.bips.tech',
  'https://leedouris.bips.tech',
  'https://logicpropertyfinance.bips.tech',
  'https://mairfinance.bips.tech',
  'https://mdmfinancialservices.bips.tech',
  'https://michaelvine.bips.tech',
  'https://millbrookbusinessfinance.bips.tech',
  'https://millsidecapital.bips.tech',
  'https://montanefinance.bips.tech',
  'https://mosaicadvisoryservices.bips.tech',
  'https://multiplyfinance.bips.tech',
  'https://mvfinance.bips.tech',
  'https://netzeroassetfinance.bips.tech',
  'https://nexgenbusinessfinance.bips.tech',
  'https://orlfinance.bips.tech',
  'https://penumbrabusinessfinance.bips.tech',
  'https://percyfinance.bips.tech',
  'https://pioneerfinancegroup.bips.tech',
  'https://powerhousebusinessfinance.bips.tech',
  'https://principalbusinessfinance.bips.tech',
  'https://prioryfinancialservices.bips.tech',
  'https://proassetfinance.bips.tech',
  'https://propertyandfinancesolutions.bips.tech',
  'https://randolphthom.bips.tech',
  'https://rbbusinessfinance.bips.tech',
  'https://sandringhambusinessfinance.bips.tech',
  'https://sharpfundingsolutions.bips.tech',
  'https://silvencommercialmoney.bips.tech',
  'https://simplybusinessfinance.bips.tech',
  'https://sourceassetfinance.bips.tech',
  'https://spotfunding.bips.tech',
  'https://srmbusinessfinance.bips.tech',
  'https://standoutcapitalsolutions.bips.tech',
  'https://stanfordfinance.bips.tech',
  'https://steeplefinance.bips.tech',
  'https://stuartcroftfs.bips.tech',
  'https://sunsetcommercialfinance.bips.tech',
  'https://tempofinance.bips.tech',
  'https://thefinancehut.bips.tech',
  'https://thefundingstore.bips.tech',
  'https://threeonethreecommercial.bips.tech',
  'https://tommiles.bips.tech',
  'https://tonyhawkesenterprises.bips.tech',
  'https://turkfinans.bips.tech',
  'https://ultimatefundingsolutions.bips.tech',
  'https://underbankscommercialfinance.bips.tech',
  'https://unifiedbusinessfinance.bips.tech',
  'https://universalassetfinance.bips.tech',
  'https://venturefinance.bips.tech',
  'https://vtwelveautomobil.bips.tech',
  'https://ybmkfinance.bips.tech',
  'https://zenithfinance.bips.tech'
];

export const Bips3 = [
  'https://ezfinancesolutions.bips.tech',
  'https://landrbusinessfinance.bips.tech',
  'https://sherrattassetfinance.bips.tech',
  'https://threecrownsfinance.bips.tech',
  'https://orcafinanceandleasing.bips.tech',
  'https://shoreassetfinance.bips.tech',
  'https://imperaadvisors.bips.tech',
  'https://pinrosecommercialfinance.bips.tech',
  'https://empirecommercialfinance.bips.tech',
  'https://keyassetfinance.bips.tech',
  'https://pentest.bips.tech',
  'https://fetchfinance.bips.tech',
  'https://robrumseybusinessfinance.bips.tech',
  'https://staging.bips.tech',
  'https://revupfinance.bips.tech',
  'https://focalisefinance.bips.tech',
  'https://ccspringadorfinance.bips.tech',
  'https://essentialfinancepartners.bips.tech',
  'https://ascendcommercialfinance.bips.tech',
  'https://praeviduscommercialfinance.bips.tech',
  'https://vehicles4business.bips.tech',
  'https://proactivecorporatefinance.bips.tech',
  'https://ellcadofinance.bips.tech',
  'https://skylarkfinancegroup.bips.tech',
  'https://heatonwoodscommercialfinance.bips.tech',
  'https://lumosbusinessfinance.bips.tech',
  'https://jtrfinance.bips.tech'
];
