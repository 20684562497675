import { useEffect, useRef } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { useTypedSelector } from 'redux/reducers';

// Components
import FCA from './components/FCA';
import ForexTracker from './components/Forex';
import NewUserOverLay from 'components/LoginDialog';
import SignBackInDialog from 'components/Authentication/signedOutLoginDialog';
import ExtremeCautionList from './components/DoNotDeal/ExtremeCautionList';

// Views
import FileStorage from './views/FileStorage';
import Extensions from './views/Extensions';
import AddressLookup from './views/Address';
import Admin from './views/Admin';
import Audit from './views/Audit';
import CalculatorView from 'views/CalculatorView';
import Chatter from './views/Chatter';
import CribSheets from './views/FileExplorer';
import VRM from './views/VRM';
import Marketing from './views/Marketing';
import Customers from './views/Customers';
import Dashboard from './views/Dashboard';
import Deals from './views/Deals';
import DealsCreate from './views/Deals/Create';
import DealsEdit from './views/Deals/Edit';
import GraphqlCrmView from 'views/GraphqlCrmView';
import InstanceDetail from './views/InstanceDetail';
import LandingPage from './views/LandingPage/Stepper';
import LegacyDealsViewer from './views/LegacyDealsViewer';
import Lenders from './views/Lenders';
import Kanban from './views/Kanban';
import NotFound from './views/NotFound';
import Portal from './views/Portal';
import Profile from './views/Profile';
import RedFlag from './views/RedFlag';
import Settings from './views/Settings';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import SupportPage from './views/Support';
import UnderDevelopment from './views/UnderDevelopment';
import UserDefinition from './views/UserDefinition';
import TestFlight from './views/TestFlight';
import PasswordReset from './views/PasswordReset';
import MessageHub from './views/MessageHub';
import BipsWeekly from './views/BipsWeekly';
import InstanceBuilder from 'views/InstanceBuilder';

let prevPath = 'no-route';

export const PrivateRoute = ({ component: Component, ...props }) => {
  const { token } = useTypedSelector((s) => s.user.auth);
  const user = useTypedSelector((s) => s.user.user);
  const portalUser = user.SystemAccess === 3;
  const currentPath = props.path;

  const noticeBoardExcludedPath = '/marketing';

  // Prevent noticeboard from showing up when navigating FROM /marketing routes:
  const navigatedFromExcluded = prevPath.includes(noticeBoardExcludedPath);
  // Prevent noticeboard from showing up when navigating TO /marketing routes:
  const currentPathIsExcluded = currentPath.includes(noticeBoardExcludedPath);

  const isMarketingRoute = navigatedFromExcluded || currentPathIsExcluded;

  const route =
    token?.length > 0 ? (
      <>
        <SignBackInDialog />
        {process.env.REACT_APP_ENV !== 'production' &&
          !portalUser &&
          !isMarketingRoute && <NewUserOverLay />}
        <Component {...props} />
      </>
    ) : (
      <Redirect to="/sign-in" />
    );
  return <Route {...props} render={() => route} />;
};

const Routes = () => {
  const disabled = process.env.REACT_APP_ENV === 'production';
  const user = useTypedSelector((s) => s.user.user);
  const portalUser = user.SystemAccess === 3;

  const history = useHistory();
  const location = useLocation();
  const prevPathRef = useRef('');

  // Set the global prevPath to the previous path before updating the ref
  prevPath = prevPathRef.current;
  useEffect(() => {
    prevPath = prevPathRef.current;

    // When location changes, record the previous path
    const unlisten = history.listen(() => {
      prevPathRef.current = location.pathname;
    });
    return () => unlisten();
  }, [history, location]);

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={
          disabled
            ? '/company-navigator'
            : portalUser
            ? '/portal'
            : '/dashboard'
        }
      />
      <PrivateRoute component={FileStorage} path="/storage" />
      <PrivateRoute component={InstanceBuilder} path="/noticeboard-Builder" />
      <PrivateRoute component={Extensions} path="/extensions" />
      <PrivateRoute component={AddressLookup} path="/address/lookup" />
      <PrivateRoute component={Admin} path="/admin" />
      <PrivateRoute component={Audit} exact path="/audit" />
      <PrivateRoute component={CalculatorView} exact path="/calculator" />
      <PrivateRoute component={Chatter} exact path="/chatter" />
      <PrivateRoute component={CribSheets} path="/file-explorer" />
      <PrivateRoute component={VRM} path="/vrm" />
      <PrivateRoute component={VRM} path="/vrm/lookup" />
      <PrivateRoute component={Marketing} path="/marketing" />
      <PrivateRoute component={Marketing} path="/marketing/lists" />
      <PrivateRoute component={Marketing} path="/marketing/reports/:id" />
      <PrivateRoute component={Customers} exact path="/customers" />
      <PrivateRoute component={Dashboard} exact path="/dashboard" />
      <PrivateRoute component={TestFlight} exact path="/test-flight" />
      <PrivateRoute component={BipsWeekly} exact path="/bips-weekly" />
      <PrivateRoute component={MessageHub} exact path="/message-hub" />
      <PrivateRoute component={Deals} exact path="/deals" />
      <PrivateRoute component={DealsCreate} exact path="/deals/create" />
      <PrivateRoute component={DealsEdit} exact path="/deals/edit" />
      <PrivateRoute component={FCA} exact path="/fca" />
      <PrivateRoute component={ForexTracker} exact path="/forex-tracker" />
      <PrivateRoute component={GraphqlCrmView} path="/graphql-crm" />
      <PrivateRoute component={Kanban} exact path="/kanban" />
      <PrivateRoute component={LegacyDealsViewer} path="/legacy-deals-viewer" />
      <PrivateRoute component={Lenders} exact path="/lenders" />
      <PrivateRoute component={Profile} exact path="/profile" />
      <PrivateRoute component={Portal} exact path="/portal" />
      <PrivateRoute component={RedFlag} exact path="/company-navigator" />
      <PrivateRoute component={Settings} exact path="/settings" />
      <PrivateRoute component={SupportPage} exact path="/support" />
      <PrivateRoute
        component={ExtremeCautionList}
        exact
        path="/extreme-caution"
      />
      <PrivateRoute
        component={UserDefinition}
        exact
        path="/UserDefinition/:ID"
      />
      <PrivateRoute
        component={InstanceDetail}
        exact
        path="/UserDefinition/:ID/:InstanceId"
      />
      <PrivateRoute
        component={InstanceDetail}
        exact
        path="portal/UserDefinition/:ID/:InstanceId"
      />
      <Route component={SignIn} exact path="/sign-in" />
      <Route
        component={SignIn}
        exact
        path="/sign-in/:pdid/:psdid/:piid/:odid/:oiid/:fdid/:fv/:rid/:msg"
      />
      <Route component={SignUp} exact path="/sign-up" />
      <Route component={LandingPage} exact path="/landingpage" />
      <Route component={PasswordReset} exact path="/passwordreset" />
      <Route component={UnderDevelopment} exact path="/under-development" />
      <Route component={NotFound} exact path="/not-found" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
