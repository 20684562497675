import { useState, useEffect } from 'react';
import { firebase } from 'redux/firebase';
import {
  Typography,
  Chip,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { BugTracker } from 'Utils/Bugtracker';
import { ELender } from 'components/Stepper/components/LenderAPI/interface';

interface ILenderConfig {
  id: number;
  environment: 'production' | 'staging' | 'pre-production';
  lenderName: ELender;
}

interface ILenderSettings {
  lenderConfigs: ILenderConfig[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: { margin: theme.spacing(1) },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200
    },
    configGroup: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius
    }
  })
);

export const LenderConfigurationManager = () => {
  const [config, setConfig] = useState<ILenderSettings>({
    lenderConfigs: []
  });

  const deleteLenderConfig = (lenderId: number, environment: string) => {
    const ref = firebase
      .firestore()
      .collection('globalSetting')
      .doc('lenderConfig');

    const configToRemove = config.lenderConfigs.find(
      (cfg) => cfg.id === lenderId && cfg.environment === environment
    );

    if (configToRemove) {
      ref.update({
        lenderConfigs: firebase.firestore.FieldValue.arrayRemove(configToRemove)
      });
    }
  };

  const addLenderConfig = (
    lenderId: number,
    environment: 'production' | 'staging' | 'pre-production',
    lenderName: ELender
  ) => {
    const ref = firebase
      .firestore()
      .collection('globalSetting')
      .doc('lenderConfig');

    const newConfig: ILenderConfig = {
      id: lenderId,
      environment,
      lenderName
    };

    ref
      .get()
      .then((res) => {
        if (!res.exists) {
          return ref.set({ lenderConfigs: [newConfig] });
        }
        return ref.update({
          lenderConfigs: firebase.firestore.FieldValue.arrayUnion(newConfig)
        });
      })
      .catch((e) => {
        BugTracker.notify(e);
      });
  };

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('globalSetting')
      .doc('lenderConfig')
      .onSnapshot((doc) => {
        const data = doc.data() as ILenderSettings;
        data && setConfig(data);
      });
    return () => unsub();
  }, []);

  return (
    <LenderConfigurationView
      config={config}
      deleteLenderConfig={deleteLenderConfig}
      addLenderConfig={addLenderConfig}
    />
  );
};

const LenderConfigurationView = ({
  config,
  deleteLenderConfig,
  addLenderConfig
}: {
  config: ILenderSettings;
  deleteLenderConfig: (lenderId: number, environment: string) => void;
  addLenderConfig: (
    lenderId: number,
    environment: 'production' | 'staging' | 'pre-production',
    lenderName: ELender
  ) => void;
}) => {
  const classes = useStyles();
  const environments = ['production', 'staging', 'pre-production'];

  return (
    <div>
      {environments.map((env) => (
        <div key={env} className={classes.configGroup}>
          <Typography variant="h6" gutterBottom>
            {env.charAt(0).toUpperCase() + env.slice(1)} Environment
          </Typography>

          {config.lenderConfigs
            .filter((cfg) => cfg.environment === env)
            .map((cfg) => (
              <Chip
                key={`${cfg.id}-${cfg.environment}`}
                label={`${cfg.lenderName} (ID: ${cfg.id})`}
                className={classes.chip}
                onDelete={() => deleteLenderConfig(cfg.id, cfg.environment)}
              />
            ))}

          <AddLenderConfig environment={env as any} onAdd={addLenderConfig} />
        </div>
      ))}
    </div>
  );
};

const AddLenderConfig = ({
  environment,
  onAdd
}: {
  environment: 'production' | 'staging' | 'pre-production';
  onAdd: (lenderId: number, environment: any, lenderName: ELender) => void;
}) => {
  const [lenderId, setLenderId] = useState('');
  const [selectedLender, setSelectedLender] = useState<ELender>(
    ELender.Aldermore
  );
  const classes = useStyles();

  const handleSubmit = () => {
    if (lenderId && selectedLender) {
      onAdd(parseInt(lenderId), environment, selectedLender);
      setLenderId('');
      setSelectedLender(ELender.Aldermore);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <TextField
          label="Lender ID"
          value={lenderId}
          onChange={(e) => setLenderId(e.target.value)}
          type="number"
        />
        <FormControl className={classes.formControl}>
          <InputLabel>Lender</InputLabel>
          <Select
            value={selectedLender}
            onChange={(e) => setSelectedLender(e.target.value as ELender)}>
            {Object.values(ELender).map((lender) => (
              <MenuItem key={lender} value={lender}>
                {lender}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Add
        </Button>
      </div>
    </div>
  );
};
