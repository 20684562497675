import { ELender, IProposalStructure } from '../interface';
import {
  Aldermore,
  CAS,
  DLL,
  Fleximize,
  FundingCircle,
  Investec,
  MannIsland,
  Northridge,
  Novuna,
  Praetura,
  PremiumCredit,
  UltimateFinance,

  // Ids
  getAldermoreIds,
  getFleximizeIds,
  getFundingCircleIds,
  getInvestecIds,
  getCASIds,
  getUltimateFinanceIds
} from '../consts/index';
import { getNorthridgeIds } from '../consts/Northridge/MappedProposal';
import { getPraeturaIds } from '../consts/Praetura/MappedProposal';
import { getDLLIds } from '../consts/DLL/MappedProposal';
import { getNovunaIds } from '../consts/Novuna/MappedProposal';
import firebase from 'firebase';

type TLenderNameToId = {
  [key in ELender]: number[];
};

export const proposalConfig: Record<number, IProposalStructure> = {
  28: {
    defaultValues: Aldermore,
    lenderTitle: ELender.Aldermore,
    mappedIds: getAldermoreIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 1,
      minAddress: 1
    },
    allowedDealFlow: [466]
  },

  //! Development For Aldermore
  // 134378: {
  //   defaultValues: Aldermore,
  //   lenderTitle: ELender.Aldermore,
  //   mappedIds: getAldermoreIds(),
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 1
  //   },
  //   allowedDealFlow: [466]
  // },
  //! END

  7965: {
    defaultValues: Novuna,
    lenderTitle: ELender.Novuna,
    mappedIds: getNovunaIds(),
    status: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 1
    },
    uploadDocuments: false,
    allowedDealFlow: [466]
  },

  //! Development For Novuna
  // 134378: {
  //   defaultValues: Novuna,
  //   lenderTitle: ELender.Novuna,
  //   mappedIds: getNovunaIds(),
  //   status: true,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 1
  //   },
  //   uploadDocuments: false,
  //   allowedDealFlow: [466]
  // },
  //! END

  73: {
    defaultValues: CAS,
    lenderTitle: ELender.CAS,
    mappedIds: getCASIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466, 474]
  },

  //! Development For CAS
  // 134378: {
  //   defaultValues: CAS,
  //   lenderTitle: ELender.CAS,
  //   mappedIds: getCASIds(),
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466, 474]
  // },
  //! END

  421: {
    defaultValues: Fleximize,
    lenderTitle: ELender.Fleximize,
    mappedIds: getFleximizeIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 0
    },
    allowedDealFlow: [534, 535],
    allowedEntities: ['Limited Company']
  },
  92: {
    defaultValues: Fleximize,
    lenderTitle: ELender.Fleximize,
    mappedIds: getFleximizeIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 0
    },
    allowedDealFlow: [474],
    allowedEntities: ['Limited Company']
  },

  //! Development For Fleximize
  // 134378: {
  //   defaultValues: Fleximize,
  //   lenderTitle: ELender.Fleximize,
  //   mappedIds: getFleximizeIds(),
  //   status: true,
  //   uploadDocuments: true,
  //   requirements: {
  //     minDirectors: 1,
  //     minShareholders: 1,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [474],
  //   allowedEntities: ['Limited Company']
  // },
  //! END

  111: {
    defaultValues: Investec,
    lenderTitle: ELender.Investec,
    mappedIds: getInvestecIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 1
    },
    allowedDealFlow: [466, 474],
    fieldVisibility: [
      {
        field: 'client.soleTrader',
        dependsOn: 'client.companyType',
        showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
      },

      {
        field: 'client.company.partner',
        dependsOn: 'client.companyType',
        showWhen: ['PARTNERSHIP']
      },
      {
        field: 'client.company.director',
        dependsOn: 'client.companyType',
        showWhen: ['LIMITEDCOMPANY']
      }
    ]
  },

  160: {
    defaultValues: Investec,
    lenderTitle: ELender.Quantum,
    mappedIds: getInvestecIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 1
    },
    allowedDealFlow: [466],
    fieldVisibility: [
      {
        field: 'client.soleTrader',
        dependsOn: 'client.companyType',
        showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
      },

      {
        field: 'client.company.partner',
        dependsOn: 'client.companyType',
        showWhen: ['PARTNERSHIP']
      },
      {
        field: 'client.company.director',
        dependsOn: 'client.companyType',
        showWhen: ['LIMITEDCOMPANY']
      }
    ]
  },

  //! Development For Investec
  // 134378: {
  //   defaultValues: Investec,
  //   lenderTitle: ELender.Investec,
  //   mappedIds: getInvestecIds(),
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 1,
  //     minShareholders: 1,
  //     minAssets: 1
  //   },
  //   allowedDealFlow: [466, 474],
  //   fieldVisibility: [
  //     {
  //       field: 'client.soleTrader',
  //       dependsOn: 'client.companyType',
  //       showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
  //     },

  //     {
  //       field: 'client.company.partner',
  //       dependsOn: 'client.companyType',
  //       showWhen: ['PARTNERSHIP']
  //     },
  //     {
  //       field: 'client.company.director',
  //       dependsOn: 'client.companyType',
  //       showWhen: ['LIMITEDCOMPANY']
  //     }
  //   ]
  // },
  //! END

  130: {
    defaultValues: Northridge,
    lenderTitle: ELender.Northridge,
    mappedIds: getNorthridgeIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466]
  },

  8765: {
    defaultValues: DLL,
    lenderTitle: ELender.DLL,
    mappedIds: getDLLIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466]
  },

  //! Development for DLL
  134378: {
    defaultValues: DLL,
    lenderTitle: ELender.DLL,
    mappedIds: getDLLIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466]
  },
  //! END

  8035: {
    defaultValues: FundingCircle,
    lenderTitle: ELender.FundingCircle,
    mappedIds: getFundingCircleIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [534, 535],
    allowedEntities: ['Limited Company']
  },

  26: {
    defaultValues: FundingCircle,
    lenderTitle: ELender.FundingCircle,
    mappedIds: getFundingCircleIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [474],
    allowedEntities: ['Limited Company']
  },

  //! Development for Funding Circle
  // 134378: {
  //   defaultValues: FundingCircle,
  //   lenderTitle: ELender.FundingCircle,
  //   mappedIds: getFundingCircleIds(),
  //   status: true,
  //   uploadDocuments: true,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [474, 534, 535],
  //   allowedEntities: ['Limited Company']
  // },
  //! END

  //! Development For Northridge
  // 134378: {
  //   defaultValues: Northridge,
  //   lenderTitle: ELender.Northridge,
  //   mappedIds: getNorthridgeIds(),
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466]
  // },
  //! END

  // 7965: {
  //   defaultValues: Novuna,
  //   lenderTitle: ELender.Novuna,
  //   mappedIds: {},
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466]
  // },

  154: {
    defaultValues: Praetura,
    lenderTitle: ELender.Praetura,
    mappedIds: getPraeturaIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0,
      minApplicants: 1
    },
    allowedDealFlow: [466],
    allowedEntities: [
      'Limited Company',
      'Sole Trader',
      'Partnership',
      'Limited Liability Partnership'
    ]
  },

  //! Development For Praetura
  // 134378: {
  //   defaultValues: Praetura,
  //   lenderTitle: ELender.Praetura,
  //   mappedIds: getPraeturaIds(),
  //   status: true,
  //   uploadDocuments: true,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0,
  //     minApplicants: 1
  //   },
  //   allowedDealFlow: [466],
  //   allowedEntities: [
  //     'Limited Company',
  //     'Sole Trader',
  //     'Partnership',
  //     'Limited Liability Partnership'
  //   ]
  // },
  //! END

  // 8745: {
  //   defaultValues: PremiumCredit,
  //   lenderTitle: ELender.PremiumCredit,
  //   mappedIds: {},
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [534, 535]
  // },
  // 157: {
  //   defaultValues: PremiumCredit,
  //   lenderTitle: ELender.PremiumCredit,
  //   mappedIds: {},
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466, 474]
  // },

  176: {
    defaultValues: UltimateFinance,
    lenderTitle: ELender.UltimateFinance,
    mappedIds: getUltimateFinanceIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466, 474]
  }

  //! Development For UltimateFinance
  // 134378: {
  //   defaultValues: UltimateFinance,
  //   lenderTitle: ELender.UltimateFinance,
  //   mappedIds: getUltimateFinanceIds(),
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466, 474]
  // }
  //! END
};

/**
 * Retrieves the proposal structure for a given lender ID or name.
 *
 * @param {number | ELender} lenderIdOrName - The numeric ID or enum name of the lender to match.
 * @returns {IProposalStructure | undefined} The matching proposal structure or undefined if not found.
 *
 * @example
 * Returns the proposal structure for FundingCircle by ID
 * const structure = getLenderProposalStructure(26);
 *
 * @example
 * Returns the proposal structure for FundingCircle by name
 * const structure = getLenderProposalStructure(ELender.FundingCircle);
 */
export const getLenderProposalStructure = async (
  lenderId: number,
  lenderName: ELender,
  configRef: firebase.firestore.DocumentReference
): Promise<IProposalStructure | undefined> => {
  try {
    const mapping = await getLenderMapping(configRef);
    const lenderIds = mapping[lenderName] || [];

    if (!lenderIds.includes(lenderId)) return undefined;
    const structure = proposalConfig[lenderId];

    if (!structure) return undefined;
    return structure;
  } catch (error) {
    console.error('Error in getLenderProposalStructure:', error);
    return undefined;
  }
};

export const getLenderMapping = async (
  configRef: firebase.firestore.DocumentReference
): Promise<TLenderNameToId> => {
  try {
    const doc = await configRef.get();
    const data = doc.data();

    if (!data?.lenderConfigs) return {} as TLenderNameToId;

    // Group configs by lender name and collect their IDs
    const mapping = data.lenderConfigs.reduce((acc, config) => {
      if (!acc[config.lenderName]) {
        acc[config.lenderName] = [];
      }
      if (!acc[config.lenderName].includes(config.id)) {
        acc[config.lenderName].push(config.id);
      }
      return acc;
    }, {} as TLenderNameToId);

    return mapping;
  } catch (error) {
    console.error('Error fetching lender mapping:', error);
    return {} as TLenderNameToId;
  }
};

/**
 * Retrieves the proposal structure for a given lender ID, with visibility controlled by environment.
 *
 * @param {number} lenderId - The numeric ID of the lender to match.
 * @returns {IProposalStructure | undefined} The matching proposal structure or undefined if not found or hidden in the current environment.
 *
 * @description
 * This function handles the visibility of proposal structures differently based on the current environment:
 * - In production (REACT_APP_FB_PROJECTID === 'financial--manager'):
 *   - Hides structures for lender IDs [28, 389, 421, 92, 137006].
 *   - Shows all other structures.
 * - In staging (REACT_APP_FB_PROJECTID === 'finbro-tech'):
 *   - Hides structures for lender IDs [28, 389].
 *   - Shows all other structures.
 * - In local or other environments:
 *   - Shows all structures.
 *
 * @example
 * Returns the proposal structure for a lender with ID 26 (visible in all environments)
 * const structure = getProposalStructureById(26);
 *
 * @example
 * Returns undefined in production, but may return a structure in staging or local environments
 * const hiddenStructure = getProposalStructureById(28);
 */
export const getProposalStructureById = async (
  lenderId: number,
  configRef: firebase.firestore.DocumentReference
): Promise<IProposalStructure | undefined> => {
  try {
    const doc = await configRef.get();
    const data = doc.data();

    if (!data?.lenderConfigs) return undefined;
    let environment: 'production' | 'staging' | 'pre-production';

    if (process.env.REACT_APP_FB_PROJECTID === 'financial--manager') {
      environment =
        window.location.origin === 'https://test.bips.tech'
          ? 'pre-production'
          : 'production';
    } else if (process.env.REACT_APP_FB_PROJECTID === 'finbro-tech') {
      environment = 'staging';
    } else {
      environment = 'staging';
    }

    const isLenderEnabled = data.lenderConfigs.some(
      (config) => config.id === lenderId && config.environment === environment
    );

    return isLenderEnabled ? proposalConfig[lenderId] : undefined;
  } catch (error) {
    console.error('Error fetching lender configuration:', error);
    return undefined;
  }
};
