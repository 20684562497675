import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Tools } from '../Calculator/interfaces';
import { IPeriodColumns } from '../Calculator/functions/setInitPeriods';
import {
  Button,
  useTheme,
  Typography,
  Grid,
  InputAdornment
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Delete, Lock } from '@material-ui/icons';
import {
  calculateAndSetEndDate,
  calculateTotalMonthsAcrossAllRows,
  cascadeStartDates,
  createEmptyRow,
  getDateFormat,
  getDatePickerViews,
  getMinStartDateForNextRow,
  getMonthsMultiplier,
  getNumberHelperText,
  getTotalMonthsText,
  recalculatePeriodsFromIndex,
  transformDataForExtras,
  validateRowSequence
} from './functions';
import moment from 'moment';
import NumericFormat from 'react-number-format';
import { grey } from '@material-ui/core/colors';
import { OverridePayment, PaymentFrequency } from 'types/calculatorInterfaces';

export interface IRowData {
  [key: string]: number | string | undefined;
}

export interface IExtra {
  Amount: number;
  StartPeriod: number;
  EndPeriod: number;
  ExPayOverRide: string;
}

const SeasonalPayments = ({
  tools,
  handleClose
}: {
  tools: Tools;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const { period, state, setRows, rows, setState, setExtras } = tools;

  const handleAddRow = () => {
    const newEmptyRow = createEmptyRow(
      period.columns,
      rows,
      state.StartDate,
      state
    );
    setRows(rows.concat(newEmptyRow));
  };

  const handleCellChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    column: IPeriodColumns
  ) => {
    if (column.field === 'Number') {
      handleNumberChange(event, rowIndex);
      return;
    }

    const updatedRows = [...rows];
    const newValue = event.target.value;

    if (column.type === 'numeric') {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: newValue === '' ? '' : newValue
      };
    } else {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: newValue
      };
    }

    if (column.field === 'PaymentFrequency' || column.field === 'Number') {
      calculateAndSetEndDate(updatedRows, rowIndex);
      recalculatePeriodsFromIndex(updatedRows, rowIndex);
    }

    setRows(updatedRows);
  };

  const handleDeleteRow = (rowIndex: number) => {
    const deletedRow: IRowData = rows[rowIndex];

    const transformedRow = transformDataForExtras(
      [deletedRow],
      state.StartDate,
      state
    )[0];

    const updatedOverridePayments = state.OverridePayments.filter(
      (override) =>
        !(
          override.StartPeriod === transformedRow.StartPeriod &&
          override.EndPeriod === transformedRow.EndPeriod &&
          override.Amount === transformedRow.Amount &&
          override.ExPayOverRide === transformedRow.ExPayOverRide
        )
    );

    setState({ ...state, OverridePayments: updatedOverridePayments });

    // Original row deletion logic
    const updatedRows = [
      ...rows.slice(0, rowIndex),
      ...rows.slice(rowIndex + 1)
    ];

    if (rowIndex === rows.length - 1) {
      setRows(updatedRows);
      setExtras(updatedRows);
      return;
    }

    recalculatePeriodsFromIndex(updatedRows, rowIndex);
    setExtras(updatedRows);
    setRows(updatedRows);
  };

  const handleDateChange = (
    date: MaterialUiPickersDate,
    rowIndex: number,
    column: IPeriodColumns
  ) => {
    const updatedRows = [...rows];

    if (date && date.isValid()) {
      const dateString = [
        date.year().toString().padStart(4, '0'),
        (date.month() + 1).toString().padStart(2, '0'),
        date.date().toString().padStart(2, '0')
      ].join('-');

      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: dateString
      };

      if (rowIndex === 0 && date.isBefore(state.StartDate)) {
        setState((prevState) => ({
          ...prevState,
          StartDate: dateString
        }));
      }

      let validate;
      if (state.PaymentFrequency === PaymentFrequency.Monthly) {
        const validateRowSequences = validateRowSequence(updatedRows);
        validate = validateRowSequences;
      } else {
        validate = true;
      }

      if (validate) {
        recalculatePeriodsFromIndex(updatedRows, rowIndex);
      }
    } else {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: ''
      };
    }

    setRows(updatedRows);
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ): void => {
    const updatedRows = [...rows];
    const newValue = event.target.value;

    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      Number: newValue
    };

    if (newValue && updatedRows[rowIndex]['StartPeriod']) {
      const number = parseInt(newValue);
      let totalMonths = number;

      switch (state.PaymentFrequency) {
        case PaymentFrequency.Annually:
          totalMonths = number * 12;
          break;
        case PaymentFrequency.SemiAnnually:
          totalMonths = number * 6;
          break;
        case PaymentFrequency.Quarterly:
          totalMonths = number * 3;
          break;
        case PaymentFrequency.Monthly:
          totalMonths = number;
          break;
      }

      const startDate = moment(updatedRows[rowIndex]['StartPeriod']);
      const endDate = moment(startDate).add(totalMonths, 'months');
      updatedRows[rowIndex]['EndPeriod'] = endDate.format('YYYY-MM-DD');

      cascadeStartDates(updatedRows, rowIndex, state);
    }

    setRows(updatedRows);
  };

  // Helper function to format numeric field values
  const getNumericFieldValue = (value: number | string | undefined) => {
    if (value === undefined || value === '') return '';
    return value.toString();
  };

  return (
    <>
      <Grid item xs={12} style={{ paddingBottom: theme.spacing(2) }}>
        <Typography variant="h5">
          {calculateTotalMonthsAcrossAllRows(rows, state)}
        </Typography>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {period.columns.map((column: IPeriodColumns) => (
                <TableCell key={column.field}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              const isStartPeriodValid =
                row['StartPeriod'] &&
                !isNaN(new Date(row['StartPeriod'] as string).getTime());

              return (
                <TableRow
                  key={rowIndex}
                  style={{
                    backgroundColor: !isStartPeriodValid ? '#ffcccc' : ''
                  }}>
                  {period.columns.map((column: IPeriodColumns) => {
                    const paymentFrequency = state.PaymentFrequency;

                    if (column.type === 'date') {
                      const views = getDatePickerViews(
                        paymentFrequency.toString()
                      );

                      const dateFormat = getDateFormat(
                        paymentFrequency.toString()
                      );

                      const months = row['Number'] || '';
                      const minStartDate =
                        rowIndex > 0
                          ? getMinStartDateForNextRow(rows.slice(0, rowIndex))
                          : undefined;

                      return (
                        <TableCell key={column.field}>
                          <KeyboardDatePicker
                            size="small"
                            variant="inline"
                            views={views}
                            style={{ width: 120 }}
                            format={dateFormat}
                            value={
                              row[column.field]
                                ? moment(row[column.field])
                                : state.StartDate
                            }
                            onChange={(date: MaterialUiPickersDate) =>
                              handleDateChange(date, rowIndex, column)
                            }
                            minDate={
                              minStartDate
                                ? minStartDate
                                : new Date('1900-01-01')
                            }
                            disabled={column.disabled}
                            autoOk
                            helperText={
                              column.field === 'EndPeriod' &&
                              months !== null &&
                              months !== ''
                                ? getTotalMonthsText(
                                    paymentFrequency.toString(),
                                    parseInt(months.toString())
                                  )
                                : ''
                            }
                          />
                        </TableCell>
                      );
                    }

                    const helperText = getNumberHelperText(state);
                    return (
                      <TableCell key={column.field}>
                        {column.type === 'select' ? (
                          <TextField
                            value={
                              row[column.field] === '1'
                                ? state.PaymentFrequency
                                : 'Seasonal Payment'
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleCellChange(event, rowIndex, column)}
                            disabled
                            style={
                              row[column.field] === '1'
                                ? { width: 120 }
                                : { width: 155 }
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <Lock
                                    style={{
                                      color: grey[300]
                                    }}
                                  />
                                </InputAdornment>
                              )
                            }}
                          />
                        ) : column.title === 'Amount' ? (
                          <CustomNumericInput
                            value={row[column.field]}
                            placeholder={column.title}
                            onChange={(event) =>
                              handleCellChange(
                                event as React.ChangeEvent<HTMLInputElement>,
                                rowIndex,
                                column
                              )
                            }
                            disabled={column.disabled}
                          />
                        ) : (
                          <TextField
                            size="small"
                            type={column.type === 'numeric' ? 'number' : 'text'}
                            value={getNumericFieldValue(row[column.field])}
                            placeholder={column.title}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = Number(event.target.value);
                              if (
                                state.PaymentFrequency !==
                                PaymentFrequency.Monthly
                              ) {
                                if (!isNaN(value) && value <= 1) {
                                  const newEvent = {
                                    ...event,
                                    target: {
                                      ...event.target,
                                      value: String(value)
                                    }
                                  } as React.ChangeEvent<HTMLInputElement>;
                                  handleCellChange(newEvent, rowIndex, column);
                                }
                              } else {
                                handleCellChange(event, rowIndex, column);
                              }
                            }}
                            disabled={
                              column.title === 'Number'
                                ? !isStartPeriodValid
                                : column.disabled
                            }
                            helperText={
                              column.title === 'Number'
                                ? !isStartPeriodValid
                                  ? 'Invalid Start Date'
                                  : helperText
                                : ''
                            }
                          />
                        )}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <Button
                      onClick={() => handleDeleteRow(rowIndex)}
                      size="small"
                      variant="text">
                      <Delete style={{ color: theme.palette.error.main }} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        spacing={1}
        style={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2)
        }}>
        <Grid item>
          <Button
            onClick={handleAddRow}
            color="primary"
            style={{
              backgroundColor: theme.palette.success.main,
              width: '20vw',
              fontWeight: 'bold'
            }}
            variant="contained">
            Add new Line
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              backgroundColor: theme.palette.warning.main,
              width: '20vw',
              fontWeight: 'bold'
            }}
            onClick={handleClose}
            disabled={rows.length === 0}
            color="primary"
            variant="contained">
            Save Seasonal Payments
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              backgroundColor: theme.palette.info.main,
              width: '20vw',
              fontWeight: 'bold'
            }}
            onClick={() => {
              setRows([]);
              setExtras([]);
              const updatedOverridePayments = state.OverridePayments.filter(
                (override) => !override.IsMonth
              );

              setState({ ...state, OverridePayments: updatedOverridePayments });
            }}
            color="primary"
            disabled={rows.length === 0}
            variant="contained">
            Clear Seasonal Payments
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SeasonalPayments;

interface ICustomNumericFormatProps {
  onChange: (event: { target: { value: string } }) => void;
  value: string | number | undefined;
  placeholder: string;
  disabled?: boolean;
}

const CustomNumericInput = React.forwardRef<
  HTMLInputElement,
  ICustomNumericFormatProps
>(({ onChange, value, disabled, placeholder, ...other }, ref) => {
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={value}
      placeholder={placeholder}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale
      prefix="£"
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      customInput={TextField}
      disabled={disabled}
    />
  );
});
