import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import {
  SET_CHECKLIST_SCHEMA,
  UPDATE_CHECKLIST_RULE
} from 'redux/actions/types';
import { useTypedSelector } from 'redux/reducers';
import { IChecklistRules } from 'redux/reducers/schemaReducer';

const useChecklist = () => {
  const dispatch = useDispatch();
  const { user } = useTypedSelector((s) => s.user);
  const { checklistRules } = useTypedSelector((s) => s.schema);

  const saveCommentsToFirestore = async (
    dealTypeId: string,
    ruleId: string,
    comment: string
  ) => {
    try {
      const docRef = firebase
        .firestore()
        .collection('globalSetting')
        .doc('ruleSchema')
        .collection('checklistRuleSchema')
        .doc(dealTypeId);
      const doc = await docRef.get();
      const existingData = doc.exists ? doc.data() : {};

      const regulationType = existingData?.[ruleId]?.regulationType;
      let commentField = 'comments';

      if (regulationType === 'Non-Regulated') {
        commentField = 'nonRegulatedComment';
      } else if (regulationType === 'Regulated') {
        commentField = 'regulatedComment';
      }

      const updatedData = {
        ...existingData,
        [ruleId]: {
          ...(existingData?.[ruleId] || {}),
          [commentField]: comment
        }
      };

      await docRef.set(updatedData, { merge: true });
    } catch (e) {
      console.error('Error saving comments:', e);
    }
  };

  const fetchCommentsFromFirestore = async (dealTypeId: string) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection('globalSetting')
        .doc('ruleSchema')
        .collection('checklistRuleSchema')
        .doc(dealTypeId)
        .get();

      const data = snapshot.data();
      if (!data) return {};

      const comments: { [key: string]: any } = {};
      Object.entries(data).forEach(([ruleId, ruleData]) => {
        if (typeof ruleData === 'object') {
          comments[ruleId] = {
            comments: ruleData.comments,
            regulatedComment: ruleData.regulatedComment,
            nonRegulatedComment: ruleData.nonRegulatedComment
          };
        }
      });

      return comments;
    } catch (e) {
      console.error('Error fetching comments:', e);
      return {};
    }
  };

  const loadChecklistIntoRedux = async () => {
    const snapshot = await firebase
      .firestore()
      .collection('globalSetting')
      .doc('ruleSchema')
      .collection('checklistRuleSchema')
      .get();

    const rules: IChecklistRules = snapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data();
      return acc;
    }, {});

    dispatch({ type: SET_CHECKLIST_SCHEMA, payload: rules });
  };

  const loadRulePreventionIntoRedux = async () => {};

  const handleChecklistUpdate = async ({
    ruleId,
    isMandatory,
    regulationType,
    dealTypeId
  }: {
    ruleId: string;
    isMandatory: boolean;
    regulationType: 'Regulated' | 'Non-Regulated' | 'Both';
    dealTypeId: string;
  }) => {
    if (user.SystemAccess > 12) {
      const ruleData = {
        isMandatory,
        regulationType
      };

      try {
        await firebase
          .firestore()
          .collection('globalSetting')
          .doc('ruleSchema')
          .collection('checklistRuleSchema')
          .doc(dealTypeId)
          .set({ [ruleId]: ruleData }, { merge: true });

        dispatch({
          type: UPDATE_CHECKLIST_RULE,
          payload: { dealTypeId, ruleId, ruleData }
        });
      } catch (e) {
        console.error('Error updating checklist:', e);
      }
    }
  };

  return {
    loadChecklistIntoRedux,
    handleChecklistUpdate,
    loadRulePreventionIntoRedux,
    checklistRules,
    saveCommentsToFirestore,
    fetchCommentsFromFirestore
  };
};

export default useChecklist;
