import { firebase } from 'redux/firebase';
import { Overview, UserInstance } from 'types/interfaces';
import { store } from 'redux/store';
import { callMsGraphDetailed } from 'services/microsoft/graph';
import {
  Message,
  FileAttachment,
  NullableOption
} from '@microsoft/microsoft-graph-types';
import { Thread, IFile } from '../interfaces';
import * as gtag from 'Utils/gtag';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { BugTracker } from 'Utils/Bugtracker';

const db = firebase.firestore();

interface IProps {
  accessToken: string;
  html: any;
  selectedThreadKey: string;
  subject: string;
  threads: { [key: string]: Thread };
  conversation: Message[] | null;
  files: IFile[];
  currentOverView: Overview;
  ccUsers: string[];
}

/**
 * Sends an email through Microsoft Graph API with support for attachments and thread management
 * @param {IProps} props - Email configuration and content
 * @returns {Promise<any>} Response from the email sending operation
 * @throws {Error} When thread is not found or email sending fails
 */
export const sendEmail = async ({
  accessToken,
  html,
  selectedThreadKey,
  subject,
  threads,
  conversation,
  files,
  currentOverView,
  ccUsers
}: IProps) => {
  const loggedInUser: UserInstance = store.getState().user.user;
  const currentDealProcess = store.getState().process.currentDealProcess;
  const baseURL = store.getState().config.baseURL;
  const deal = store.getState().dealSummary.deal;

  const thread = threads[selectedThreadKey];
  if (!thread) return new Error('No Thread');
  const members = thread.members;
  try {
    // get email addresses from userinstanceIds
    const membersEmails = await getMembersList({ loggedInUser, members });
    // create recipient array
    const toRecipients = membersEmails
      .filter((email) => email !== loggedInUser.UserInstanceEmail)
      .map((email) => ({
        emailAddress: { address: email }
      }));

    // Helper function to convert ArrayBuffer to base64 for attachment handling
    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) binary += String.fromCharCode(bytes[i]);
      return window.btoa(binary);
    };

    // Helper function to read file as ArrayBuffer
    const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    };

    // Process email attachments
    const attachments: FileAttachment[] = [];
    try {
      await Promise.all(
        files.map(async (file: any) => {
          const buffer = await readFileAsArrayBuffer(file);
          const attachment = {
            '@odata.type': '#microsoft.graph.fileAttachment',
            name: file.name,
            contentType: file.type,
            contentBytes: arrayBufferToBase64(buffer)
          };

          attachments.push(attachment);
        })
      );
    } catch (error) {
      console.error('Error reading files: ', error);
    }

    if (toRecipients && toRecipients.length < 1) {
      return console.log(
        'You do not have members in your group other than yourself'
      );
    }

    let message: Message = {};

    /**
     * Validates if an expected recipient email exists in the most recent message's recipients list.
     * The function specifically looks at the newest email in the conversation to avoid
     * checking outdated recipient lists from older messages.
     *
     * @param expectedRecipientEmail - The email address to validate against the recipient list
     * @param conversation - Array of Message objects representing the email conversation
     * @returns boolean indicating whether the expected recipient is found in the latest message's recipients
     */
    const validateReplyAllRecipients = (
      expectedRecipientEmail: string,
      conversation: Message[]
    ): boolean => {
      // Early return if inputs are invalid
      if (!expectedRecipientEmail || !conversation?.length) {
        return false;
      }

      // Find the most recent message that has recipients
      const sortedMessages = [...conversation].sort((a, b) => {
        const dateA = new Date(a.createdDateTime || 0);
        const dateB = new Date(b.createdDateTime || 0);
        return dateB.getTime() - dateA.getTime();
      });

      const newestMessageWithRecipients = sortedMessages.find(
        (message) => message?.toRecipients && message?.toRecipients?.length > 0
      );

      if (!newestMessageWithRecipients?.toRecipients) {
        return false;
      }

      // Normalize and validate the email address
      const normalizedExpectedEmail = expectedRecipientEmail
        .toLowerCase()
        .trim();

      return newestMessageWithRecipients.toRecipients.some((recipient) => {
        const recipientEmail = recipient?.emailAddress?.address;
        return recipientEmail?.toLowerCase().trim() === normalizedExpectedEmail;
      });
    };

    /**
     * Checks if all conditions are met for a valid reply-all scenario.
     * Validates recipient email matches, conversation existence, and rule configurations.
     *
     * @returns Promise resolving to boolean - true if all reply-all conditions are met, false otherwise
     */
    const isReplyAllConditionMet = async () => {
      if (!conversation) return false;

      const getToRecipientEmail = await getQuickLiteUser({
        UserInstanceId: parseInt(thread.members[1].toString()),
        baseUrl: baseURL,
        action: 'GET'
      });

      if (
        getToRecipientEmail &&
        !validateReplyAllRecipients(
          getToRecipientEmail?.UserInstance.UserInstanceEmail,
          conversation
        )
      ) {
        return false;
      }

      if (!deal.ruleConversations || !deal.ruleConversations[thread.members[1]])
        return false;

      if (deal.ruleConversations[thread.members[1]][thread.type] === undefined)
        return false;

      return true;
    };

    /**
     * Determines if the current thread represents a valid reply-all situation.
     * Evaluates thread existence, conversation rules, and member configurations.
     *
     * @returns Promise resolving to boolean | undefined
     *          - true if valid reply-all conditions are met
     *          - false if conditions fail
     *          - undefined if thread doesn't exist
     */
    const getIsReplyAll = async (): Promise<boolean | undefined> => {
      if (!thread) return;
      if (!deal.ruleConversations) {
        return thread ? thread.conversationId !== undefined : false;
      }

      const ruleList = deal.ruleConversations[thread.members[1]];
      if (thread?.members && ruleList) {
        if (ruleList[thread.type]) {
          console.log({ ruleList });
          return await isReplyAllConditionMet();
        }
      }
    };

    const isReplyAll = await getIsReplyAll();
    if (isReplyAll && conversation) {
      const data = {
        comment: html,
        replyTo: toRecipients,
        ccRecipients: ccUsers.map((user) => ({
          emailAddress: {
            address: user
          }
        }))
      };

      interface MessageGroup {
        conversationId: NullableOption<string> | undefined;
        messages: Message[];
      }

      const dealOwner = currentDealProcess.UserInstanceId === loggedInUser.Id;
      const systemAccess = loggedInUser.SystemAccess === 4;

      const uniqueConversations = conversation.reduce<MessageGroup[]>(
        (acc, current) => {
          let group = acc.find(
            (group) => group.conversationId === current.conversationId
          );

          if (!group) {
            group = { conversationId: current.conversationId, messages: [] };
            acc.push(group);
          }

          const duplicate = group.messages.find(
            (message) => message.internetMessageId === current.internetMessageId
          );

          if (!duplicate) {
            group.messages.push(current);
          }

          return acc;
        },
        []
      );

      let res, fallback;
      const createDraftMessage = async (newId?: string) => {
        try {
          const filter = uniqueConversations.find(
            (conversation) =>
              conversation.conversationId === thread.conversationId
          );

          const method = 'POST';
          const url = `https://graph.microsoft.com/v1.0/me/messages/${
            newId ? newId : filter?.messages[0]?.id
          }/createReplyAll`;

          const response = await callMsGraphDetailed({
            accessToken,
            url,
            method,
            data
          });

          return response;
        } catch (error) {
          console.error('Error creating draft message:', error);
          BugTracker.notify(error);
          throw error;
        }
      };

      try {
        res = await createDraftMessage();
        if (!res && fallback === 4) {
          res = await createDraftMessage(uniqueConversations[1].messages[0].id);
        }

        console.log({ message, res });
        message = res?.data;
        if (attachments) {
          await Promise.all(
            attachments.map((attachment: FileAttachment) =>
              addAttachmentToDraft({ message, accessToken, attachment })
            )
          );
        }

        return await sendDraftMessage({
          message,
          accessToken,
          thread,
          subject,
          currentOverView
        });
      } catch (error) {
        console.error(
          'Error in message creation or attachment process:',
          error
        );
        BugTracker.notify(error);
        throw error;
      }
    } else {
      const data = {
        subject,
        importance: 'high',
        body: { contentType: 'HTML', content: html },
        toRecipients,
        ccRecipients: ccUsers.map((user) => ({
          emailAddress: {
            address: user
          }
        }))
      };
      const createDraftMessage = async () => {
        const method = 'POST';
        const url = `https://graph.microsoft.com/v1.0/me/messages`;
        const res = await callMsGraphDetailed({
          accessToken,
          url,
          method,
          data
        });
        return res;
      };
      const res = await createDraftMessage();
      message = res?.data;
      if (attachments) {
        await Promise.all(
          attachments.map((attachment: FileAttachment) =>
            addAttachmentToDraft({ message, accessToken, attachment })
          )
        );
      }

      await saveConversationIdToThread({ message, selectedThreadKey });
      await sendDraftMessage({
        message,
        accessToken,
        thread,
        subject,
        currentOverView
      });
    }
  } catch (e: any) {
    throw new Error(e);
  }
};

/**
 * Saves conversation ID to Firebase thread document
 * @param {Object} params - Parameters for saving conversation ID
 * @param {Message} params.message - Message object containing conversation ID
 * @param {string} params.selectedThreadKey - Thread document ID
 * @returns {Promise<any>} Firebase update operation result
 */
export const saveConversationIdToThread = ({ message, selectedThreadKey }) => {
  const { conversationId } = message;
  const ref = db.collection('thread').doc(selectedThreadKey);
  return ref
    .update({ conversationId })
    .then((res) => res)
    .catch((e) => e);
};

/**
 * Adds an attachment to a draft message
 * @param {Object} params - Parameters for adding attachment
 * @param {Message} params.message - Draft message object
 * @param {string} params.accessToken - Microsoft Graph API access token
 * @param {FileAttachment} params.attachment - File attachment object
 * @returns {Promise<any>} Response from attachment addition operation
 */
export const addAttachmentToDraft = async ({
  message,
  accessToken,
  attachment
}: {
  message: Message;
  accessToken: string;
  attachment: FileAttachment;
}) => {
  const { id } = message;
  const method = 'POST';
  const url = `https://graph.microsoft.com/v1.0/me/messages/${id}/attachments`;
  const res = await callMsGraphDetailed({
    accessToken,
    url,
    method,
    data: attachment
  });

  return res;
};

/**
 * Retrieves email addresses for thread members
 * @param {Object} params - Parameters for getting member list
 * @param {UserInstance} params.loggedInUser - Currently logged in user
 * @param {string[]} params.members - Array of member IDs
 * @returns {Promise<string[]>} Array of member email addresses
 */
const getMembersList = async ({ loggedInUser, members }) => {
  const baseUrl: string = store.getState().config.baseURL;

  let membersEmails: any = [];
  const sortMembersList = members.map(async (UserInstanceId: string) => {
    if (UserInstanceId === loggedInUser.Id) return;

    const newUserInstanceId: number = parseInt(UserInstanceId);
    const response = await getQuickLiteUser({
      baseUrl,
      UserInstanceId: newUserInstanceId,
      action: 'GET'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) membersEmails.push(UserInstance.UserInstanceEmail);
  });
  await Promise.all(sortMembersList);
  return membersEmails;
};

/**
 * Sends a draft message and updates Firebase with conversation details
 * @param {Object} params - Parameters for sending draft message
 * @param {Message} params.message - Draft message to send
 * @param {string} params.accessToken - Microsoft Graph API access token
 * @param {Thread} params.thread - Thread object
 * @param {string} params.subject - Email subject
 * @param {Overview} params.currentOverView - Current overview object
 * @param {string} params.html - Email content in HTML format
 * @param {Array} params.toRecipients - Array of recipient objects
 * @param {Array} params.ccRecipients - Array of CC recipient objects
 * @param {string} params.selectedThreadKey - Selected thread key
 * @returns {Promise<any>} Response from send operation
 * @throws {Error} When message creation or sending fails
 */
const sendDraftMessage = async ({
  message,
  accessToken,
  thread,
  subject,
  currentOverView
}) => {
  if (!message?.conversationId || !message?.id) {
    const error = new Error('Invalid message: missing conversationId or id');
    console.error('Invalid message object:', { message });
    BugTracker.notify(error);
    throw error;
  }

  // Send draft message to Microsoft Graph API
  const sendDraftToGraph = async (messageId: string) => {
    try {
      const response = await callMsGraphDetailed({
        accessToken,
        url: `https://graph.microsoft.com/v1.0/me/messages/${messageId}/send`,
        method: 'POST'
      });
      return response;
    } catch (error) {
      console.error('Error sending draft message:', error);
      BugTracker.notify(error);
      throw error;
    }
  };

  // Update conversation data in Firebase
  const updateFirebaseConversation = async () => {
    const dealRef = firebase.firestore().collection('deal');

    await firebase.firestore().runTransaction(async (transaction) => {
      const dealDocRef = dealRef.doc(
        currentOverView.ProcessInstanceId.toString()
      );
      const dealDoc = await transaction.get(dealDocRef);

      // Initialize or get existing conversation data
      let ruleConversations = dealDoc.data()?.ruleConversations || {};
      const memberKey = thread.members[1];

      if (!ruleConversations[memberKey]) {
        ruleConversations[memberKey] = {};
      }

      // Compare and update conversation IDs
      const existingThreadTypeConversationId =
        ruleConversations[memberKey][thread.type];

      const newConversationId = message.conversationId;

      // Make sure this only ever runs in development
      if (
        process.env.NODE_ENV === 'development' &&
        existingThreadTypeConversationId &&
        existingThreadTypeConversationId !== newConversationId
      ) {
        console.log('Current Firebase State:', {
          memberKey,
          threadType: thread.type,
          existingData: ruleConversations[memberKey],
          newConversationId: message.conversationId
        });
      }

      // Update conversation mappings
      ruleConversations[memberKey] = {
        ...ruleConversations[memberKey],
        conversationId: newConversationId,
        [thread.type]: newConversationId
      };

      // Set or update the document
      if (!dealDoc.exists) {
        transaction.set(dealDocRef, { ruleConversations });
      } else {
        transaction.update(dealDocRef, { ruleConversations });
      }
    });
  };

  try {
    // Send the draft message
    const sentMail = await sendDraftToGraph(message.id);
    const success = sentMail?.status === 202;

    if (!success) {
      throw new Error('Failed to send draft email');
    }

    // Update Firebase and log analytics
    await updateFirebaseConversation();

    gtag.event({
      feature: 'Message Hub',
      action: 'Microsoft 365 Send',
      message: `Action ${subject}`
    });

    return sentMail;
  } catch (error) {
    console.error('Error in sendDraftMessage:', error);
    BugTracker.notify(error);
    throw error;
  }
};
