import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  FieldDefinitionDict,
  FieldDefinition,
  ObjectDefinition,
  CompleteProcessStepDefinition,
  CompleteObjectInstance,
  FieldInstance
} from 'types/interfaces';
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  useTheme,
  Tooltip,
  Checkbox,
  Button,
  CircularProgress,
  Fade,
  Zoom,
  Box,
  Paper
} from '@material-ui/core';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import InlineTextField from 'components/Fields/InlineTextField';
import Typography from '@material-ui/core/Typography';
import FieldStatusIcon from '../SingleObject/components/FieldStatusIcon';
import { Grid, GridSize } from '@material-ui/core';
import { FieldDefinitionTitle } from '../components/FieldDefinitionTitle';
import { useObject } from 'hooks/useObject';
import { getDealData } from 'redux/actions/processes/getDealData';
import DeleteObjectButton from '../components/DeleteObjectButton';
import { getLayoutSpacing } from '../helper';
import { HideShowFields } from '../../../../functions/hideshowFields';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { XpansionIsolated } from 'common/Xpansion';
import { useTypedSelector } from 'redux/reducers';
import { objectType } from '../Forms/FormContent';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  selectObjectInstance,
  updateObject
} from 'redux/actions/objectUpdater';
import { useProcess } from 'hooks';
import { createNotification } from 'react-redux-notify';
import { successNotif, warningNotif } from 'components/Notifications';
import { useDispatch } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { BiHide, BiShow } from 'react-icons/bi';
import useDecline from 'hooks/useDeals/useDecline';
import { BugTracker } from 'Utils/Bugtracker';
import useRepeatableObject, {
  findFieldInstance,
  LENDER_FIELD_DEFINITION,
  LENDER_PROPOSAL_DECISION
} from 'hooks/useDeals/useRepeatableObject';
import firebase from 'firebase';
import { useDealSummary } from 'hooks/useDealSummary';
import { globalIds } from 'helpers/globalIdConfig';
import DetailsDialog from '../helper/DetailsDialog';
import QuickUserFromId from 'components/User/QuickUserFromId';
import ObjectSaving, {
  ICustomizeComment,
  IProposalComments
} from '../SingleObject/ObjectSaving';
import {
  getRelationship,
  getUsersForProcess
} from 'redux/actions/GraphQlActions';
import {
  IGetRelationshipsRelatedToAssigned,
  IUserForProcess
} from 'redux/actions/GraphQlActions/interface';
import { StepperContext } from 'components/Stepper/context';
import isPreviewMode from '../helper/previewMode';
import {
  ELenderDecision,
  ELenderStatus,
  IProposal,
  IProposalStructure
} from 'components/Stepper/components/LenderAPI/interface';
import LenderAPI from 'components/Stepper/components/LenderAPI';
import { theme } from 'theme';
import {
  getProposalStructureById,
  proposalConfig
} from 'components/Stepper/components/LenderAPI/configuration/proposalConfig';
import { getFieldInstances, hasFields } from 'Utils/FieldInstanceChecker';
import { useLenderAPIStyles } from 'components/Stepper/components/LenderAPI/styles';
import { Assignment } from '@material-ui/icons';
import { convertProposalsList } from 'components/Stepper/components/LenderAPI/functions';
import uuid from 'react-uuid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    gridContainer: {
      display: 'flex'
    },
    leverCol: {
      width: '50%'
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '50%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      width: '100%'
    }
  })
);

interface IProps {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  stepdefdict: CompleteProcessStepDefinition;
  setObjectData: Dispatch<SetStateAction<unknown[]>>;
  setCurrentObjectType: Dispatch<SetStateAction<objectType>>;
  currentObjectType: objectType;
  display: 'clientView' | 'systemView';
}

const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
};
const RepeatDynamicObject = (props: IProps) => {
  const { isDealClosed, isDealTransferred, ReqCmp, proposalsList } =
    useContext(StepperContext);
  const classes = useLenderAPIStyles();

  const { currentDeal, currentStepId, entityType, currentOverView } =
    useTypedSelector((s) => s.process);
  const UserInstance = useTypedSelector((s) => s.user.user);
  const { landingpage } = useTypedSelector((s) => s.utility);

  const [lenderStructures, setLenderStructures] = useState<
    Record<number, IProposalStructure>
  >({});

  const [proposals, setProposals] = useState<
    { ObjectInstanceId: number; proposal: IProposal }[]
  >([]);

  const [CompleteObjectInstanceList, setCompleteObjectInstanceList] =
    React.useState<CompleteObjectInstance[]>([]);

  const [firestoreFieldInstances, setFirestoreFieldInstances] = useState<
    ICustomizeComment[]
  >([]);

  const { FieldDefinitionList, ObjectDefinition, stepdefdict } = props;
  const leverFieldDefinitions: FieldDefinition[] =
    props.FieldDefinitionList.filter(
      (el) => el.FieldType === 'Drop-downHideShowFields'
    );

  const { ProcessInstance } = currentDeal;
  const ProcessInstanceId = ProcessInstance?.Id;
  const { deleteObject, duplicateObject, publishObject } = useObject({
    ObjectDefinition,
    UserInstance,
    ProcessInstanceId,
    Fields: FieldDefinitionList,
    ProcessStepSelectionType:
      stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
  });

  const deleteObjectAndRefresh: (Id: number) => Promise<any> = async (Id) => {
    const deleted = await deleteObject(Id);
    return deleted && refreshDealData();
  };

  const refreshDealData = async () => {
    const ProcessDefinitionId = currentDeal.ProcessInstance.ProcessDefinitionId;
    await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId: currentStepId,
      ProcessDefinitionId
    });
  };

  const readOnly: boolean = ReadOnlyRedflag({
    ObjectDefinition: props.ObjectDefinition
  });

  const canAddMore =
    props.ObjectDefinition.ObjectRepeat === 0
      ? true
      : CompleteObjectInstanceList.length < props.ObjectDefinition.ObjectRepeat;

  const { modifiedObjectInstances, readOnlyFields, brokerAccepted } =
    useRepeatableObject({
      CompleteObjectInstanceList,
      isInlineField: false
    });

  const handleLenderAPI = async (
    CompleteObjectInstance: CompleteObjectInstance
  ) => {
    const getFieldInstance = getFieldInstances(CompleteObjectInstance);

    const getLenderId = getFieldInstance.find((FieldInstance: FieldInstance) =>
      globalIds.customer.lenderProposal.LenderProposalFields.lender.includes(
        FieldInstance.FieldDefinitionId
      )
    );

    const numberLenderId = Number(getLenderId?.FieldValue);
    const lenderStructure = lenderStructures[numberLenderId || 0];

    if (lenderStructure) {
      const uniqueId = uuid();
      const newProposal = {
        ObjectInstanceId: CompleteObjectInstance.ObjectInstance.Id,
        proposal: {
          id: CompleteObjectInstance?.ObjectInstance.Id.toString() || '0',
          uniqueId,
          lenderId: numberLenderId,
          decision: ELenderDecision.PENDING,
          requestedStatus: ELenderStatus.DRAFT,
          files: null,
          CompleteObjectInstance,
          ...lenderStructure
        } as IProposal
      };

      setProposals((prevProposals) => [...prevProposals, newProposal]);
    }
  };

  useEffect(() => {
    const fetchLenderStructures = async () => {
      const lenderIds = Object.keys(proposalConfig).map(Number);

      try {
        const configRef = firebase
          .firestore()
          .collection('globalSetting')
          .doc('lenderConfig');

        const structures: Record<number, IProposalStructure> = {};
        for (const lenderId of lenderIds) {
          const structure = await getProposalStructureById(lenderId, configRef);
          if (structure) {
            structures[lenderId] = structure;
          }
        }

        setLenderStructures(structures);
      } catch (error) {
        console.error('Error fetching lender structures:', error);
      }
    };

    fetchLenderStructures();
  }, []);

  useEffect(() => {
    const fetchProposals = async () => {
      const LenderOD =
        globalIds.customer.lenderProposal.LenderProposalObjectDefinition;

      if (LenderOD.includes(props.ObjectDefinition.Id)) {
        try {
          const convertedProposals = await convertProposalsList({
            proposalsList,
            currentDeal
          });
          setProposals(convertedProposals);
        } catch (error) {
          console.error('Error fetching proposals:', error);
          setProposals([]);
        }
      }
    };

    fetchProposals();
  }, [proposalsList]);

  React.useEffect(() => {
    const NewCompleteObjectInstanceList: CompleteObjectInstance[] =
      Object.values(currentDeal.CompleteObjectInstanceDict).filter(
        (CompleteObjectInstance: CompleteObjectInstance): boolean =>
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
          props.ObjectDefinition.Id
      );

    if (NewCompleteObjectInstanceList)
      setCompleteObjectInstanceList(NewCompleteObjectInstanceList);
  }, [
    ObjectDefinition,
    currentDeal.CompleteObjectInstanceDict,
    setCompleteObjectInstanceList
  ]);

  const IdsToDisplayReadOnly = [2862];
  const IdsToRemoveText = [3304, 3456, 2853, 3724, 3688, 3659];
  const IdsToMandatoryStep = [3630, 3679];

  const isReadOnlyTextContent = IdsToDisplayReadOnly.includes(
    ObjectDefinition.Id
  );
  const isRemoveTextContent = IdsToRemoveText.includes(ObjectDefinition.Id);
  const isMandatoryTextContent = IdsToMandatoryStep.includes(
    ObjectDefinition.Id
  );

  //* isPreview will only work if the magic link has been injected with preview.
  const isPreview = isPreviewMode(window.location.href, landingpage);
  const selectedObjectInstanceProposal = modifiedObjectInstances().find(
    (CompleteObjectInstance: CompleteObjectInstance) =>
      CompleteObjectInstance.ObjectInstance.Selected
  );

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <div>
      {CompleteObjectInstanceList.length === 0 ? (
        <Alert severity="warning">
          {dealCondition ? (
            <>
              <AlertTitle>No {props.ObjectDefinition.Title} Found</AlertTitle>
              <Typography variant="h6" color="textSecondary">
                Please note that this deal is $
                {isDealTransferred ? 'transferred' : 'closed'}. You cannot add{' '}
                {props.ObjectDefinition.Title}.
              </Typography>
            </>
          ) : isPreview ? (
            <>
              <AlertTitle>No {props.ObjectDefinition.Title} Found</AlertTitle>
              <Typography variant="h6" color="textSecondary">
                Please note that this Landing Page is in Preview mode. You
                cannot add {props.ObjectDefinition.Title} at this time.
              </Typography>
            </>
          ) : !isReadOnlyTextContent ? (
            <>
              {isRemoveTextContent ? (
                <Typography style={{ fontWeight: 'bold' }}>
                  No {props.ObjectDefinition.Title} has been added
                </Typography>
              ) : isMandatoryTextContent ? (
                <>
                  <AlertTitle>
                    No {props.ObjectDefinition.Title} has been added
                  </AlertTitle>
                  <Typography variant="h6" color="textSecondary">
                    Please complete the {props.ObjectDefinition.Title} section
                    as this is a Mandatory Step.
                  </Typography>
                </>
              ) : (
                <>
                  <AlertTitle>
                    No {props.ObjectDefinition.Title} has been added
                  </AlertTitle>
                  <Typography variant="h6" color="textSecondary">
                    Please complete the {props.ObjectDefinition.Title} to
                    continue with the process.
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Typography style={{ fontWeight: 'bold' }}>
              No {props.ObjectDefinition.Title}
            </Typography>
          )}
        </Alert>
      ) : (
        <div>
          {modifiedObjectInstances()
            .filter(
              (CompleteObjectInstance) =>
                !(
                  landingpage &&
                  !CompleteObjectInstance.ObjectInstance.IsPublished
                )
            )
            .map(
              (
                CompleteObjectInstance: CompleteObjectInstance,
                localIndex: number
              ) => {
                const findBrokerForLP = brokerAccepted?.find((broker) => {
                  return (
                    broker.completeObjectInstanceId ===
                    CompleteObjectInstance.ObjectInstance.Id
                  );
                });

                // Here we filter by the CompleteObjectInstanceId and find all the proposals that are linked
                const filterProposalsById = proposals.filter(
                  (proposal) =>
                    proposal.ObjectInstanceId ===
                    CompleteObjectInstance.ObjectInstance.Id
                );

                const LenderOD =
                  globalIds.customer.lenderProposal
                    .LenderProposalObjectDefinition;

                let lenderInformation: {
                  getLenderId: FieldInstance | undefined;
                  fields: boolean;
                  canShowLenderAPI: boolean | undefined;
                } = {
                  getLenderId: undefined,
                  fields: false,
                  canShowLenderAPI: false
                };

                if (LenderOD.includes(props.ObjectDefinition.Id)) {
                  const getAllRequiredFieldsById: FieldInstance[] | undefined =
                    ReqCmp &&
                    ReqCmp._reqfieldsnum.filter(
                      (fieldInstance: FieldInstance) =>
                        fieldInstance?.ObjectDefinitionId?.toString() ===
                          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId?.toString() &&
                        fieldInstance?.ObjectInstanceId.toString() ===
                          CompleteObjectInstance.ObjectInstance.Id.toString()
                    );

                  lenderInformation.fields = hasFields(
                    getAllRequiredFieldsById
                  );
                  const getFieldInstance = getFieldInstances(
                    CompleteObjectInstance
                  );

                  lenderInformation.getLenderId = getFieldInstance.find(
                    (FieldInstance: FieldInstance) =>
                      globalIds.customer.lenderProposal.LenderProposalFields.lender.includes(
                        FieldInstance.FieldDefinitionId
                      )
                  );

                  const numberLenderId = parseInt(
                    lenderInformation.getLenderId?.FieldValue ?? '0'
                  );
                  const lenderStructure = lenderStructures[numberLenderId];

                  lenderInformation.canShowLenderAPI =
                    !!lenderStructure &&
                    lenderStructure.allowedDealFlow.includes(
                      currentOverView.ProcessDefinitionId
                    ) &&
                    (!lenderStructure.allowedEntities ||
                      lenderStructure.allowedEntities.includes(entityType));
                }

                return (
                  <>
                    <ObjectInstantiation
                      key={CompleteObjectInstance.ObjectInstance.Id}
                      CompleteObjectInstance={CompleteObjectInstance}
                      leverFieldDefinitions={leverFieldDefinitions}
                      idx={CompleteObjectInstance.ObjectInstance.Id}
                      props={props}
                      findBrokerForLP={findBrokerForLP}
                      readOnlyFields={readOnlyFields}
                      readOnly={readOnly}
                      deleteObjectAndRefresh={deleteObjectAndRefresh}
                      localIndex={localIndex}
                      duplicateObject={duplicateObject}
                      publishObject={publishObject}
                      CompleteObjectInstanceList={CompleteObjectInstanceList}
                      setFirestoreFieldInstances={setFirestoreFieldInstances}
                      firestoreFieldInstances={firestoreFieldInstances}
                    />

                    {lenderInformation.fields &&
                      lenderInformation.canShowLenderAPI && (
                        <Paper className={classes.proposal}>
                          <Alert
                            severity="info"
                            style={{
                              borderTopRightRadius: 0,
                              borderTopLeftRadius: 0
                            }}>
                            <AlertTitle>
                              {`All Required Fields have been Filled In.`}
                            </AlertTitle>
                            <Typography variant="h6" color="textSecondary">
                              {`Select “Create Proposal Form" and ensure all
                              Required Fields have been Completed.`}
                            </Typography>
                          </Alert>

                          <Fade in timeout={300}>
                            <Box className={classes.displayMode}>
                              <Zoom in timeout={300}>
                                <Button
                                  variant="outlined"
                                  style={{
                                    color: theme.palette.success.main
                                  }}
                                  className={classes.assignButton}
                                  onClick={async () =>
                                    handleLenderAPI(CompleteObjectInstance)
                                  }>
                                  <Assignment
                                    className={classes.icon}
                                    fontSize="small"
                                  />
                                  Create Proposal Form
                                </Button>
                              </Zoom>
                            </Box>
                          </Fade>
                          <div style={{ paddingBottom: theme.spacing(0.5) }} />
                          <LenderAPI
                            proposals={filterProposalsById}
                            setProposals={setProposals}
                            selectedLenderId={parseInt(
                              lenderInformation.getLenderId?.FieldValue ?? '0'
                            )}
                            ObjectInstanceList={{
                              targetObjectInstance:
                                CompleteObjectInstance.ObjectInstance,
                              selectedObjectInstance:
                                selectedObjectInstanceProposal?.ObjectInstance ||
                                null
                            }}
                          />
                        </Paper>
                      )}
                  </>
                );
              }
            )}
        </div>
      )}

      <br />
      {!dealCondition && !isPreview && (
        <>
          {canAddMore && !readOnly && (
            <AddFieldAndObjectButton
              Fields={Object.values(props.FieldDefinitionDict)}
              ObjectDefinition={props.ObjectDefinition}
              ProcessInstance={currentDeal.ProcessInstance}
              ProcessInstanceId={currentDeal.ProcessInstance.Id}
              ProcessStepSelectionType={
                props.stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
              }
              UserInstance={UserInstance}
              label={`New ${props.ObjectDefinition.Title}`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RepeatDynamicObject;

const ObjectInstantiation = ({
  CompleteObjectInstance,
  leverFieldDefinitions,
  idx,
  props,
  readOnly,
  deleteObjectAndRefresh,
  localIndex,
  readOnlyFields,
  findBrokerForLP,
  duplicateObject,
  publishObject,
  CompleteObjectInstanceList,
  setFirestoreFieldInstances,
  firestoreFieldInstances
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  leverFieldDefinitions: FieldDefinition[];
  idx: number;
  props: IProps;
  readOnly: boolean;
  deleteObjectAndRefresh: (Id: number) => Promise<any>;
  localIndex: number;
  findBrokerForLP:
    | {
        completeObjectInstanceId: number;
        completedByUser: string;
        status: string;
      }
    | undefined;
  readOnlyFields: number[];
  duplicateObject: (duplicateObject: any) => void;
  publishObject: (object: any) => Promise<void>;
  CompleteObjectInstanceList: CompleteObjectInstance[];
  setFirestoreFieldInstances: Dispatch<SetStateAction<ICustomizeComment[]>>;
  firestoreFieldInstances: ICustomizeComment[];
}) => {
  const forceUpdate = useForceUpdate();
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { syncProcessInstance } = useDealSummary();

  const { token, currentDeal, currentStep, currentProcess, landingpage } =
    useProcess();
  const { value } = useTypedSelector((s) => s.formReducer);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const { darkMode } = useTypedSelector((s) => s.fb.proxy_settings);
  const deal = useTypedSelector((s) => s.dealSummary.deal);
  const UserInstance = useTypedSelector((s) => s.user.user);
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

  const { isDeclined } = useDecline({
    FieldDefinitionDict: props.FieldDefinitionDict,
    object: CompleteObjectInstance
  });
  const [open, setOpen] = React.useState<{
    open: boolean;
    FieldDefinition: FieldDefinition;
  }>({
    open: false,
    FieldDefinition: {} as FieldDefinition
  });

  const [isLenderProposal, setIsLenderProposal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);

  let md: GridSize = getLayoutSpacing(props.ObjectDefinition.ObjectDescription);
  let fieldsToShow: FieldDefinition[] = HideShowFields({
    FieldDefinitionList: props.FieldDefinitionList,
    CompleteObjectInstance
  }).sort(
    (a: FieldDefinition, b: FieldDefinition) => a.ItemOrder - b.ItemOrder
  );

  const handleObjectSaving = async ({
    FieldDefinition,
    ObjectDefinition,
    comment
  }: {
    FieldDefinition: FieldDefinition;
    ObjectDefinition: ObjectDefinition;
    comment: string;
  }) => {
    if (UserInstance.Id !== currentDeal.ProcessInstance.UserInstanceId) return;
    if (ObjectDefinition && comment !== '') {
      const ProposalComments = globalIds.objectDefinitionIds.proposalComments;
      if (ProposalComments.includes(ObjectDefinition.Id)) {
        const parentName: string = FieldDefinition.Title;
        const parentSection: IProposalComments = {
          comment,
          fieldDefinitionId: FieldDefinition.Id
        };

        const reduxCustomer = Object.keys(
          currentStep.UserInstanceDictForCurrentStep
        );

        const customerId = deal?.customers?.selected
          ? deal?.customers?.selected[0]?.Id
          : reduxCustomer[0] ?? undefined;

        if (!customerId) return;
        await ObjectSaving({
          parentName,
          parentSection,
          user: UserInstance,
          dealTitle: currentProcess.ProcessDefinition.Title,
          type: FieldDefinition.FieldType,
          customerId: customerId.toString()
        });
      }
    }
  };

  const findSelectedLenderProposal = () => {
    const lenderField =
      globalIds.customer.lenderProposal.LenderProposalFields.lender;

    const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
      (FieldInstance: FieldInstance) =>
        lenderField.includes(FieldInstance.FieldDefinitionId)
    );

    if (FieldInstance) return FieldInstance;
  };

  const updateLenderProposalState = (selectedLenderProposal) => {
    const lenderProposalGlobalId =
      globalIds.customer.lenderProposal.LenderProposalObjectDefinition;
    const isLenderProposalCheck = lenderProposalGlobalId.includes(
      CompleteObjectInstance.ObjectInstance.ObjectDefinitionId
    );

    if (isLenderProposalCheck) {
      if (selectedLenderProposal) {
        setIsLenderProposal(true);
        return;
      }
    }
    setIsLenderProposal(false);
  };

  const handleRefresh = () => {
    const selectedLenderProposal = findSelectedLenderProposal();
    if (selectedLenderProposal) {
      setLoading(true);
      updateLenderProposalState(selectedLenderProposal);
      setLoading(false);
    } else {
      forceUpdate();
    }
  };

  const findLenderField = findSelectedLenderProposal();
  useEffect(() => {
    if (findLenderField) {
      updateLenderProposalState(findLenderField);
    }
  }, [findLenderField]);

  const detectRequiredFields = () => {
    const requiredFields = fieldsToShow.filter(
      (FieldDefinition: FieldDefinition) => FieldDefinition.FieldRequired
    );

    const requiredFieldsWithInstances = requiredFields.reduce(
      (acc, FieldDefinition) => {
        const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
          (FieldInstance: FieldInstance) =>
            FieldInstance.FieldDefinitionId === FieldDefinition.Id &&
            FieldInstance.FieldValue === ''
        );

        if (FieldInstance) {
          acc.push({ FieldDefinition, FieldInstance });
        }

        return acc;
      },
      [] as Array<{
        FieldDefinition: FieldDefinition;
        FieldInstance: FieldInstance;
      }>
    );

    return requiredFieldsWithInstances;
  };

  const handleSelectObject = async () => {
    setSelectLoading(true);
    const UserDefinitionId = 21;
    const requiredFields: FieldDefinition[] = fieldsToShow.filter(
      (FieldDefinition) => FieldDefinition.FieldRequired
    );

    const numberOfRequiredFieldToDo: FieldDefinition[] = requiredFields.filter(
      (requiredField: FieldDefinition) =>
        FieldInstanceList.find(
          (FieldInstance: FieldInstance) =>
            FieldInstance.FieldDefinitionId === requiredField.Id &&
            FieldInstance.FieldValue === ''
        )
    );

    if (numberOfRequiredFieldToDo.length === 0) {
      try {
        const updatedObject = await selectObjectInstance({
          token,
          data: CompleteObjectInstance,
          props: { ProcessInstanceId: currentDeal.ProcessInstance.Id },
          action: 'SELECT'
        });

        if (updatedObject && updatedObject.length > 0) {
          const response = (await getUsersForProcess({
            action: 'SELECTEDUSERFORPROCESS',
            ProcessInstanceId: currentDeal.ProcessInstance.Id,
            UserDefinitionId: UserDefinitionId,
            ProcessDefinitionId:
              currentDeal.ProcessInstance.ProcessDefinitionId,
            baseUrl
          })) as IUserForProcess[] | undefined;

          if (response) {
            let options: { label: string; value: number }[] = [];
            response.forEach((UsersForProcess: IUserForProcess) => {
              options.push({
                label: UsersForProcess.Title,
                value: UsersForProcess.Id
              });
            });
          }
        }
      } catch (e) {
        console.error('Error in handleSelectObject:', e);
        BugTracker.notify(e);
      }

      const ProposalLenders =
        globalIds.customer.lenderProposal.LenderProposalObjectDefinition;
      if (
        ProposalLenders.includes(
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId
        )
      ) {
        // Lender Update
        // When Lender has been selected remove Payout Contract & Proposal Contract
        // Get Parties & Contracts CompleteObjectInstance

        const PartiesContactsODI = 3625;
        const partiesContacts: CompleteObjectInstance | undefined =
          Object.values(currentDeal.CompleteObjectInstanceDict).find(
            (CompleteObjectInstance: CompleteObjectInstance) => {
              return (
                CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
                PartiesContactsODI
              );
            }
          );

        if (partiesContacts) {
          await syncProcessInstance();
          const partiesFieldInstanceList = getFieldInstances(partiesContacts);

          const findProposal: FieldInstance | undefined = findFieldInstance({
            CompleteObjectInstance: partiesContacts,
            fieldDefinitionId: 23116
          });

          let updatedFieldInstanceList = partiesFieldInstanceList.map(
            (FieldInstance: FieldInstance) => {
              if (
                findProposal &&
                findProposal.FieldDefinitionId &&
                FieldInstance.FieldDefinitionId ===
                  findProposal.FieldDefinitionId
              ) {
                return {
                  ...FieldInstance,
                  FieldValue: ''
                };
              }
              return FieldInstance;
            }
          );

          const findPayout: FieldInstance | undefined = findFieldInstance({
            CompleteObjectInstance: partiesContacts,
            fieldDefinitionId: 23109
          });

          updatedFieldInstanceList = updatedFieldInstanceList.map(
            (FieldInstance: FieldInstance) => {
              if (
                findPayout &&
                findPayout.FieldDefinitionId &&
                FieldInstance.FieldDefinitionId === findPayout.FieldDefinitionId
              ) {
                return {
                  ...FieldInstance,
                  FieldValue: ''
                };
              }
              return FieldInstance;
            }
          );

          const newCompleteObjectInstance: CompleteObjectInstance = {
            ObjectInstance: partiesContacts.ObjectInstance,
            FieldInstanceList: updatedFieldInstanceList
          };

          await updateObject({
            token,
            data: newCompleteObjectInstance,
            props: { ProcessInstanceId: currentDeal.ProcessInstance.Id }
          });

          // EDGE CASE: Get Relationships for this updated User:
          const config: IGetRelationshipsRelatedToAssigned = {
            action: 'RELATEDTOASSIGNED',
            ProcessInstanceId: currentDeal.ProcessInstance.Id,
            AssignedUserDefinitionId: 21,
            RelatedUserDefinitionId: 528,
            fetchPolicy: true,
            baseUrl
          };

          await getRelationship(config);
        } else {
          setSelectLoading(false);
          return dispatch(
            createNotification(
              warningNotif(
                'Failed To Updated Lender On Parties & Contacts Page, Please Select a Primary Contact First'
              )
            )
          );
        }
      }

      dispatch(
        createNotification(
          successNotif(`${CompleteObjectInstance.ObjectInstance.Title} Updated`)
        )
      );
    } else {
      dispatch(
        createNotification(
          warningNotif(
            'This object cannot be selected because all its required fields have not been filled in'
          )
        )
      );
    }

    setSelectLoading(false);
  };

  useEffect(() => {
    const fetchFieldInstances = async () => {
      const userDocRef = firebase
        .firestore()
        .collection('userAccount')
        .doc(UserInstance.Id.toString());

      const reduxCustomer = Object.keys(
        currentStep.UserInstanceDictForCurrentStep
      );

      const customerId = deal?.customers?.selected
        ? deal?.customers?.selected[0]?.Id
        : reduxCustomer[0] ?? undefined;

      if (!customerId) return;
      const uniqueId = `${customerId}-${currentProcess.ProcessDefinition.Title}`;
      const doc = await userDocRef.get();
      const data = doc.data();
      const fetchedFieldInstances = data
        ? data['customize_Comments']?.[uniqueId]
        : undefined;

      setFirestoreFieldInstances(fetchedFieldInstances);
    };

    if (globalIds.objectDefinitionIds.proposalComments.includes(value)) {
      fetchFieldInstances();
    }
  }, [CompleteObjectInstance]);

  const QuoteObject = 3565;
  const LenderCommissionInvoice = 3486;
  const IdDisplayObjects = [QuoteObject, LenderCommissionInvoice];

  let hasLenderDecision: FieldInstance | boolean = false;
  if (FieldInstanceList) {
    const matchingFieldInstance = FieldInstanceList.find(
      (FieldInstance: FieldInstance) =>
        readOnlyFields.includes(FieldInstance.Id)
    );

    if (matchingFieldInstance) {
      hasLenderDecision = matchingFieldInstance;
    }
  }

  // IQ stands for "IndicativeQuote"
  let isIQAccepted: boolean | null = null;
  if (FieldInstanceList) {
    const IQDeclineId = [23525, 23592];
    const IQFieldInstance = FieldInstanceList.find(
      (FieldInstance: FieldInstance) => {
        return IQDeclineId.includes(FieldInstance.FieldDefinitionId);
      }
    );

    if (IQFieldInstance) {
      isIQAccepted = IQFieldInstance.FieldValue === 'false';
    }
  }

  const AcceptedLenderDecision =
    typeof hasLenderDecision !== 'boolean' &&
    hasLenderDecision?.FieldValue === 'Accept';
  const DeclinedLenderDecision =
    typeof hasLenderDecision !== 'boolean' &&
    hasLenderDecision?.FieldValue === 'Decline';

  const isDirector =
    CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
    globalIds.customer.directors.DirectorsObjectDefinition;

  const handleLenderReadOnly = (FieldInstance: FieldInstance): boolean => {
    if (isDeclined || DeclinedLenderDecision || AcceptedLenderDecision) {
      const findLender = LENDER_FIELD_DEFINITION.includes(
        FieldInstance.FieldDefinitionId
      );

      if (findLender) return true;
      else return false;
    }

    return false;
  };

  //* isPreview will only work if the magic link has been injected with preview.
  const isPreview = isPreviewMode(window.location.href, landingpage);
  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <XpansionIsolated
      key={idx}
      expanded
      style={{
        backgroundColor:
          AcceptedLenderDecision || isIQAccepted
            ? theme.palette.success.light
            : isDeclined || DeclinedLenderDecision
            ? theme.palette.error.light
            : '',
        border:
          AcceptedLenderDecision || isIQAccepted
            ? `2px ${theme.palette.success.main} solid`
            : isDeclined || DeclinedLenderDecision
            ? `2px ${theme.palette.error.main} solid`
            : ''
      }}
      summary={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}>
          {IdDisplayObjects.includes(props.ObjectDefinition.Id) ? (
            <div
              style={{
                fontWeight: 'bold',
                flexGrow: 1,
                color: isDeclined || DeclinedLenderDecision ? 'white' : ''
              }}>
              {props.ObjectDefinition.Title} (
              {CompleteObjectInstance.ObjectInstance.Id})
            </div>
          ) : (
            <div
              style={{
                fontWeight: 'bold',
                flexGrow: 1,
                color: isDeclined || DeclinedLenderDecision ? 'white' : ''
              }}>
              {props.ObjectDefinition.Title} : {localIndex + 1}
            </div>
          )}

          {/* If Lender & Proposal Then Show Chip Of Lender If Lender Exists */}
          {isLenderProposal && (
            <>
              {!loading && (
                <>
                  {findLenderField && findLenderField?.FieldValue !== '' && (
                    <div style={{ marginLeft: theme.spacing(5) }}>
                      <QuickUserFromId
                        UserInstanceId={findLenderField?.FieldValue.toString()}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <>
            {dealCondition || isPreview ? (
              <Alert severity="info">
                <Typography variant="h6">
                  {isPreview ? 'Preview Mode' : 'Read Only'}
                </Typography>
              </Alert>
            ) : (
              <>
                {props.display === 'systemView' && (
                  <Tooltip title={`Hide/Show`}>
                    <Checkbox
                      style={{
                        color:
                          isDeclined || DeclinedLenderDecision ? 'white' : ''
                      }}
                      icon={<BiHide />}
                      checkedIcon={<BiShow />}
                      checked={
                        CompleteObjectInstance.ObjectInstance.IsPublished
                      }
                      onChange={() => publishObject(CompleteObjectInstance)}
                    />
                  </Tooltip>
                )}

                {!isDeclined && !isDirector && (
                  <Tooltip title="Select Object">
                    <IconButton
                      data-cy="star-icon-btn"
                      onClick={async (e) => {
                        e.stopPropagation();
                        handleSelectObject();
                      }}>
                      {selectLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          {CompleteObjectInstance.ObjectInstance.Selected ? (
                            <StarIcon
                              style={{
                                color: theme.palette.success.main
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                color: DeclinedLenderDecision ? 'white' : ''
                              }}
                            />
                          )}
                        </>
                      )}
                    </IconButton>
                  </Tooltip>
                )}

                {!isDeclined && !isIQAccepted && (
                  <Tooltip
                    title={`Duplicate ${CompleteObjectInstance.ObjectInstance.Title}`}>
                    <IconButton
                      style={{
                        color: DeclinedLenderDecision ? 'white' : ''
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        duplicateObject(CompleteObjectInstance);
                      }}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {!readOnly && (
                  <DeleteObjectButton
                    isDeclined={isDeclined || DeclinedLenderDecision}
                    object={CompleteObjectInstance}
                    deleteObject={deleteObjectAndRefresh}
                    title={CompleteObjectInstance.ObjectInstance.Title}
                  />
                )}
              </>
            )}
          </>
        </div>
      }>
      <Grid container spacing={1}>
        {fieldsToShow.map((FieldDefinition: FieldDefinition) => {
          let firestoreInstance: ICustomizeComment | undefined;
          if (firestoreFieldInstances) {
            firestoreInstance = firestoreFieldInstances.find(
              (instance: ICustomizeComment) =>
                instance.parentSection.fieldDefinitionId === FieldDefinition.Id
            );
          }
          if (FieldDefinition.Id === 23126) return;

          const isReadOnly: boolean = ReadOnlyRedflag({
            ObjectDefinition: props.ObjectDefinition,
            FieldDefinition
          });

          const readOnlyField =
            FieldDefinition.FieldType.includes('Information');

          if (readOnlyField) {
            const hexColorMatch = FieldDefinition.FieldDescription.match(
              /hexColor-\[([^[\]]*)\]/
            );

            const borderColorMatch =
              FieldDefinition.FieldDescription.match(/border-\[([^[\]]*)\]/);

            const hexColor = hexColorMatch ? hexColorMatch[1] : '';
            const borderColor = borderColorMatch ? borderColorMatch[1] : '';

            const style = {
              backgroundColor: hexColor,
              ...(borderColor && { border: `2px solid ${borderColor}` })
            };

            return (
              <Grid item key={FieldDefinition.Id} xs={12}>
                <Alert severity="info" style={style} elevation={1}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {FieldDefinition.Title}
                  </Typography>
                </Alert>
              </Grid>
            );
          }

          const fieldDefinitionExists = FieldInstanceList.some(
            (FieldInstance: FieldInstance) =>
              FieldInstance.FieldDefinitionId === FieldDefinition.Id
          );

          if (!fieldDefinitionExists) {
            return (
              <Grid
                item
                key={FieldDefinition.Id}
                xs={12}
                md={md}
                className={classes.gridContainer}>
                <div className={classes.paper}>
                  <div style={{ display: 'flex' }}>
                    <FieldDefinitionTitle
                      title={FieldDefinition.Title}
                      height="h6"
                    />
                  </div>
                  <div>
                    {CompleteObjectInstance.ObjectInstance.IsPublished && (
                      <InlineTextField
                        readOnly={dealCondition || isPreview}
                        FieldDefinition={FieldDefinition}
                        FieldInstance={undefined}
                        ObjectDefinition={props.ObjectDefinition}
                        ObjectInstance={
                          CompleteObjectInstance &&
                          CompleteObjectInstance.ObjectInstance
                        }
                        ProcessInstance={currentDeal.ProcessInstance}
                        ProcessStepSelectionType={
                          props.stepdefdict.ProcessStepDefinition
                            .ProcessStepSelectionType
                        }
                        UserInstance={UserInstance}
                        fields={leverFieldDefinitions}
                        type={FieldDefinition.FieldType}
                        value={''}
                        parentsIsFormComponent
                        isRepeatable
                        refreshCalculationFields={forceUpdate}
                        CompleteObjectInstance={CompleteObjectInstance}
                        completeObjectInstances={CompleteObjectInstanceList}
                        handleObjectSaving={handleObjectSaving}
                        firestoreComment={firestoreInstance}
                      />
                    )}
                  </div>
                </div>
              </Grid>
            );
          }

          return (
            <Grid
              item
              key={FieldDefinition.Id}
              xs={12}
              md={md}
              className={classes.gridContainer}
              alignItems="stretch">
              {FieldInstanceList.map((FieldInstance: FieldInstance) => {
                const IndicativeDeclineId = [23525, 23592];
                const DeclinedChecker = IndicativeDeclineId.includes(
                  FieldInstance.FieldDefinitionId
                );

                if (DeclinedChecker) return <div />;
                if (FieldInstance.FieldDefinitionId === FieldDefinition.Id) {
                  const isLenderReadOnly = handleLenderReadOnly(FieldInstance);

                  let isFieldReadOnly = isReadOnly || isLenderReadOnly;
                  return (
                    <div
                      className={classes.paper}
                      key={FieldInstance.Id}
                      style={{
                        backgroundColor:
                          isDeclined || DeclinedLenderDecision
                            ? darkMode
                              ? theme.palette.secondary.dark
                              : 'white'
                            : '',
                        border:
                          AcceptedLenderDecision || isIQAccepted
                            ? `2px ${theme.palette.success.main} solid`
                            : isDeclined || DeclinedLenderDecision
                            ? `2px ${theme.palette.error.main} solid`
                            : ''
                      }}>
                      <div style={{ display: 'flex' }}>
                        <FieldStatusIcon
                          FieldDefinition={FieldDefinition}
                          FieldInstance={FieldInstance}
                          Required={FieldDefinition.FieldRequired}
                        />

                        <FieldDefinitionTitle
                          title={FieldDefinition.Title}
                          height="h6"
                        />
                      </div>

                      {LENDER_PROPOSAL_DECISION.includes(
                        FieldInstance.FieldDefinitionId
                      ) &&
                        findBrokerForLP && (
                          <Grid container spacing={1} direction="row">
                            <Grid
                              item
                              style={{ paddingTop: theme.spacing(1.5) }}>
                              <Typography>
                                {findBrokerForLP.status} By
                              </Typography>
                            </Grid>
                            <Grid item>
                              <QuickUserFromId
                                UserInstanceId={findBrokerForLP.completedByUser}
                              />
                            </Grid>
                          </Grid>
                        )}

                      <div>
                        <div style={{ visibility: 'hidden' }}>
                          <FieldStatusIcon
                            FieldDefinition={FieldDefinition}
                            FieldInstance={FieldInstance}
                            Required={FieldDefinition.FieldRequired}
                          />
                        </div>
                        {CompleteObjectInstance.ObjectInstance.IsPublished && (
                          <InlineTextField
                            readOnly={
                              isFieldReadOnly || dealCondition || isPreview
                            }
                            FieldDefinition={FieldDefinition}
                            FieldInstance={FieldInstance}
                            ObjectDefinition={props.ObjectDefinition}
                            ObjectInstance={
                              CompleteObjectInstance &&
                              CompleteObjectInstance.ObjectInstance
                            }
                            ProcessInstance={currentDeal.ProcessInstance}
                            ProcessStepSelectionType={
                              props.stepdefdict.ProcessStepDefinition
                                .ProcessStepSelectionType
                            }
                            UserInstance={UserInstance}
                            fields={leverFieldDefinitions}
                            type={FieldDefinition.FieldType}
                            value={FieldInstance?.FieldValue}
                            parentsIsFormComponent
                            isRepeatable
                            refreshCalculationFields={handleRefresh}
                            detectRequired={detectRequiredFields}
                            completeObjectInstances={CompleteObjectInstanceList}
                            CompleteObjectInstance={CompleteObjectInstance}
                            handleObjectSaving={handleObjectSaving}
                            firestoreComment={firestoreInstance}
                          />
                        )}
                      </div>
                      {FieldDefinition.FieldDescription === 'Viewer' &&
                        FieldInstance.FieldValue !== '' && (
                          <div style={{ paddingTop: theme.spacing(1) }}>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                setOpen({
                                  ...open,
                                  open: true,
                                  FieldDefinition: FieldDefinition
                                })
                              }>
                              View Details
                            </Button>
                          </div>
                        )}
                    </div>
                  );
                }
                return null;
              })}
            </Grid>
          );
        })}
      </Grid>
      <DetailsDialog
        setOpen={setOpen}
        open={open}
        CompleteObjectInstance={CompleteObjectInstance}
      />
    </XpansionIsolated>
  );
};
