import { useEffect, useState } from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { GetAssignedUsersByProcess } from 'redux/database';
import { AssignedUser, FbFileRef } from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import { EDocumentResults } from 'graphql/FileStorageAPI/interface';

/**
 * AuthorizedViewers component displays a list of users who have permission to view a document.
 * It filters the list to only show users who are assigned to the current process.
 *
 * @remarks
 * The component performs the following operations:
 * 1. Fetches the list of assigned users for the current process
 * 2. Filters the file's authorized users to only show those who are assigned to the process
 * 3. Displays each valid user with a checkbox to toggle their permissions
 * 4. Shows document status through the Alert component's severity
 *
 * @lifecycle
 * - On mount: Fetches assigned users
 * - On ProcessInstanceId change: Re-fetches assigned users
 *
 * @returns {JSX.Element | null} Returns null if there are no valid users to display
 */
const AuthorizedViewers = ({
  file,
  handleChange,
  ProcessInstanceId
}: {
  file: FbFileRef;
  handleChange: (params: {
    key: string;
    user: string | AssignedUser;
    checked: boolean;
    isGrouped: boolean;
  }) => Promise<void>;
  ProcessInstanceId: number;
}) => {
  const [assignedUsers, setAssignedUsers] = useState<AssignedUser[]>([]);

  useEffect(() => {
    const fetchAssignedUsers = async () => {
      const res = await GetAssignedUsersByProcess({ Id: ProcessInstanceId });
      if (res.data) {
        setAssignedUsers(res.data as AssignedUser[]);
      }
    };

    fetchAssignedUsers();
  }, [ProcessInstanceId]);

  const assignedUserIds = new Set(
    assignedUsers.map((user) => user.UserInstanceId.toString())
  );

  const validUsers = file.users.filter((userId) =>
    assignedUserIds.has(userId.toString())
  );

  if (!validUsers.length) return null;
  return (
    <div style={{ padding: theme.spacing(1) }}>
      <Alert
        severity={
          file?.documentStatus
            ? file.documentStatus.documentStatus === EDocumentResults.NONE ||
              file.documentStatus.documentStatus === EDocumentResults.ACCEPTABLE
              ? 'success'
              : 'error'
            : 'success'
        }>
        <Typography style={{ fontWeight: 'bold' }}>
          List of Authorized Viewers for This Document:
        </Typography>

        {validUsers.map((userId) => {
          const checked = file.users.includes(userId.toString());

          return (
            <Grid container direction="row" key={userId} spacing={1}>
              <Grid item>
                <Checkbox
                  onChange={() =>
                    handleChange({
                      key: file.fileId || file.name,
                      user: userId,
                      checked: true,
                      isGrouped: false
                    })
                  }
                  checked={checked}
                  style={{
                    color: checked ? theme.palette.success.main : 'default'
                  }}
                />
              </Grid>
              <Grid item>
                <QuickUserFromId UserInstanceId={userId.toString()} />
              </Grid>
            </Grid>
          );
        })}
      </Alert>
    </div>
  );
};

export default AuthorizedViewers;
