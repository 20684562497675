import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, CircularProgress, DialogTitle } from '@material-ui/core';
import * as gtag from 'Utils/gtag';
import { CustomDialog } from 'common/Dialog';
import { useProcess } from 'hooks';

const DealProgressOverview = ({
  children,
  Icon,
  CustomBadge,
  title,
  actionBtn,
  fullScreen,
  type,
  disabled,
  syncProcessInstance
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles({ type });
  const { currentDeal } = useProcess();
  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id;
  // const fullScreen = !['Documents'].includes(title);
  const fullWidth = ['Message Hub', 'File Storage', 'Checklist'].includes(
    title
  );

  const cloneChildrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { isOpen, open: isOpen })
  );

  return (
    <>
      <div
        className={`${classes.root} ${disabled ? 'disabled' : ''}`}
        onClick={async () => {
          if (disabled) return;

          if (title === 'Message Hub') {
            setLoading(true);
            syncProcessInstance && (await syncProcessInstance());
            setLoading(false);
            setIsOpen(true);
          } else {
            setIsOpen(true);
          }

          gtag.event({
            feature: 'Deal Feature',
            action: title,
            message: `Opening ${title} Feature`
          });
        }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            <Icon className={classes.icon} />
            <Typography className={classes.text}>{title}</Typography>
            {CustomBadge && <CustomBadge />}
          </>
        )}
      </div>

      <CustomDialog
        maxSize={fullWidth ? 'lg' : 'sm'}
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        staticPosition
        alert={{
          title: title,
          description:
            title === 'Checklist'
              ? `GDPR is a "Mandatory Step" for detailed information on compliance status, please consult the GDPR Contacts page.`
              : `Here is an Overview of ${title}.`,
          type: title === 'Checklist' ? 'error' : 'info'
        }}>
        <DialogContent
          className={classes.dialog}
          style={!fullScreen ? { minHeight: 250, minWidth: 500 } : {}}>
          {cloneChildrenWithProps}
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default DealProgressOverview;

interface Props {
  type?: 'success' | 'warning' | 'error' | 'info';
  fullScreen: boolean;
  children: any;
  Icon?: any;
  CustomBadge?: any;
  title: string;
  actionBtn?: {
    name?: string;
    action?: () => void;
  };
  syncProcessInstance?: () => Promise<boolean>;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light,
      justifyContent: 'flex-start',
      textTransform: 'none',
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'all 0.3s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        borderRight: `50px solid ${theme.palette.primary.main}`,
        background: theme.palette.grey[300],
        color: theme.palette.grey[800],
        '& > p': {
          color: theme.palette.grey[800]
        },
        '& > svg': {
          color: theme.palette.grey[800]
        }
      },
      '&.disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        cursor: 'not-allowed',
        '&:hover': {
          borderRight: 'none',
          background: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
          '& > p': {
            color: theme.palette.action.disabled
          },
          '& > svg': {
            color: theme.palette.action.disabled
          }
        }
      },
      color: 'white'
    },
    icon: { fontSize: 20, color: 'white', marginRight: 8 },
    text: { color: 'white', fontWeight: 'bold' },
    dialog: { padding: theme.spacing(2) },
    narrowContainer: {
      width: '70%',
      position: 'absolute',
      right: '38px',
      top: '150px',
      zIndex: 1
    }
  })
);
