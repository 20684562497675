import React, { Fragment, useState } from 'react';
import {
  TextField,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import { Extra, Tools } from '../Calculator/interfaces';
import { PercentageField, CurrencyField } from '../Utils/inputFields';
import { DatePicker } from '@material-ui/pickers';
import { DateType } from '@date-io/type';
import { useCalculator } from '../Calculator/hooks/useCalculator';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const Additional_InputsDialog = ({ tools }: { tools: Tools }) => {
  const { state } = tools;
  const { handleChange, handleDateChange } = useCalculator(tools);
  const [open, setOpen] = useState(false);
  const shouldOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onSubmit = async () => setOpen(false);

  return (
    <Fragment>
      <Button
        color="primary"
        fullWidth
        onClick={shouldOpen}
        variant="contained">
        Additional Inputs
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogContent>
          <Alert severity="info">
            <Typography>
              These <b>Values</b> do not form part of the <b>Calculation</b>
            </Typography>
          </Alert>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center">
            <Grid item style={{ width: '100%' }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  paddingTop: 5,
                  paddingBottom: 10
                }}>
                Lease
              </Typography>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12} style={{ paddingRight: 15 }}>
                  <TextField
                    onChange={handleChange('RefundOfSalesProceeds')}
                    label="Refund of Sales Proceeds"
                    fullWidth
                    type="number"
                    value={state._AdditionalInputs.RefundOfSalesProceeds}
                  />
                </Grid>
                <Grid item sm={6} xs={12} style={{ paddingRight: 15 }}>
                  <TextField
                    onChange={handleChange('SecondaryPeriodRental')}
                    label="Secondary Period Rental"
                    fullWidth
                    type="number"
                    value={state._AdditionalInputs.SecondaryPeriodRental}
                  />
                </Grid>
              </Grid>

              <Typography
                style={{
                  fontWeight: 'bold',
                  paddingTop: 10
                }}>
                Variable Rate Hire Purchase
              </Typography>
              <Grid container spacing={1}>
                {/*  <Grid item sm={4} xs={12} style={{ paddingRight: 15 }}>
                  <TextField
                    id="Customer Type Selection"
                    select
                    margin="dense"
                    fullWidth
                    label="Customer Type"
                    value={state._AdditionalInputs.CustomerType}
                    onChange={handleChange('CustomerType')}>
                    <MenuItem value={'CUSTOMER_0'}>Customer 0</MenuItem>
                    <MenuItem value={'CUSTOMER_1'}>Customer 1</MenuItem>
                  </TextField>
                </Grid>
                */}
                <Grid item sm={6} xs={12} style={{ paddingRight: 15 }}>
                  <TextField
                    id="Base Rate Selection"
                    select
                    margin="dense"
                    fullWidth
                    label="Base Rate"
                    defaultValue={state._AdditionalInputs.BaseRate}
                    onChange={handleChange('BaseRate')}>
                    <MenuItem value={'LIBOR'}>LIBOR</MenuItem>
                    <MenuItem value={'SONIA'}>SONIA</MenuItem>
                    <MenuItem value={'Bank_Base_Rate'}>Bank Base Rate</MenuItem>
                    <MenuItem value={'Finance_House_Base_Rate'}>
                      Finance House Base Rate
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item sm={6} xs={12} style={{ paddingRight: 15 }}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled={undefined}
                    name="Minimum Base Rate per Annum"
                    onBlur={undefined}
                    onChange={handleChange('MinimumBaseRatePerAnnum')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    value={state._AdditionalInputs.MinimumBaseRatePerAnnum}
                    style={undefined}
                    type={undefined}
                  />
                </Grid>
              </Grid>

              {/* Requested By Shamila to be Removed */}
              {/* Requested By Shamila to be added back. Date: 14/01/2025 */}
              <Typography
                style={{
                  fontWeight: 'bold',
                  paddingTop: 10,
                  paddingBottom: 10
                }}>
                Subsidy
              </Typography>
              <Grid container spacing={1}>
                <Grid item sm={3} xs={12} style={{ paddingRight: 15 }}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Manufacturer’s Subsidy"
                    onBlur={undefined}
                    onChange={handleChange('ManufacturerSubsidy')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._AdditionalInputs.ManufacturerSubsidy}
                  />
                </Grid>
                <Grid item sm={3} xs={12} style={{ paddingRight: 15 }}>
                  <DatePicker
                    fullWidth
                    label="Manufacturer’s Subsidy Date"
                    margin="dense"
                    onChange={(date: DateType | null) => {
                      if (date) {
                        const newDate = new Date(date?.format());
                        handleDateChange('ManufacturerSubsidyDate', newDate);
                      }
                    }}
                    value={state._AdditionalInputs.ManufacturerSubsidyDate}
                    variant="dialog"
                  />
                </Grid>
                <Grid item sm={3} xs={12} style={{ paddingRight: 15 }}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Dealers Subsidy Amount"
                    onBlur={undefined}
                    onChange={handleChange('DealersSubsidyAmount')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._AdditionalInputs.DealersSubsidyAmount}
                  />
                </Grid>
                <Grid item sm={3} xs={12} style={{ paddingRight: 15 }}>
                  <DatePicker
                    fullWidth
                    label="Dealer’s Subsidy Date"
                    margin="dense"
                    onChange={(date: DateType | null) => {
                      if (date) {
                        const newDate = new Date(date?.format());
                        handleDateChange('DealersSubsidyAmountDate', newDate);
                      }
                    }}
                    value={state._AdditionalInputs.DealersSubsidyAmountDate}
                    variant="dialog"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button color="primary" onClick={onSubmit} variant="contained">
              ok
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Additional_InputsDialog;
