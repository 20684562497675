import React, { useEffect } from 'react';
import { TextField, MenuItem, Grid, InputAdornment } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useProcess } from 'hooks/useProcess';
import WarningIcon from '@material-ui/icons/Warning';
import { useRegulated } from 'hooks/useRegulated';
import { Info } from '@material-ui/icons';

const loanFinanceProducts = [
  'Loan - Commercial',
  'Loan - Professional',
  'Loan - Secured',
  'Loan - Tax Loan',
  'Loan - VAT Loan',
  'Loan - Variable Loan',
  'Rolling Credit'
];

const assetFinanceProducts: string[] = [
  'Hire Purchase - Aviation Mortgage',
  'Hire Purchase - Fixed Rate Hire Purchase',
  'Hire Purchase - Lease Purchase',
  'Hire Purchase - Marine Mortgage',
  'Hire Purchase - Refinance Hire Purchase',
  'Hire Purchase - Sale & Hire Purchase Back',
  'Hire Purchase - Variable Rate Balanced Payments',
  'Hire Purchase - Variable Rate Day to Day',
  'Lease - Finance Lease',
  'Lease - Fixed Term Lease',
  'Lease - Lease Rental',
  'Lease - Minimum Period Lease',
  'Lease - Operating Lease',
  'Lease - Refinance Lease',
  'Lease - Sale & Lease Back'
];

const assetFinanceProductsRelatedParty: string[] = [
  'Motor Finance - Fixed Rate Hire Purchase',
  'Motor Finance - Lease Purchase',
  'Motor Finance - Personal Contract Purchase',
  ...assetFinanceProducts
];

const Menu = ({ state, setState }) => {
  const theme = useTheme();
  const { currentProcess } = useProcess();
  const { regulated } = useRegulated();

  const Title = currentProcess?.ProcessDefinition?.Title;
  const isLoanDeal = Title.includes('Loan') || Title === 'Alternative-Finance';
  const hasFinanceProduct =
    state.FinanceProduct === '' || state.FinanceProduct === '0';
  let optionsFinanceProduct = isLoanDeal
    ? loanFinanceProducts
    : assetFinanceProducts;

  let choses = isLoanDeal
    ? optionsFinanceProduct
    : regulated.isRegulatedParty
    ? assetFinanceProductsRelatedParty
    : assetFinanceProducts;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setState({ ...state, [name]: event.target.value });
  };

  const isPlaceholder =
    !state.Title || state.Title === 'null' || state.Title === '';

  useEffect(() => {
    if (state.FinanceType === '' && !regulated.isRegulatedParty) {
      state.FinanceType = 'Non-Regulated';
    }
  }, [state.FinanceType]);

  return (
    <div style={{ paddingTop: theme.spacing(1) }}>
      <Grid container direction="row">
        <Grid item xs={2}>
          <Grid item xs style={{ marginLeft: theme.spacing(1) }}>
            <TextField
              data-cy="finance-product"
              required
              id="FinanceProductID"
              select
              margin="dense"
              style={{ width: '95%' }}
              label="Finance Product"
              value={state.FinanceProduct || 'Select'}
              InputProps={{
                startAdornment: hasFinanceProduct && (
                  <InputAdornment position="start">
                    <WarningIcon
                      style={{
                        color: theme.palette.warning.main
                      }}
                    />
                  </InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 'FinanceProduct')
              }>
              <MenuItem value="Select" disabled>
                Please Select a Finance Product
              </MenuItem>
              {choses.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid item xs style={{ marginLeft: theme.spacing(1) }}>
            <TextField
              data-cy="finance-type"
              required
              id="FinanceTypeID"
              select
              margin="dense"
              disabled={!regulated.isRegulatedParty}
              style={{ width: '95%' }}
              label="Finance Type"
              value={state.FinanceType || 'Select'}
              InputProps={{
                startAdornment: state.FinanceType === '' && (
                  <InputAdornment position="start">
                    <WarningIcon
                      style={{
                        color: theme.palette.warning.main
                      }}
                    />
                  </InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 'FinanceType')
              }>
              <MenuItem value="Select" disabled>
                Please Select The Finance Type
              </MenuItem>
              <MenuItem key={'Non-Regulated'} value={'Non-Regulated'}>
                Non-Regulated Agreement
              </MenuItem>
              <MenuItem key={'Regulated'} value={'Regulated'}>
                Regulated Agreement
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid item xs style={{ marginLeft: theme.spacing(1) }}>
            <TextField
              data-cy="discretionary-type"
              required
              id="DiscretionaryTypeID"
              select
              margin="dense"
              style={{ width: '95%' }}
              label="Discretionary Type"
              value={state.DiscretionaryType || 'Select'}
              InputProps={{
                startAdornment: state.DiscretionaryType === '' && (
                  <InputAdornment position="start">
                    <WarningIcon
                      style={{
                        color: theme.palette.warning.main
                      }}
                    />
                  </InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, 'DiscretionaryType')
              }>
              <MenuItem value="Select" disabled>
                Please Select The Finance Type
              </MenuItem>
              <MenuItem key={'Discretionary'} value={'Discretionary'}>
                Discretionary
              </MenuItem>
              <MenuItem key={'Non-Discretionary'} value={'Non-Discretionary'}>
                Non-Discretionary
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <TextField
            style={{
              width: '95%'
            }}
            id="NOTES_ID"
            margin="dense"
            multiline
            InputLabelProps={{
              style: {
                color: theme.palette.primary.dark
              }
            }}
            InputProps={{
              startAdornment: !state.Notes && (
                <InputAdornment position="start">
                  <Info
                    style={{
                      color: theme.palette.info.main
                    }}
                  />
                </InputAdornment>
              )
            }}
            placeholder="Hidden Notes"
            label="HIDDEN NOTES"
            value={state.Notes}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 'Notes')
            }
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            style={{ width: '98%' }}
            id="QuoteID"
            margin="dense"
            multiline
            InputLabelProps={{
              style: { color: theme.palette.primary.dark }
            }}
            InputProps={{
              startAdornment: isPlaceholder && (
                <InputAdornment position="start">
                  <Info
                    style={{
                      color: theme.palette.info.main
                    }}
                  />
                </InputAdornment>
              )
            }}
            placeholder="Calculator Name"
            label="CALCULATION NAME *"
            value={state.Title === 'null' ? '' : state.Title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, 'Title')
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default Menu;

// const debounce = (func, delay) => {
//   let debounceTimer;
//   const debounced = function (...args) {
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func(...args), delay);
//   };
//   debounced.cancel = () => clearTimeout(debounceTimer);
//   return debounced;
// };
