const NovunaProposal = {
  proposal: {
    interfaceid: '',
    externalreferenceid: '',
    funderid: '',
    introducer: {
      introducercode: '',
      introducerbranch: '',
      salespersoncode: ''
    },
    source: '',
    customer: {
      id: '',
      companytype: '',
      individual: {
        individualname: {
          title: '',
          forename: '',
          surname: '',
          middleinitials: ''
        },
        dateofbirth: ''
      },
      company: {
        companyname: '',
        companyregnum: '',
        dateestablished: '',
        contactname: {
          title: '',
          forename: '',
          surname: '',
          middleinitials: '',
          telephonenumber: '',
          mobilenumber: '',
          emailaddress: ''
        }
      },
      tradingas: '',
      industrytype: '',
      farm: {
        farmacreageowned: '',
        farmacreagerented: ''
      },
      addresses: [
        {
          address: {
            poboxnumber: '',
            flatnumber: '',
            buildingnumber: '',
            buildingname: '',
            streetname: '',
            town: '',
            county: '',
            postcode: '',
            country: '',
            phonenumber: '',
            mobilenumber: '',
            emailaddress: '',
            moveindate: ''
          }
        }
      ],
      otherparties: [
        {
          otherparty: {
            id: '',
            companytype: '',
            individual: {
              individualname: {
                title: '',
                forename: '',
                surname: '',
                middleinitials: ''
              },
              dateofbirth: ''
            },
            company: {
              companyname: '',
              companyregnum: '',
              dateestablished: '',
              contactname: {
                title: '',
                forename: '',
                surname: '',
                middleinitials: '',
                telephonenumber: '',
                mobilenumber: '',
                emailaddress: ''
              }
            },
            tradingas: '',
            industrytype: '',
            addresses: {
              address: {
                poboxnumber: '',
                flatnumber: '',
                buildingnumber: '',
                buildingname: '',
                streetname: '',
                town: '',
                county: '',
                postcode: '',
                country: '',
                phonenumber: '',
                mobilenumber: '',
                emailaddress: '',
                moveindate: ''
              }
            },
            marketinganddatausage: {
              marketingbyemail: true,
              marketingbypost: true,
              marketingbysms: true,
              marketingbyphone: true,
              expectedchangeincircumstances: '',
              reasonforexpectedchangeincircumstances: ''
            }
          }
        }
      ],
      privateaddress: {
        poboxnumber: '',
        flatnumber: '',
        buildingnumber: '',
        buildingname: '',
        streetname: '',
        town: '',
        county: '',
        postcode: '',
        country: '',
        phonenumber: '',
        mobilenumber: '',
        emailaddress: '',
        moveindate: ''
      },
      marketinganddatausage: {
        marketingbyemail: true,
        marketingbypost: true,
        marketingbysms: true,
        marketingbyphone: true,
        expectedchangeincircumstances: '',
        reasonforexpectedchangeincircumstances: ''
      }
    },
    assets: [
      {
        asset: {
          numberofassets: 0,
          cost: 0,
          vattreatment: '',
          description: '',
          type: '',
          deposit: 0,
          residualvalue: 0,
          isnew: true,
          ageatstartdate: 0,
          supplier: {
            id: '',
            companytype: '',
            individual: {
              individualname: {
                title: '',
                forename: '',
                surname: '',
                middleinitials: ''
              },
              dateofbirth: ''
            },
            company: {
              companyname: '',
              companyregnum: '',
              dateestablished: '',
              contactname: {
                title: '',
                forename: '',
                surname: '',
                middleinitials: '',
                telephonenumber: '',
                mobilenumber: '',
                emailaddress: ''
              }
            },
            tradingas: '',
            industrytype: '',
            address: {
              poboxnumber: '',
              flatnumber: '',
              buildingnumber: '',
              buildingname: '',
              streetname: '',
              town: '',
              county: '',
              postcode: '',
              country: '',
              phonenumber: '',
              mobilenumber: '',
              emailaddress: '',
              moveindate: ''
            },
            marketinganddatausage: {
              marketingbyemail: true,
              marketingbypost: true,
              marketingbysms: true,
              marketingbyphone: true,
              expectedchangeincircumstances: '',
              reasonforexpectedchangeincircumstances: ''
            }
          },
          make: '',
          model: ''
        }
      }
    ],
    financials: {
      agreementstartdate: '',
      settlementcompanyname: '',
      settlementagreementnumber: '',
      term: 0,
      advancedrentalamount: 0,
      numberofadvancedrentals: 0,
      regularrentalfrequency: 0,
      totalnumberofrentals: 0,
      paymentmethod: '',
      producttype: '',
      balloonpayment: 0,
      paymentprofiles: {
        paymentprofile: {
          startdate: '',
          rentalfrequency: '',
          numberofrentals: 0,
          isadvance: true,
          rentalpaymentcode: '',
          rentalamount: 0
        }
      }
    },
    repurchaser: {
      id: '',
      companytype: '',
      individual: {
        individualname: {
          title: '',
          forename: '',
          surname: '',
          middleinitials: ''
        },
        dateofbirth: ''
      },
      company: {
        companyname: '',
        companyregnum: '',
        dateestablished: '',
        contactname: {
          title: '',
          forename: '',
          surname: '',
          middleinitials: '',
          telephonenumber: '',
          mobilenumber: '',
          emailaddress: ''
        }
      },
      tradingas: '',
      industrytype: '',
      address: {
        poboxnumber: '',
        flatnumber: '',
        buildingnumber: '',
        buildingname: '',
        streetname: '',
        town: '',
        county: '',
        postcode: '',
        country: '',
        phonenumber: '',
        mobilenumber: '',
        emailaddress: '',
        moveindate: ''
      },
      marketinganddatausage: {
        marketingbyemail: true,
        marketingbypost: true,
        marketingbysms: true,
        marketingbyphone: true,
        expectedchangeincircumstances: '',
        reasonforexpectedchangeincircumstances: ''
      }
    },
    guarantors: [
      {
        guarantor: {
          id: '',
          companytype: '',
          individual: {
            individualname: {
              title: '',
              forename: '',
              surname: '',
              middleinitials: ''
            },
            dateofbirth: ''
          },
          company: {
            companyname: '',
            companyregnum: '',
            dateestablished: '',
            contactname: {
              title: '',
              forename: '',
              surname: '',
              middleinitials: '',
              telephonenumber: '',
              mobilenumber: '',
              emailaddress: ''
            }
          },
          tradingas: '',
          industrytype: '',
          address: {
            poboxnumber: '',
            flatnumber: '',
            buildingnumber: '',
            buildingname: '',
            streetname: '',
            town: '',
            county: '',
            postcode: '',
            country: '',
            phonenumber: '',
            mobilenumber: '',
            emailaddress: '',
            moveindate: ''
          },
          marketinganddatausage: {
            marketingbyemail: true,
            marketingbypost: true,
            marketingbysms: true,
            marketingbyphone: true,
            expectedchangeincircumstances: '',
            reasonforexpectedchangeincircumstances: ''
          }
        }
      }
    ],
    notes: [
      {
        note: {
          notedetail: ''
        }
      }
    ],
    attachments: [
      {
        attachment: {
          name: '',
          data: ''
        }
      }
    ]
  }
};

export default NovunaProposal;
