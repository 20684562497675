import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FieldDefinition,
  ObjectDefinition,
  UserInstance,
  ProcessInstance
} from 'types/interfaces';
import { Calculation } from 'types/calculatorInterfaces';
import { useObject } from 'hooks/useObject';

interface Props {
  ObjectDefinition: ObjectDefinition;
  ProcessInstance?: ProcessInstance;
  UserInstance: UserInstance;
  calculation?: Calculation;
  ProcessInstanceId: number;
  label: string;
  Fields: FieldDefinition[];
  children?: React.ReactNode;
  ProcessStepSelectionType: string;
  isAssetObject?: boolean;
  onOpen?: () => void;
  context?: string;
}

export const AddFieldAndObjectButton = (props: Props) => {
  const { children, label } = props;
  const { loading, createNewObject, proceedToCreateNewObject } =
    useObject(props);

  if (loading) {
    return (
      <div style={{ padding: 16 }}>
        <CircularProgress />
      </div>
    );
  } else {
    if (children) {
      return <div onClick={proceedToCreateNewObject}>{children}</div>;
    }

    if (label) {
      return (
        <Button
          data-cy="add-field-btn"
          color="primary"
          onClick={proceedToCreateNewObject}
          variant="contained">
          {label}
          <AddIcon style={{ marginLeft: 8 }} />
        </Button>
      );
    }

    return (
      <IconButton color="primary" onClick={proceedToCreateNewObject}>
        <AddIcon />
      </IconButton>
    );
  }
};
