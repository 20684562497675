import { firebase } from 'redux/firebase';
import { Domain } from '../types';
import { BugTracker } from 'Utils/Bugtracker';

interface FetchDomains {
  isAFS: boolean;
  isSynergy: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  userInstanceId: number;
  isSendersTab?: boolean;
}

// NOTE: Only admins will be able to see the domain auth tab
export const fetchDomains = async ({
  isAFS,
  isSynergy,
  isSuperAdmin,
  isAdmin,
  userInstanceId,
  isSendersTab
}: FetchDomains): Promise<Domain[]> => {
  const currentHostname = `${window.location.hostname.replace(
    /[^a-zA-Z ]/g,
    ''
  )}`;
  const global = 'global';

  try {
    // Super admins see ALL domains
    let ref: firebase.firestore.Query = firebase
      .firestore()
      .collection('domain');

    let domains: Domain[] = [];

    const snapshot = await ref.get();
    snapshot.forEach((doc) => domains.push(doc.data() as Domain));

    // filter out invalid domains when rendering on Senders tab:
    if (isSendersTab) {
      domains = domains.filter((d) => d.valid);

      // only valid root domains
      const uniqueValidDomains = Array.from(
        new Map(domains.map((d) => [d.domain, d])).values()
      );

      domains = uniqueValidDomains;
    }

    return domains.filter((domain) => {
      // return all domains
      if (isSuperAdmin) return true;

      // global domains:
      if (domain.hostname === global) {
        // filter out AFS global domains for non AFS instances:
        if (!isAFS && domain.domain.includes('afs')) return false;
        // filter out Synergy global domains for non Synergy instances:
        if (!isSynergy && domain.domain.includes('synergy')) return false;
        return true;
      }

      if (isAdmin) {
        // return all domains for this current instance
        if (domain.hostname === currentHostname) return true;
      }

      // For regular users and admins, return custom domains they have created:
      return domain.userInstanceId === userInstanceId;
    });
  } catch (error) {
    BugTracker.notify(error);
    console.error('Error fetching domains: ', error);
    return [];
  }
};
