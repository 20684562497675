import { pdf } from '@react-pdf/renderer';
import PDF from 'components/Calculator/Calculator/components/PDF';
import { funderDocCalculations } from 'components/Calculator/Calculator/functions/FunderDocCalculations';
import { StepperContext } from 'components/Stepper/context';
import { useProcess } from 'hooks';
import { createContext, useContext, useEffect, useState } from 'react';
import { theme } from 'theme';
import { Calculation } from 'types/calculatorInterfaces';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldDefinitionDict,
  FieldInstance,
  ObjectDefinition,
  ProcessInstance,
  IRegulated
} from 'types/interfaces';
import { Convert_ObjectInstance_To_Calculation } from 'Utils';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { mapFieldsToCalculation } from './functions';

interface IListsInterface {
  mainlist: FieldDefinition[] | [];
  notes: FieldDefinition[] | [];
  rateslist: FieldDefinition[] | [];
  advancedlist: FieldDefinition[] | [];
  commissionlist: FieldDefinition[] | [];
  primarymetrics: FieldDefinition[] | [];
  calculationtype: FieldDefinition[] | [];
  quoteTitle: FieldDefinition[] | [];
}

interface IOpenStateInterface {
  rates: boolean;
  fees: boolean;
  commission: boolean;
  calculator: boolean;
  showChart: boolean;
  notes: boolean;
  balloon: boolean;
}

interface IOutputContextInterface {
  open: IOpenStateInterface;
  setOpen: React.Dispatch<React.SetStateAction<IOpenStateInterface>>;
  lists: IListsInterface;
  setLists: React.Dispatch<React.SetStateAction<IListsInterface>>;
  regulated: IRegulated;
  checkEmptyAmountAndCashFlow: () => {
    isEmpty: boolean;
    hasCashFlow: boolean;
  };
  setRegulated: React.Dispatch<React.SetStateAction<IRegulated>>;
  handleCalculatorEvents: (name: string) => void;
  handleFunderDocumentDown: ({
    CompleteObjectInstance,
    FieldDefinitionList
  }: {
    CompleteObjectInstance: CompleteObjectInstance;
    FieldDefinitionList: FieldDefinition[];
  }) => Promise<void>;
  handleClick: (name: string) => void;
  formatData: () => void;
  props: OutputInterface;
  QuoteId: number;
  subSystemUser: number | boolean;
  OverviewQuoteId: number;
}

export interface OutputInterface {
  CompleteObjectInstanceDict?: CompleteObjectInstance[];
  CompleteObjectInstanceList?: CompleteObjectInstance[];
  ProcessInstance: ProcessInstance;
  ProcessInstanceId: number;
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectInstance: CompleteObjectInstance;
  setCalculation?: (Calculation) => void;
  isOverview: boolean;
  readOnly: boolean;
  calculation: Calculation;
  display: 'clientView' | 'systemView';
  index: number;
  selected?: boolean;
  isDeclined?: boolean;
}

const defaultOutputContext: IOutputContextInterface = {
  open: {
    rates: false,
    fees: false,
    commission: false,
    calculator: false,
    showChart: false,
    notes: false,
    balloon: false
  },
  QuoteId: 0,
  subSystemUser: false,
  OverviewQuoteId: 0,
  setOpen: () => {},
  lists: {
    mainlist: [],
    notes: [],
    rateslist: [],
    advancedlist: [],
    commissionlist: [],
    primarymetrics: [],
    calculationtype: [],
    quoteTitle: []
  },
  setLists: () => {},
  regulated: { hasEntity: false, isRegulatedParty: false },
  checkEmptyAmountAndCashFlow: () => ({ isEmpty: true, hasCashFlow: false }),
  setRegulated: () => {},
  handleCalculatorEvents: () => {},
  handleFunderDocumentDown: async () => {},
  handleClick: () => {},
  formatData: () => {},
  props: {
    ProcessInstance: {} as ProcessInstance,
    ProcessInstanceId: 0,
    ObjectDefinition: {} as ObjectDefinition,
    FieldDefinitionDict: {} as FieldDefinitionDict,
    FieldDefinitionList: [] as FieldDefinition[],
    ObjectInstance: {} as CompleteObjectInstance,
    setCalculation: () => {},
    isOverview: false,
    readOnly: false,
    calculation: {} as Calculation,
    display: 'clientView',
    index: 0,
    selected: false
  }
};

export const OutputContext =
  createContext<IOutputContextInterface>(defaultOutputContext);

const OutputProvider = ({
  children,
  props
}: {
  children: React.ReactNode;
  props: OutputInterface;
}) => {
  const {
    ObjectInstance,
    FieldDefinitionDict,
    setCalculation,
    ObjectDefinition
  } = props;

  const { landingpage, user, checkRegulatedPartyStatus } = useProcess();
  const { setQuotesRefreshing } = useContext(StepperContext);

  const QuoteId = props.ObjectInstance?.ObjectInstance?.Id;
  const subSystemUser = QuoteId && user.SystemAccess <= 4;
  const OverviewQuoteId =
    props.ObjectInstance?.FieldInstanceList?.[0]?.ObjectInstanceId;

  const [open, setOpen] = useState({
    rates: landingpage ? false : true,
    fees: false,
    commission: false,
    calculator: false,
    showChart: false,
    notes: false,
    balloon: false
  });

  const [regulated, setRegulated] = useState<IRegulated>({} as IRegulated);

  const mainlist = landingpage
    ? ['Deposit', '_Balloon']
    : ['OverridePayments', 'Deposit', '_Balloon'];

  const calculatedRegulatedStatus = async () => {
    const response = await checkRegulatedPartyStatus();
    setRegulated(response);
  };

  const [lists, setLists] = useState<IListsInterface>({
    mainlist: [],
    notes: [],
    rateslist: [],
    advancedlist: [],
    commissionlist: [],
    primarymetrics: [],
    calculationtype: [],
    quoteTitle: []
  });

  const handleCalculatorEvents = (name: string) => {
    const CompleteObjectDefinition = {
      ObjectDefinition,
      FieldDefinitionDict
    };

    const calculation = Convert_ObjectInstance_To_Calculation(
      ObjectInstance,
      CompleteObjectDefinition
    );

    setCalculation && setCalculation(calculation);
    setOpen({ ...open, [name]: !open[name] });
  };

  const handleClick = (name: string) => {
    if (name.includes('calculator')) {
      handleCalculatorEvents(name);
      setQuotesRefreshing(true);
    } else handleCalculatorEvents(name);
  };

  const formatData = () => {
    const newList: any = {
      mainlist: [],
      notes: [],
      rateslist: [],
      advancedlist: [],
      commissionlist: [],
      primarymetrics: [],
      calculationtype: [],
      balloonlist: [],
      quoteTitle: []
    };
    const newState: any = {};
    if (props?.FieldDefinitionDict) {
      Object.values(props.FieldDefinitionDict).find(
        (FieldDefinition: FieldDefinition) => {
          const Title = FieldDefinition.Title;

          if ('cashflow'.includes(Title)) {
            newState.cashflowId = FieldDefinition.Id;
          }
          if (primarymetrics.includes(Title))
            newList.primarymetrics.push(FieldDefinition);

          if (notes.includes(Title)) newList.notes.push(FieldDefinition);

          if (mainlist.includes(Title)) newList.mainlist.push(FieldDefinition);

          if (rateslist.includes(Title))
            newList.rateslist.push(FieldDefinition);

          if (advancedlist.includes(Title))
            newList.advancedlist.push(FieldDefinition);

          if (commissionlist.includes(Title))
            newList.commissionlist.push(FieldDefinition);

          if (calculationtype.includes(Title))
            newList.calculationtype.push(FieldDefinition);

          if (quoteTitle.includes(Title))
            newList.quoteTitle.push(FieldDefinition);
        }
      );
    }
    setLists(newList);
  };

  const handleFunderDocumentDown = async ({
    CompleteObjectInstance,
    FieldDefinitionList
  }: {
    CompleteObjectInstance: CompleteObjectInstance;
    FieldDefinitionList: FieldDefinition[];
  }) => {
    const getFieldInstanceList = getFieldInstances(CompleteObjectInstance);
    const calculationData = mapFieldsToCalculation({
      FieldDefinitionList,
      FieldInstanceList: getFieldInstanceList
    });

    if (calculationData) {
      const FUNDER_DOC = funderDocCalculations(calculationData);
      const blob = await pdf(<PDF state={FUNDER_DOC} theme={theme} />).toBlob();

      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    }
  };

  const checkEmptyAmountAndCashFlow = () => {
    const CompleteObjectInstance = props?.ObjectInstance;
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

    const findFieldDefinitionAmount: FieldDefinition | undefined =
      Object.values(props?.FieldDefinitionDict).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title.includes('Amount')
      );

    const findFieldDefinitionCashflow: FieldDefinition | undefined =
      Object.values(props?.FieldDefinitionDict).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title.includes('CashFlow')
      );

    let isEmpty = false;
    let hasCashFlow = false;

    FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
      if (
        findFieldDefinitionAmount &&
        findFieldDefinitionAmount.Id === FieldInstance.FieldDefinitionId
      ) {
        if (FieldInstance.FieldValue === '') {
          isEmpty = true;
        }
      }

      if (
        findFieldDefinitionCashflow &&
        findFieldDefinitionCashflow.Id === FieldInstance.FieldDefinitionId
      ) {
        if (FieldInstance.FieldValue !== '') {
          hasCashFlow = true;
        }
      }
    });

    return {
      isEmpty,
      hasCashFlow
    };
  };

  useEffect(() => {
    formatData();
    calculatedRegulatedStatus();
  }, []);

  const value = {
    open,
    setOpen,
    lists,
    setLists,
    regulated,
    setRegulated,
    handleCalculatorEvents,
    handleClick,
    formatData,
    checkEmptyAmountAndCashFlow,
    handleFunderDocumentDown,
    props,
    QuoteId,
    subSystemUser,
    OverviewQuoteId
  };

  return (
    <OutputContext.Provider value={value}>{children}</OutputContext.Provider>
  );
};

export default OutputProvider;

const notes = ['FinanceProduct', 'Notes', 'FinanceType', 'DiscretionaryType'];
const primarymetrics = ['Amount', 'MonthlyPayment', 'Term'];

const rateslist = [
  'APR',
  'FlatRate',
  'NominalFlatRate',
  'Yield',
  'MarginRate',
  'VATPercentage'
];
const advancedlist = [
  'DocFee',
  'DocFeeUpsell',
  'OptionFee',
  'CustomerYield',
  'NPV',
  'Interest'
];
const commissionlist = [
  'CommissionAmount',
  'CommissionPercentage',
  '_Commission'
];
const calculationtype = ['Calculation'];
const quoteTitle = ['QuoteName', 'Title'];
