export const validateDomain = (domain: string): string => {
  const basePattern = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

  if (!basePattern.test(domain))
    return 'Please enter a valid domain (e.g. example.com)';

  // if multiple dots then subdomains have been added
  const dotCount = (domain.match(/\./g) || []).length;
  if (dotCount > 1)
    return 'Domain should not contain subdomains. Use the subdomain field in the Advanced section.';

  return '';
};

export const validateSubDomain = (subdomain: string): string => {
  // A single DNS label:
  // - starts and ends with a letter or digit
  // - can contain letters, digits, and hyphens in between
  // - length: 1-63 chars
  const pattern = /^[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/;

  if (!pattern.test(subdomain))
    return 'Please enter a valid subdomain (e.g. marketing)';

  return '';
};
