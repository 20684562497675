import {
  ELenderAPIType,
  IStructuredField,
  JSONObject,
  JSONValue,
  LenderFieldDefinition,
  TLenderAPIInterfaces,
  TTypeSafeRecord,
  ELenderStatus,
  IProposal,
  IProposalStructure,
  ELender,
  ELenderDecision
} from '../interface';
import { IProposalList } from 'redux/database/Lender API/interface';
import {
  CompleteObjectInstance,
  CompleteProcessInstance,
  FbFileRef,
  FieldInstance
} from 'types/interfaces';
import { getLenderProposalStructure } from '../configuration/proposalConfig';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { globalIds } from 'helpers/globalIdConfig';
import { computeHiddenFields } from '../helper';
import moment from 'moment';
import firebase from 'firebase';

interface EmptyObjectConfig {
  lender: string;
  paths: string[];
}

const EMPTY_OBJECT_CONFIGS: EmptyObjectConfig[] = [
  {
    lender: ELender.DLL,
    paths: ['previousAddress', 'bankDetails']
  }
];

/**
 * Checks if the value is an empty string or zero.
 * @param {number | boolean | string} value - The value to check.
 * @returns {boolean} - True if value is an empty string or zero; otherwise, false.
 */
const isEmptyOrTrue = (value: number | boolean | string): boolean => {
  return value === '' || value === 0;
};

const prepareForApiUpload = (
  data: TLenderAPIInterfaces,
  proposal: IProposal
): JSONObject => {
  // Get the set of hidden fields using computeHiddenFields
  const hiddenFields: Set<string> = computeHiddenFields(data, proposal);
  console.log({ hiddenFields });

  /**
   * Checks if a field path should be hidden based on the hiddenFields Set
   */
  const isFieldHidden = (path: string): boolean => {
    return Array.from(hiddenFields).some((hiddenPath) => {
      // Direct match
      if (path === hiddenPath) return true;

      // Check if this field is part of a hidden parent path
      const hiddenParts = hiddenPath.split('.');
      const fieldParts = path.split('.');
      return hiddenParts.every((part, index) => fieldParts[index] === part);
    });
  };

  const shouldNullifyEmptyObject = (path: string[]): boolean => {
    const currentLender = proposal.lenderTitle;
    const currentPath = path.join('.');

    // Find matching config for current lender
    const config = EMPTY_OBJECT_CONFIGS.find(
      (conf) => conf.lender === currentLender
    );
    if (!config) return false;

    // Check if current path ends with any of the configured paths
    return config.paths.some(
      (configPath) =>
        currentPath.endsWith(configPath) ||
        currentPath.includes(`${configPath}.`)
    );
  };

  const isObjectEmpty = (obj: JSONObject): boolean => {
    return Object.values(obj).every(
      (value) =>
        value === null ||
        value === '' ||
        value === 0 ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' &&
          value !== null &&
          isObjectEmpty(value as JSONObject))
    );
  };

  /**
   * Recursive function to process each value in the internal structure.
   */
  const processValue = (
    value: TLenderAPIInterfaces,
    path: string[],
    fieldDefinition?: LenderFieldDefinition
  ): JSONValue => {
    const currentPath = path.join('.');

    // Check if current path is hidden
    if (isFieldHidden(currentPath)) {
      console.log({ value });
      return Array.isArray(value) ? [] : typeof value === 'object' ? {} : null;
    }

    // Handle arrays
    if (Array.isArray(value)) {
      const processedArray = value
        .map((item, index) =>
          processValue(item, [...path, index.toString()], fieldDefinition)
        )
        .filter(
          (item) =>
            item !== null &&
            (Array.isArray(item) ? item.length > 0 : true) &&
            (typeof item === 'object' ? Object.keys(item).length > 0 : true)
        );

      return processedArray;
    }

    // Handle objects
    if (typeof value === 'object' && value !== null) {
      if (isStructuredField(value)) {
        return processStructuredField(value);
      } else {
        const result: JSONObject = {};

        Object.entries(value).forEach(([key, val]) => {
          const fieldDef =
            (val as IStructuredField)?.FieldDefinition || fieldDefinition;
          const processedValue = processValue(val, [...path, key], fieldDef);

          if (
            processedValue !== null &&
            (!Array.isArray(processedValue) || processedValue.length > 0) &&
            (typeof processedValue !== 'object' ||
              Object.keys(processedValue).length > 0)
          ) {
            result[key] = processedValue;
          }
        });

        if (shouldNullifyEmptyObject(path) && isObjectEmpty(result)) {
          return null;
        }

        return Object.keys(result).length > 0 ? result : {};
      }
    }

    return value as JSONValue;
  };

  /**
   * Processes a structured field by formatting its value based on the field definition.
   */
  const processStructuredField = (field: IStructuredField): JSONValue => {
    const { value, FieldDefinition } = field;

    const isCurrency = Array.isArray(FieldDefinition?.type)
      ? FieldDefinition?.type.includes(ELenderAPIType.Currency)
      : FieldDefinition?.type === ELenderAPIType.Currency;

    const isNumber = Array.isArray(FieldDefinition?.type)
      ? FieldDefinition?.type.includes(ELenderAPIType.Number)
      : FieldDefinition?.type === ELenderAPIType.Number;

    if (isCurrency) {
      return parseFloat(value.toString());
    } else if (isNumber) {
      return parseInt(value.toString(), 10);
    }

    return value;
  };

  return processValue(data, [], undefined) as JSONObject;
};

/**
 * Type guard to check if a value is a StructuredField.
 * @param {any} value - The value to check.
 * @returns {value is IStructuredField} - True if the value is an IStructuredField.
 */
const isStructuredField = (value: any): value is IStructuredField => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'value' in value &&
    'FieldDefinitionId' in value &&
    'ObjectInstanceId' in value &&
    'isReadonly' in value
  );
};

/**
 * Generates a human-readable label from a field name by converting camelCase or snake_case to Title Case.
 * @param {string} fieldName - The field name to convert.
 * @returns {string} - The generated label in Title Case.
 */
const generateLabel = (fieldName: string): string => {
  return fieldName
    .split('.')
    .pop()!
    .split(/(?=[A-Z])/)
    .join(' ')
    .split('_')
    .map((word, _) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

/**
 * Safely parses a string into an integer, returning null if the input is null or not a valid number.
 * @param {string | null} value - The string to parse.
 * @returns {number | null} - The parsed integer or null.
 */
const safeParseInt = (value: string | null): number | null => {
  if (value === null) return null;
  const parsed = parseInt(value, 10);
  return isNaN(parsed) ? null : parsed;
};

/**
 * Converts a list of proposals from the database into a structured format suitable for internal use.
 * @param {Object} params - The parameters object.
 * @param {IProposalList[]} params.proposalsList - The list of proposals to convert.
 * @param {CompleteProcessInstance} params.currentDeal - The current deal containing the complete object instances.
 * @returns {Array<{ ObjectInstanceId: number; proposal: IProposal }>} - An array of proposals with their associated ObjectInstanceId.
 */
const convertProposalsList = async ({
  proposalsList,
  currentDeal
}: {
  proposalsList: IProposalList[];
  currentDeal: CompleteProcessInstance;
}): Promise<{ ObjectInstanceId: number; proposal: IProposal }[]> => {
  const configRef = firebase
    .firestore()
    .collection('globalSetting')
    .doc('lenderConfig');

  const processedProposals = await Promise.all(
    proposalsList.map(async (item: IProposalList) => {
      const ObjectInstanceId = safeParseInt(item.meta._targetObjectInstanceId);
      if (ObjectInstanceId === null) {
        console.error(`Invalid ObjectInstanceId for proposal ${item.id}`);
        return null;
      }

      const getCompleteObjectInstanceDict =
        currentDeal.CompleteObjectInstanceDict;
      const getCompleteObjectInstance: CompleteObjectInstance | undefined =
        Object.values(getCompleteObjectInstanceDict).find(
          (CompleteObjectInstance: CompleteObjectInstance) =>
            CompleteObjectInstance.ObjectInstance.Id === ObjectInstanceId
        );

      const getFieldInstanceList = getFieldInstances(getCompleteObjectInstance);
      const getLenderFieldInstance = getFieldInstanceList.find(
        (FieldInstance: FieldInstance) =>
          globalIds.customer.lenderProposal.LenderProposalFields.lender.includes(
            FieldInstance.FieldDefinitionId
          )
      );

      const getLender = getLenderFieldInstance?.FieldValue;
      const structureByName: IProposalStructure | undefined =
        await getLenderProposalStructure(
          getLender ? parseInt(getLender.toString()) : 0,
          item.meta._lender,
          configRef
        );

      let files: FbFileRef[] = [];
      if (item?.uploadedFiles) {
        item?.uploadedFiles.forEach((element) => {
          const fileFind: FbFileRef | undefined = item.files?.find(
            (item: FbFileRef) => item.fileId === element
          );

          if (fileFind) files.push(fileFind);
        });
      }

      if (!structureByName) return null;
      const proposal: IProposal = {
        ...structureByName,
        id: item.id || item.meta._proposalId || '',
        uniqueId: item.id || item.meta._proposalId || '',
        lenderId: getLender ? parseInt(getLender.toString()) : 0,
        lenderTitle: item.meta._lender as ELender,
        decision: item.meta._decision as ELenderDecision,
        requestedStatus: item.meta._status as ELenderStatus,
        CompleteObjectInstance: getCompleteObjectInstance
          ? getCompleteObjectInstance
          : ({} as CompleteObjectInstance),
        defaultValues: item.proposal,
        files,
        isImported: true
      };

      return { ObjectInstanceId, proposal };
    })
  );

  return processedProposals.filter(
    (item): item is { ObjectInstanceId: number; proposal: IProposal } =>
      item !== null
  );
};

export const isFinanceDependent = (field: IStructuredField): boolean => {
  if (!field.FieldDefinition) return false;
  const fieldType = field.FieldDefinition.type;

  if (Array.isArray(fieldType)) {
    return fieldType.includes(ELenderAPIType.FianceDependent);
  }

  return fieldType === ELenderAPIType.FianceDependent;
};

export const isFinanceParent = (field: IStructuredField): boolean => {
  if (!field.FieldDefinition) return false;
  const fieldType = field.FieldDefinition.type;

  if (Array.isArray(fieldType)) {
    return fieldType.includes(ELenderAPIType.FinanceParent);
  }

  return fieldType === ELenderAPIType.FinanceParent;
};

export const findFinanceParentValue = (data: TLenderAPIInterfaces): string => {
  for (const [key, value] of Object.entries(data)) {
    if (isStructuredField(value)) {
      const fieldType = value.FieldDefinition?.type;
      if (
        (Array.isArray(fieldType) &&
          fieldType.includes(ELenderAPIType.FinanceParent)) ||
        fieldType === ELenderAPIType.FinanceParent
      ) {
        return value.value as string;
      }
    } else if (typeof value === 'object' && value !== null) {
      const result = findFinanceParentValue(value as TLenderAPIInterfaces);
      if (result) return result;
    }
  }
  return '';
};

export const determineFieldRequirement = (
  field: IStructuredField,
  financeParentValue: string
): boolean => {
  if (!field.FieldDefinition) return field.isRequired;

  const fieldType = field.FieldDefinition.type;
  const isFinanceDependent = Array.isArray(fieldType)
    ? fieldType.includes(ELenderAPIType.FianceDependent)
    : fieldType === ELenderAPIType.FianceDependent;

  if (isFinanceDependent) {
    const options = field.FieldDefinition.options as string[] | undefined;
    if (Array.isArray(options) && options.includes(financeParentValue)) {
      return true;
    }
    return false;
  }

  return field.isRequired;
};

interface IFieldCounts {
  total: number;
  completed: number;
  errors: number;
  hasRequired: boolean;
}

/**
 * Recursively counts required fields in the data, tracking the total number, how many are completed, and errors.
 * @param {any} data - The data to process, which can be a structured field, an array, or an object.
 * @returns {Object} - An object containing total required fields, completed fields, error count, and if any required fields exist.
 * @property {number} total - Total number of required fields.
 * @property {number} completed - Number of completed required fields.
 * @property {number} errors - Number of errors encountered.
 * @property {boolean} hasRequired - Whether any required fields exist.
 */
const countRequiredFields = ({
  data,
  hiddenFields,
  financeParentValue,
  fieldPath = []
}: {
  data: any;
  hiddenFields: Set<string>;
  financeParentValue: string;
  fieldPath?: string[];
}): IFieldCounts => {
  let total = 0;
  let completed = 0;
  let errors = 0;
  let hasRequired = false;

  const currentPath = fieldPath.join('.');

  const shouldBeHidden = (path: string) => {
    return Array.from(hiddenFields).some((hiddenPath) => {
      // Get the last part of the hidden path (e.g., 'soleTrader' from 'client.soleTrader')
      const hiddenKey = hiddenPath.split('.').pop();
      // Get the last part of the current path
      const currentKey = path.split('.').pop();

      // Direct match on the field name
      if (currentKey === hiddenKey) return true;

      // Full path match
      if (path === hiddenPath) return true;

      // Check if path contains the hidden field
      const pathContainsHidden =
        path.includes(`.${hiddenKey}.`) || path.endsWith(`.${hiddenKey}`);

      return pathContainsHidden;
    });
  };

  if (shouldBeHidden(currentPath)) {
    return { total, completed, errors, hasRequired };
  }

  if (isStructuredField(data)) {
    const isRequired = determineFieldRequirement(data, financeParentValue);

    if (isRequired) {
      total++;
      hasRequired = true;
      const isValidValue =
        data.value === 0 ||
        (data.value !== '' && data.value !== undefined && data.value !== null);
      if (isValidValue && !data.error) {
        completed++;
      }
      if (data.error && !hiddenFields.has(currentPath)) {
        errors++;
      }
    }
  } else if (Array.isArray(data)) {
    data.forEach((item, index) => {
      const counts = countRequiredFields({
        data: item,
        hiddenFields,
        financeParentValue,
        fieldPath: [...fieldPath, index.toString()]
      });
      total += counts.total;
      completed += counts.completed;
      errors += counts.errors;
      hasRequired = hasRequired || counts.hasRequired;
    });
  } else if (typeof data === 'object' && data !== null) {
    Object.entries(data).forEach(([key, value]) => {
      const counts = countRequiredFields({
        data: value,
        hiddenFields,
        financeParentValue,
        fieldPath: [...fieldPath, key]
      });
      total += counts.total;
      completed += counts.completed;
      errors += counts.errors;
      hasRequired = hasRequired || counts.hasRequired;
    });
  }

  return { total, completed, errors, hasRequired };
};

interface IValidationResult {
  error: string | null;
  isNumber?: boolean;
}

const validateDateField = (
  value: string | null,
  isDateTime: boolean,
  requirement?: {
    dateLength?: number;
    pattern?: string;
  }
): IValidationResult => {
  if (!value) return { error: null };

  const format = isDateTime ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
  const date = moment(value, format, true);

  if (value.length < (isDateTime ? 19 : 10)) {
    return { error: null };
  }

  if (!date.isValid()) {
    return {
      error: `Please enter a valid ${
        isDateTime ? 'date and time' : 'date'
      } in the correct format`
    };
  }

  if (requirement?.dateLength) {
    const maxDate = moment()
      .subtract(requirement.dateLength, 'years')
      .endOf('day');
    if (date.isAfter(maxDate)) {
      return {
        error: `Date must be at least ${requirement.dateLength} years ago`
      };
    }
  }

  return { error: null };
};

const handleDateChange = (
  date: moment.Moment | null,
  isDateTime: boolean,
  requirement?: { dateLength?: number; pattern?: string }
) => {
  if (!date) return { value: null, error: null };

  const format = isDateTime ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
  const formattedDate = date.format(format);
  const validation = validateDateField(formattedDate, isDateTime, requirement);

  return {
    value: formattedDate,
    error: validation.error
  };
};

export {
  prepareForApiUpload,
  generateLabel,
  isStructuredField,
  isEmptyOrTrue,
  convertProposalsList,
  countRequiredFields,
  handleDateChange
};
