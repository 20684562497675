import { createNotification } from 'react-redux-notify';
import { store } from 'redux/store';
import { successNotif, errorNotif } from 'components/Notifications';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

interface UpdateDomain {
  key: string;
  hostname: string;
  originalHostname: string;
}

// NOTE: this is currenly only being used to toggle / update hostnames to global
export const updateDomain = async ({
  key,
  hostname,
  originalHostname
}: UpdateDomain): Promise<UpdateDomain | void> => {
  const { dispatch } = store;

  try {
    await firebase
      .firestore()
      .collection('domain')
      .doc(key)
      // set originalHostname for older domains that didnt have it on creation
      .update({ hostname, originalHostname });

    dispatch(
      createNotification(
        successNotif(`Domain hostname updated to: ${hostname}`)
      )
    );

    return {
      key,
      hostname,
      originalHostname
    };
  } catch (error) {
    BugTracker.notify(error);
    console.error(`Error updating domain key: ${key}`, error);

    dispatch(
      createNotification(errorNotif(`Something went wrong, please try again.`))
    );
  }
};
