import { validateForm } from '../../Utils/validation';
import axios, { AxiosRequestConfig } from 'axios';
import fecha from 'fecha';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import { Calculation } from 'types/calculatorInterfaces';
import { store } from 'redux/store';
import { SET_CALCULATION } from 'redux/actions/types';
import { setForValueFromCalculationNo } from './setForValueFromCalculationNo';
import { INIT_STATE } from 'components/Calculator/Utils/calculations';

/**
 * Sanitizes an object by replacing null/undefined values with defaults
 * @param obj - The source object to sanitize
 * @param defaultTemplate - Template with default values
 * @returns A new object with all values sanitized
 * @example
 * const sanitized = sanitizeObject(sourceObj, defaultTemplate);
 */

const sanitizeObject = <T,>(obj: Partial<T>, defaultTemplate: T): T => {
  const result = { ...defaultTemplate };

  for (const [key, value] of Object.entries(obj)) {
    const typedKey = key as keyof T;

    if (key.startsWith('_')) {
      if (value === null || value === undefined) continue;

      const defaultValue = defaultTemplate[typedKey];
      if (
        typeof value === 'object' &&
        typeof defaultValue === 'object' &&
        defaultValue !== null
      ) {
        result[typedKey] = sanitizeObject(
          value as Partial<typeof defaultValue>,
          defaultValue
        );
      }
    } else {
      if (value !== null && value !== undefined) {
        result[typedKey] = value as T[keyof T];
      }
    }
  }

  return result;
};

// Convert to percentages to send e.g.('10%' => 0.1)
const convertPercentages = (
  stateObj: Calculation,
  submitObj: Calculation,
  nameArray: string[]
): void => {
  nameArray.forEach((name) => {
    if (stateObj[name] !== 0) submitObj[name] = stateObj[name] / 100;
  });
};

// Set default values for empty fields
const setDefaultValues = (
  stateObj: Calculation,
  submitObj: Calculation,
  nameArray: string[]
): void => {
  nameArray.forEach((name) => {
    if (stateObj[name] === undefined) return;
    if (stateObj[name] === null) return;
    const value = stateObj[name].toString();

    switch (name) {
      case 'Term':
        if (value === '') submitObj[name] = 36;
        break;

      case 'Counting':
        if (value === '0') submitObj.Counting = 'Actual365';
        break;

      case 'FinanceProduct':
      case 'Notes':
        submitObj[name] = value || '';
        break;

      case 'Title':
        submitObj[name] = value || undefined;
        break;

      default:
        if (value === '') {
          submitObj[name] = name === 'Assets' ? [] : 0;
        }
    }
  });
};

const percentize = (fieldArray: string[], rData: Calculation): void => {
  fieldArray.forEach((field) => {
    if (rData.hasOwnProperty(field) && !isNaN(Number(rData[field]))) {
      rData[field] = (parseFloat(rData[field]) * 100).toFixed(4);
    }
  });
};

export const onSubmit = ({
  isRecalculate,
  balloonRep,
  state,
  StartDate,
  forValue,
  fromValue,
  setSnackBar,
  setLoading,
  setErrorMessage,
  setOutput,
  setCalculated,
  setState,
  props,
  freq,
  isLoanDeal,
  baseURL,
  grossAdvance
}) => {
  const { _NetAdvance, ...restOfState } = state;
  const { isLoading, ...restOfNetAdvance } = _NetAdvance;

  const stateObj = {
    ...restOfState,
    _NetAdvance: {
      ...restOfNetAdvance
    }
  };

  let submitObj: Calculation = { ...stateObj };

  // Check if any values is Null sometimes it does.
  submitObj = sanitizeObject(stateObj, INIT_STATE);

  submitObj.PaymentFrequency = freq;
  if (isRecalculate) {
    submitObj.CashFlow = [];
    submitObj.CashFlowAsText = '';
  }

  if (submitObj._NetAdvance && submitObj._NetAdvance.Vat_Payment) {
    const { Vat_Payment } = submitObj._NetAdvance;
    const condition =
      Vat_Payment === 'ADD_VAT_TO_NET' || Vat_Payment === 'DEFER_VAT';

    let AmountIncludesVAT = false;
    if (condition) AmountIncludesVAT = true;

    if (condition) {
      // Requested By Shamila when we calculate for ADD_VAT_TO_NET & DEFER_VAT we are getting the GROSS not Advance
      submitObj.Amount = grossAdvance;
    }

    setState((s) => ({ ...s, AmountIncludesVAT }));
    submitObj.AmountIncludesVAT = AmountIncludesVAT;
  }

  // Add token
  submitObj.Token = '499e21a2a4ade77cad4a47e1a1dccd9d';
  // Add date to state object
  submitObj.StartDate = fecha.format(
    new Date(StartDate),
    'YYYY-MM-DDThh:ss:ss'
  );

  convertPercentages(stateObj, submitObj, [
    'MarginRate',
    'Yield',
    'APR',
    'FlatRate',
    'NominalFlatRate',
    'VATPercentage',
    'FundingRate',
    'IntroducerFeePercentOfCommission',
    'RateOfInterestPerAnnum'
  ]);

  setDefaultValues(stateObj, submitObj, [
    'Amount',
    'Yield',
    'Deposit.value',
    'Term',
    'Counting',
    'Rate',
    'APR',
    'FlatRate',
    'NominalFlatRate',
    'MonthlyPayment',
    'DocFee',
    'DocFeeUpsell',
    'OptionFee',
    'CommissionPercentage',
    'CommissionAmount',
    'InitialRentals',
    'FundingRate',
    'NonVATableItemsCost',
    'IntroducerFee',
    'IntroducerFeePercentOfCommission',
    'Notes',
    'FinanceProduct',
    'Assets',
    'FinanceType',
    'DiscretionaryType',
    'Title',
    'RateOfInterestPerAnnum'
  ]);

  /** TERM INCREMENT IS HAPPENING HERE */
  if (stateObj._Balloon.ContractPeriod === 1) {
    submitObj.Term = parseInt(submitObj.Term.toString()) + 1;
  }

  // If the IsLoanDeal then we getting the VATPercentage to 0
  if (isLoanDeal) submitObj.VATPercentage = 0;

  // Making sure CommissionAmount & CommissionPercentage uses Broker_Commission
  submitObj.CommissionAmount = stateObj._Commission.Broker_Commission;
  submitObj.CommissionPercentage = stateObj._Commission.Broker_Rate / 100;

  // We always need the Gross not the Net
  /** REQUEST by Shamila they said for DocFee we always use a Gross which is Fee + VAT */
  submitObj.DocFee =
    parseInt(stateObj._DocumentFee.DocFee.toString()) +
    parseInt(stateObj._DocumentFee.DocFee_Vat.toString());

  submitObj.OptionFee =
    parseInt(stateObj._DocumentFee.OptionFee.toString()) +
    parseInt(stateObj._DocumentFee.OptionFee_Vat.toString());

  // console.log('REQUEST: ', submitObj);
  const config: AxiosRequestConfig = {
    url: `${baseURL}calculatorjson.ashx`,
    method: 'POST',
    data: submitObj,
    headers: { 'Content-Type': 'multipart/form-data; boundary=something' }
  };

  const { status, message } = validateForm(submitObj, forValue, fromValue);
  if (!status) {
    setSnackBar({
      open: true,
      message: message,
      variant: 'warning'
    });
  }

  if (status) {
    setLoading(true);
    axios(config)
      .then((r) => {
        // console.log('RESPONSE: ', r.data, r);
        const calculation: Calculation = r.data;

        if (calculation._Balloon.ContractPeriod === 1) {
          calculation.Term -= 1;
        }

        if (calculation.CashFlow === null) {
          calculation.CashFlow = [];
        }

        if (calculation.OutputStatus === 1) {
          setSnackBar({
            open: true,
            message: calculation.OutputMessage,
            variant: 'warning'
          });
          setLoading(false);
          throw calculation.OutputMessage;
        }

        if (calculation.OutputStatus === 0) {
          setErrorMessage(null);
          setSnackBar({
            open: true,
            message: 'Calculation Updated',
            variant: 'success'
          });
        }

        percentize(
          [
            'APR',
            'FlatRate',
            'Yield',
            'MarginRate',
            'NominalFlatRate',
            'CommissionPercentage',
            'VATPercentage',
            'YieldRate',
            'FundingRate',
            'RateOfInterestPerAnnum'
          ],
          calculation
        );

        if (forValue === 'forBalloon') {
          calculation.MonthlyPayment = balloonRep;
        }

        const newCalculation: Calculation = {
          ...calculation,
          FinanceType: submitObj.FinanceType,
          DiscretionaryType: submitObj.DiscretionaryType
        };

        setOutput(newCalculation);
        setLoading(false);
        setCalculated(true);

        // Set response data to parent window object for integrations
        // window.parent.calculation = r.data;
        store.dispatch({ type: SET_CALCULATION, payload: newCalculation });

        // How is this calculator integrated?
        // If props.setCalculation - then currently in our system and we want to update the parent object so it can be saved into the process builder data schema
        if (props.setCalculation) {
          if (state._Balloon && state._Balloon.Balloon) {
            const Balloon = state._Balloon.Balloon;
            newCalculation._Balloon.Balloon = Balloon;
          }
          newCalculation.PaymentFrequency = freq;
          props.setCalculation(newCalculation);
        }

        gtag.event({
          action: 'CALCULATION_RUN',
          feature: 'CALCULATOR',
          message: isRecalculate ? 'Integrated' : 'Standalone'
        });

        const { newforValue, newfromValue } = setForValueFromCalculationNo(
          newCalculation.Calculation
        );
        gtag.event({
          action: `${newforValue}: ${newfromValue}`,
          feature: 'CALCULATOR',
          message: newCalculation.Amount
        });
      })
      .catch((e) => {
        setLoading(false);
        BugTracker.notify(e);
      });
  }
};

//? No longer needed as we are now having more decimal places
// const roundFields = (
//   data: Calculation,
//   fields: string[],
//   decimalPlaces: number
// ): Calculation => {
//   const roundedData: Calculation = { ...data };

//   if (data.CommissionAmount) {
//     const roundedCommission = roundUp(data.CommissionAmount.toString(), 2);
//     roundedData['CommissionAmount'] = parseFloat(roundedCommission);
//   }

//   if (data.Deposit) {
//     const roundedDeposit = roundUp(data.Deposit.toString(), 2);
//     roundedData['Deposit'] = parseFloat(roundedDeposit);
//   }

//   fields.forEach((field) => {
//     if (
//       roundedData[field] !== undefined &&
//       typeof roundedData[field] === 'number'
//     ) {
//       const decimalValue = roundedData[field] * 100;
//       const roundedDecimalValue = parseFloat(
//         roundUp(decimalValue.toString(), decimalPlaces)
//       );

//       roundedData[field] = roundedDecimalValue / 100;
//     }
//   });

//   return roundedData as Calculation;
// };

//* Function To Help Find Out If It Needs To Round Down Or Up.
//? No longer needed as we are now having more decimal places
// const roundUp = (numStr: string, decimalPlaces: number): string => {
//   let num = parseFloat(numStr);
//   if (isNaN(num)) return numStr;

//   const delta = 0.0000000000001;
//   const isCloseToWholeNumber = num % 1 > 1 - delta;
//   if (isCloseToWholeNumber) {
//     num = Math.ceil(num);
//   } else {
//     const factor5Decimal = Math.pow(10, 5);
//     let rounded5DecimalNum =
//       Math.round(num * factor5Decimal) / factor5Decimal;

//     const thirdFourthAndFifthDigit = Math.floor(
//       (rounded5DecimalNum * 100000) % 1000
//     );

//     const thirdDigit = Math.floor(thirdFourthAndFifthDigit / 100);
//     const fourthDigit = Math.floor((thirdFourthAndFifthDigit % 100) / 10);
//     const fifthDigit = thirdFourthAndFifthDigit % 10;

//     if (thirdDigit === 5 && fourthDigit === 5) {
//       if (fifthDigit >= 5) {
//         rounded5DecimalNum =
//           (Math.floor(rounded5DecimalNum * 100000) + 10) / 100000;
//       }
//     } else if (fourthDigit >= 5 || (thirdDigit === 5 && fifthDigit >= 5)) {
//       rounded5DecimalNum =
//         (Math.floor(rounded5DecimalNum * 100000) + 10) / 100000;
//     }

//     num = rounded5DecimalNum;
//   }

//   const factor = Math.pow(10, decimalPlaces);
//   num = Math.round(num * factor) / factor;

//   return num.toFixed(decimalPlaces);
// };

//? Was used for rounding, used to sit inside the axios request.
// const fieldsToRound = [
//   'Yield',
//   'APR',
//   'FlatRate',
//   'NominalFlatRate',
//   'MarginRate',
//   'RateOfInterestPerAnnum'
// ];
// const roundedDataObject = roundFields(roundedData, fieldsToRound, 2);
