import React, { Fragment, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import { Select, MenuItem, InputAdornment } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { SuccessButton } from 'common/Button';
import { ISender, SENDER, SenderDetails } from '../types';
import { CustomDialog } from 'common/Dialog';
import { updateSender, createSender } from '../functions';
import { fetchDomains } from 'components/Marketing/DomainAuthentication/functions';
import { QueryKeys } from 'components/Marketing/types';
import { useTypedSelector } from 'redux/reducers';
import { useInstance } from 'hooks/useInstance';
import { validateEmail, extractDomainFromEmail } from '../utils';

const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

const from_email_key: keyof ISender = 'from_email';
const reply_to_key: keyof ISender = 'reply_to';

interface Props {
  sender?: ISender;
  hasWritePermissions?: boolean;
}

export default function UpsertSenderDialog({
  sender,
  hasWritePermissions
}: Props) {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();
  const user = useTypedSelector((s) => s.user.user);
  const { isAFS, isSynergy } = useInstance();

  const isAdmin = user.SystemAccess === 10;
  const isSuperAdmin = user.SystemAccess === 15;

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<ISender>(sender || SENDER);
  // need to store seperate state for email dropdowns
  const [selectedDomains, setSelectedDomains] = React.useState({
    from_email: extractDomainFromEmail(sender?.from_email) || '',
    reply_to: extractDomainFromEmail(sender?.reply_to) || ''
  });
  const [errors, setErrors] = React.useState<any[]>([]);

  const { data: validDomains = [], isLoading: loadingDomains } = useQuery(
    [QueryKeys.FetchDomains],
    () =>
      fetchDomains({
        isAFS,
        isSynergy,
        isAdmin,
        isSuperAdmin,
        userInstanceId: user.Id,
        isSendersTab: true
      }),
    {
      enabled: true,
      refetchOnMount: true
    }
  );

  const {
    mutate: create,
    isLoading,
    error: createErrors
  } = useMutation(createSender, {
    onSuccess: (valid) => {
      if (valid === false) return;

      queryClient.invalidateQueries(QueryKeys.FetchSenders);
      setOpen(false);
    }
  });

  const {
    mutate: update,
    isLoading: updateLoading,
    error: updateErrors
  } = useMutation(updateSender, {
    onSuccess: (valid) => {
      if (valid === false) return;

      queryClient.invalidateQueries(QueryKeys.FetchSenders);
      setOpen(false);
    }
  });

  const onSubmit = () => {
    const fromError = validateEmail(state[from_email_key] as string);
    const replyError = validateEmail(state[reply_to_key] as string);

    if (fromError) {
      setErrors([...errors, { field: from_email_key, message: fromError }]);

      return;
    }

    if (replyError) {
      setErrors([
        ...errors,
        {
          field: reply_to_key,
          message: replyError
        }
      ]);

      return;
    }

    if (sender) update(state);
    else
      create({
        ...state,
        hostname,
        userInstanceId: user.Id
      });
  };

  const loading = isLoading || updateLoading || loadingDomains;

  const isEditMode = !!sender;

  const disabled = isEditMode && !hasWritePermissions;

  React.useEffect(() => {
    const errs = [
      // axios errors
      ...(Array.isArray(createErrors) ? createErrors : []),
      ...(Array.isArray(updateErrors) ? updateErrors : [])
    ];

    setErrors(errs);
  }, [createErrors, updateErrors]);

  // select on mount during tests:
  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'test') return;

    const domain = validDomains[0]?.domain;
    if (!domain) return;

    setSelectedDomains({
      from_email: domain,
      reply_to: domain
    });
  }, [validDomains]);

  return (
    <>
      <SuccessButton
        data-testid={isEditMode ? `edit-sender-${sender.id}` : 'create-sender'}
        onClick={() => setOpen(true)}
        variant="contained"
        disabled={disabled}>
        {isEditMode ? 'Edit' : 'Create Sender'}
      </SuccessButton>

      <CustomDialog
        open={open}
        handleClose={() => setOpen(false)}
        maxSize="sm"
        aria-labelledby="form-dialog-title"
        alert={{
          title: `${isEditMode ? 'Edit Sender' : 'Create Sender'}`,
          description: `${
            isEditMode
              ? "Here you can Update the Sender's details"
              : 'Please Create a new Sender that will send out Campaigns'
          }`,
          type: 'info'
        }}>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info">
              <Typography>
                You may only create one sender on your current plan. These
                details will be included in all or your email marketing sends.
              </Typography>
            </Alert>
          </DialogContentText>
          <form
            data-testid={
              isEditMode
                ? `upsert-sender-form-${sender.id}`
                : `upsert-sender-form`
            }
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            {Object.keys(SenderDetails).map((key) => {
              const { required, hidden, title } = SenderDetails[key];

              if (hidden) return;

              const isEmailKey = key === from_email_key || key === reply_to_key;

              return (
                <Fragment key={key}>
                  <TextField
                    {...(register(key), { required })}
                    error={!!errors.find((e) => e.field === key)}
                    fullWidth
                    id={key}
                    key={key}
                    label={title}
                    margin="dense"
                    type="text"
                    value={state[key]}
                    variant="outlined"
                    onChange={(e) =>
                      setState((s) => {
                        const value = e.target.value as string;
                        const selectedDomain = selectedDomains[key];

                        // Remove error for this field if it exists
                        setErrors((errs) =>
                          errs.filter((err) => err.field !== key)
                        );

                        if (!isEmailKey || !selectedDomain)
                          return { ...s, [key]: value };

                        // prepend "@domain" to the string that is typed in:
                        if (value.includes('@')) {
                          const [username] = value.split('@');
                          return {
                            ...s,
                            [key]: `${username}@${selectedDomain}`
                          };
                        } else {
                          // dont allow them to remove the @
                          return { ...s, [key]: `@${selectedDomain}` };
                        }
                      })
                    }
                    //  Email Domain fields:
                    InputProps={{
                      ...(isEmailKey
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  required
                                  id={`email-input-${key}`}
                                  label={title}
                                  value={selectedDomains[key]}
                                  onChange={(e) => {
                                    setSelectedDomains((s) => ({
                                      ...s,
                                      [key]: e.target.value
                                    }));

                                    // also update textfield state
                                    setState((s) => {
                                      const currentValue = state[key] as string;
                                      const domainValue = e.target.value;

                                      const newValue = `${currentValue.replace(
                                        /@.*/,
                                        ''
                                      )}@${domainValue}`;

                                      return {
                                        ...s,
                                        [key]: newValue
                                      };
                                    });
                                  }}>
                                  {validDomains.map(({ domain, key }) => {
                                    return (
                                      <MenuItem value={domain} key={key}>
                                        {domain}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </InputAdornment>
                            )
                          }
                        : {})
                    }}
                  />
                  {errors?.find((e) => e.field === key) && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {errors.find((e) => e.field === key).message}
                    </Typography>
                  )}
                </Fragment>
              );
            })}
            <div
              style={{
                margin: '12px 0',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <ButtonGroup color="inherit" variant="contained">
                <SuccessButton
                  type="submit"
                  style={{ width: 100 }}
                  data-testid={
                    isEditMode
                      ? `update-sender-btn-${sender.id}`
                      : `submit-sender-btn`
                  }>
                  {loading ? (
                    <span style={{ color: 'white ' }}>
                      <CircularProgress color="inherit" size={20} />
                    </span>
                  ) : isEditMode ? (
                    'update'
                  ) : (
                    'submit'
                  )}
                </SuccessButton>
                <Button onClick={() => setOpen(false)}>Close</Button>
              </ButtonGroup>
            </div>
          </form>
        </DialogContent>
      </CustomDialog>
    </>
  );
}
