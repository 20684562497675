import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import styleToObject from 'style-to-object';
import toStyle from 'style-object-to-string';

interface StyleIssue {
  element: string;
  style: string;
  line: number;
  message: string;
  severity: 'error' | 'warning';
  potentialError?: string;
  context?: string;
}

interface HTMLAnalyzerProps {
  defaultHtml?: string;
}

/**
 * Converts various CSS unit values to pixels
 * @param {string} value - The CSS value to convert (e.g., "12pt", "16px", "14")
 * @returns {string} The converted value in pixels
 */
const convertToPixels = (value: string): string => {
  const match = value.match(/^(\d*\.?\d+)\s*(pt|px)?$/);
  if (match) {
    const [, num, unit] = match;
    const numValue = parseFloat(num);

    if (unit === 'pt') {
      return `${Math.round(numValue * 1.333333)}px`;
    } else if (unit === 'px' || !unit) {
      return `${Math.round(numValue)}px`;
    }
  }
  return value;
};

/**
 * Checks if a style attribute uses CSS string syntax instead of React object syntax
 * @param {string} styleAttr - The style attribute to check
 * @returns {boolean} True if the style uses CSS string syntax
 */
const checkForReactStyleIssues = (styleAttr: string): boolean => {
  return (
    styleAttr.includes(':') && // Contains CSS property-value separator
    !styleAttr.startsWith('{') && // Not in React object syntax
    !styleAttr.includes('{{') // Not in React object syntax
  );
};

/**
 * Fixes HTML styles by converting them to React-compatible format
 * @param {string} html - The HTML string to process
 * @returns {string} The processed HTML with React-compatible styles
 */
export const fixStyles = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Helper function to convert string styles to React-compatible object
  const convertToReactStyle = (styleStr: string): string => {
    try {
      const styleObj = styleToObject(styleStr);
      if (!styleObj) return styleStr;

      // Convert the style properties to camelCase
      const reactStyleObj = Object.entries(styleObj).reduce(
        (acc, [key, value]) => {
          // Convert kebab-case to camelCase
          const camelKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

          // Handle numeric values
          let processedValue: string | number = value;
          if (
            typeof value === 'string' &&
            !isNaN(Number(value)) &&
            value !== ''
          ) {
            processedValue = Number(value);
          }

          acc[camelKey] = processedValue;
          return acc;
        },
        {} as Record<string, string | number>
      );

      // Convert back to string but in React format
      return `{{${Object.entries(reactStyleObj)
        .map(([k, v]) => `${k}:${typeof v === 'string' ? `'${v}'` : v}`)
        .join(',')}}}`;
    } catch (error) {
      console.error('Error converting to React style:', error);
      return styleStr;
    }
  };

  // Clean element classes
  const cleanClasses = (element: Element) => {
    const classes = element.getAttribute('class');
    if (classes) {
      const cleanedClasses = classes
        .split(/\s+/)
        .filter(
          (cls) =>
            !cls.toLowerCase().includes('mso') &&
            !cls.toLowerCase().includes('xmso')
        )
        .join(' ');
      if (cleanedClasses) {
        element.setAttribute('class', cleanedClasses);
      } else {
        element.removeAttribute('class');
      }
    }
  };

  // First pass: clean all classes
  doc.querySelectorAll('*').forEach(cleanClasses);

  // Second pass: fix style attributes and convert to React style syntax
  doc.querySelectorAll('*[style]').forEach((el) => {
    const style = el.getAttribute('style');
    if (!style) return;

    try {
      // Parse style string to object
      const styleObj = styleToObject(style);
      if (!styleObj) return;

      // Properties that need unit conversion
      const propsNeedingUnits = [
        'fontSize',
        'width',
        'height',
        'margin',
        'marginTop',
        'marginRight',
        'marginBottom',
        'marginLeft',
        'padding',
        'paddingTop',
        'paddingRight',
        'paddingBottom',
        'paddingLeft'
      ];

      // Convert values for specific properties
      Object.entries(styleObj).forEach(([key, value]) => {
        if (typeof value === 'string') {
          const kebabKey = key
            .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
            .toLowerCase();

          // Skip relative units and percentages
          if (
            value.includes('%') ||
            value.includes('em') ||
            value.includes('rem') ||
            value.includes('vw') ||
            value.includes('vh')
          ) {
            return;
          }

          if (kebabKey.includes('margin') || kebabKey.includes('padding')) {
            const match = value.match(/^(\d*\.?\d+)\s*(pt|px)?$/);
            if (match) {
              const [, num, unit = ''] = match;
              const numValue = parseFloat(num);
              styleObj[key] =
                unit === 'pt'
                  ? `${Math.round(numValue * 1.333333)}px`
                  : `${Math.round(numValue)}px`;
            }
          } else if (propsNeedingUnits.includes(key)) {
            styleObj[key] = convertToPixels(value);
          }
        }
      });

      // Convert to React-compatible style string
      const reactStyle = convertToReactStyle(
        toStyle(styleObj)
          .replace(/(\s*;\s*)?[^;]+mso[^;]*/gi, '')
          .replace(/;;+/g, ';')
          .replace(/;+\s*$/, '')
      );

      if (reactStyle) {
        el.setAttribute('style', reactStyle);
      } else {
        el.removeAttribute('style');
      }
    } catch (error) {
      console.error('Error fixing styles:', error);
    }
  });

  // Final pass: remove remaining MSO-related attributes
  doc.querySelectorAll('*').forEach((el) => {
    Array.from(el.attributes)
      .filter((attr) => attr.name.toLowerCase().includes('mso'))
      .forEach((attr) => el.removeAttribute(attr.name));
    cleanClasses(el);
  });

  return doc.body.innerHTML;
};

/**
 * React component that analyzes HTML content for style-related issues
 * and provides fixes for common React styling problems
 *
 * @component
 * @param {HTMLAnalyzerProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const HTMLAnalyzer = ({ defaultHtml = '' }: HTMLAnalyzerProps) => {
  const [htmlInput, setHtmlInput] = useState(defaultHtml);
  const [issues, setIssues] = useState<StyleIssue[]>([]);
  const [parsedElements, setParsedElements] = useState<any[]>([]);
  const [selectedLine, setSelectedLine] = useState<number | null>(null);

  const analyzeHTML = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlInput, 'text/html');
    const foundIssues: StyleIssue[] = [];
    const elements: any[] = [];

    const lines = htmlInput.split('\n');
    const findLineInfo = (
      searchStr: string
    ): { lineNumber: number; context: string } => {
      const lineIndex = lines.findIndex((line) => line.includes(searchStr));
      return {
        lineNumber: lineIndex + 1,
        context: lines[lineIndex] || ''
      };
    };

    const elementsWithStyle = doc.querySelectorAll('*[style]');
    elementsWithStyle.forEach((el) => {
      const styleAttr = el.getAttribute('style');
      const elementHtml = el.outerHTML;
      const { lineNumber, context } = findLineInfo(elementHtml);

      // Check for MSO classes
      const classNames = el.getAttribute('class');
      if (classNames?.includes('mso')) {
        foundIssues.push({
          element: el.tagName.toLowerCase(),
          style: classNames,
          line: lineNumber,
          message: 'Element uses MSO classes which may cause styling issues',
          severity: 'warning',
          context
        });
      }

      if (styleAttr) {
        // Add check for React style syntax
        if (checkForReactStyleIssues(styleAttr)) {
          foundIssues.push({
            element: el.tagName.toLowerCase(),
            style: styleAttr,
            line: lineNumber,
            message:
              'Style prop uses string format instead of React object syntax',
            severity: 'error',
            potentialError:
              'React Error #62: style prop expects an object, not a string',
            context
          });
        }

        try {
          const styleObj = styleToObject(styleAttr);
          elements.push({
            tag: el.tagName.toLowerCase(),
            style: styleAttr,
            parsed: styleObj,
            html: elementHtml,
            line: lineNumber,
            context
          });

          // Check for missing units
          const hasMissingUnits = Object.entries(styleObj || {}).some(
            ([key, value]) => {
              if (typeof value !== 'string') return false;
              return (
                (key === 'fontSize' ||
                  key.includes('margin') ||
                  key.includes('padding')) &&
                !value.includes('px') &&
                !value.includes('em') &&
                !value.includes('rem') &&
                !value.includes('%') &&
                !value.includes('vh') &&
                !value.includes('vw') &&
                !isNaN(parseFloat(value))
              );
            }
          );

          if (hasMissingUnits) {
            foundIssues.push({
              element: el.tagName.toLowerCase(),
              style: styleAttr,
              line: lineNumber,
              message: 'Style contains numeric values without units',
              severity: 'error',
              potentialError: 'Missing units for numeric values',
              context
            });
          }

          // Check for non-camelCase style properties
          Object.keys(styleObj || {}).forEach((key) => {
            if (key.includes('-')) {
              foundIssues.push({
                element: el.tagName.toLowerCase(),
                style: styleAttr,
                line: lineNumber,
                message: 'Style property uses kebab-case instead of camelCase',
                severity: 'error',
                potentialError: `'${key}' should be camelCase for React`,
                context
              });
            }
          });
        } catch (error) {
          foundIssues.push({
            element: el.tagName.toLowerCase(),
            style: styleAttr,
            line: lineNumber,
            message: `Parse error: ${
              error instanceof Error ? error.message : 'Unknown error'
            }`,
            severity: 'error',
            context
          });
        }
      }
    });

    // Get additional HTML issues
    const additionalIssues = additionalHTMLChecks(doc, lines);

    // Combine all issues
    const allIssues = [...foundIssues, ...additionalIssues];

    setIssues(allIssues);
    setParsedElements(elements);
  };

  const handleFix = () => {
    const fixed = fixStyles(htmlInput);
    setHtmlInput(fixed);
    analyzeHTML();
  };

  const highlightLine = (lineNumber: number) => {
    const textArea = document.querySelector('textarea');
    if (!textArea) return;

    const lines = htmlInput.split('\n');
    const position = lines
      .slice(0, lineNumber - 1)
      .reduce((pos, line) => pos + line.length + 1, 0);

    textArea.focus();
    textArea.setSelectionRange(
      position,
      position + lines[lineNumber - 1].length
    );
    setSelectedLine(lineNumber);
  };

  return (
    <div className="space-y-4 p-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium">
          Paste HTML to Analyze:
        </label>
        <textarea
          className="w-full h-64 p-2 border rounded font-mono text-sm"
          value={htmlInput}
          onChange={(e) => setHtmlInput(e.target.value)}
          placeholder="Paste your HTML here..."
        />
      </div>

      <div className="flex space-x-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={analyzeHTML}>
          Analyze HTML
        </button>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={handleFix}>
          Fix Issues
        </button>
      </div>

      {issues.length > 0 && (
        <div className="space-y-2">
          <h3 className="font-medium">Found Issues ({issues.length}):</h3>
          <div className="space-y-2">
            {issues.map((issue, idx) => (
              <div key={idx} className="space-y-2">
                <Alert
                  severity={issue.severity}
                  onClick={() => highlightLine(issue.line)}
                  style={{ cursor: 'pointer' }}>
                  <div className="space-y-1">
                    <div className="font-medium">
                      Issue in {issue.element} at line {issue.line}:
                    </div>
                    <div className="text-sm">{issue.message}</div>
                    {issue.potentialError && (
                      <div className="text-sm text-red-600">
                        Details: {issue.potentialError}
                      </div>
                    )}
                    <div className="text-sm font-mono bg-gray-100 p-2 rounded">
                      Style: {issue.style}
                    </div>
                  </div>
                </Alert>
              </div>
            ))}
          </div>
        </div>
      )}

      {parsedElements.length > 0 && (
        <div className="space-y-2">
          <h3 className="font-medium">
            Parsed Elements ({parsedElements.length}):
          </h3>
          <div className="space-y-2">
            {parsedElements.map((el, idx) => (
              <div
                key={idx}
                className={`p-4 rounded ${
                  selectedLine === el.line
                    ? 'bg-blue-50 border-blue-200 border'
                    : 'bg-gray-50'
                }`}
                onClick={() => highlightLine(el.line)}
                style={{ cursor: 'pointer' }}>
                <div className="font-medium">
                  {el.tag} (Line {el.line})
                </div>
                {el.style && (
                  <>
                    <div className="text-sm mt-2">Raw style:</div>
                    <pre className="text-sm bg-white p-2 rounded mt-1">
                      {el.style}
                    </pre>
                    <div className="text-sm mt-2">Parsed style object:</div>
                    <pre className="text-sm bg-white p-2 rounded mt-1">
                      {JSON.stringify(el.parsed, null, 2)}
                    </pre>
                    <div className="text-sm mt-2">Context:</div>
                    <pre className="text-sm bg-white p-2 rounded mt-1 break-all">
                      {el.context}
                    </pre>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HTMLAnalyzer;

/**
 * Performs additional HTML checks for common issues and accessibility problems
 * @param {Document} doc - The parsed HTML document
 * @param {string[]} lines - Array of lines from the original HTML
 * @returns {StyleIssue[]} Array of found issues
 */
const additionalHTMLChecks = (doc: Document, lines: string[]): StyleIssue[] => {
  const additionalIssues: StyleIssue[] = [];

  const findLineInfo = (
    searchStr: string
  ): { lineNumber: number; context: string } => {
    const lineIndex = lines.findIndex((line) => line.includes(searchStr));
    return {
      lineNumber: lineIndex + 1,
      context: lines[lineIndex] || ''
    };
  };

  // Check for deprecated HTML elements
  const deprecatedElements = [
    'font',
    'center',
    'strike',
    'u',
    'big',
    'tt',
    'marquee'
  ];
  deprecatedElements.forEach((tag) => {
    const elements = doc.getElementsByTagName(tag);
    Array.from(elements).forEach((el) => {
      const { lineNumber, context } = findLineInfo(el.outerHTML);
      additionalIssues.push({
        element: tag,
        style: el.outerHTML,
        line: lineNumber,
        message: `Deprecated HTML element <${tag}> detected`,
        severity: 'warning',
        context
      });
    });
  });

  // Check for empty links or buttons
  doc.querySelectorAll('a, button').forEach((el) => {
    if (!el.textContent?.trim() && !el.querySelector('img')) {
      const { lineNumber, context } = findLineInfo(el.outerHTML);
      additionalIssues.push({
        element: el.tagName.toLowerCase(),
        style: el.outerHTML,
        line: lineNumber,
        message:
          'Empty interactive element found - may cause accessibility issues',
        severity: 'error',
        context
      });
    }
  });

  // Check for inline styles with !important
  doc.querySelectorAll('[style]').forEach((el) => {
    const style = el.getAttribute('style');
    if (style?.includes('!important')) {
      const { lineNumber, context } = findLineInfo(el.outerHTML);
      additionalIssues.push({
        element: el.tagName.toLowerCase(),
        style: style,
        line: lineNumber,
        message:
          'Use of !important in inline styles may cause specificity issues',
        severity: 'warning',
        context
      });
    }
  });

  // Check for problematic viewport meta tags
  doc.querySelectorAll('meta[name="viewport"]').forEach((el) => {
    const content = el.getAttribute('content');
    if (
      content?.includes('user-scalable=no') ||
      content?.includes('maximum-scale=1')
    ) {
      const { lineNumber, context } = findLineInfo(el.outerHTML);
      additionalIssues.push({
        element: 'meta',
        style: el.outerHTML,
        line: lineNumber,
        message:
          'Viewport meta tag restricts user scaling - potential accessibility issue',
        severity: 'warning',
        context
      });
    }
  });

  // Check for tables used for layout
  doc.querySelectorAll('table').forEach((table) => {
    const hasLayoutAttributes =
      table.hasAttribute('width') ||
      table.hasAttribute('align') ||
      table.getAttribute('role') === 'presentation';
    if (hasLayoutAttributes) {
      const { lineNumber, context } = findLineInfo(table.outerHTML);
      additionalIssues.push({
        element: 'table',
        style: table.outerHTML,
        line: lineNumber,
        message:
          'Table appears to be used for layout purposes rather than data presentation',
        severity: 'warning',
        context
      });
    }
  });

  // Check for nested tables
  doc.querySelectorAll('table table').forEach((table) => {
    const { lineNumber, context } = findLineInfo(table.outerHTML);
    additionalIssues.push({
      element: 'table',
      style: table.outerHTML,
      line: lineNumber,
      message:
        'Nested tables detected - may cause layout and maintenance issues',
      severity: 'warning',
      context
    });
  });

  // Check for missing alt attributes on images
  doc.querySelectorAll('img').forEach((img) => {
    if (!img.hasAttribute('alt')) {
      const { lineNumber, context } = findLineInfo(img.outerHTML);
      additionalIssues.push({
        element: 'img',
        style: img.outerHTML,
        line: lineNumber,
        message: 'Image missing alt attribute - required for accessibility',
        severity: 'error',
        context
      });
    }
  });

  // Check for hardcoded dimensions
  doc.querySelectorAll('[width], [height]').forEach((el) => {
    const width = el.getAttribute('width');
    const height = el.getAttribute('height');
    if ((width && !width.includes('%')) || (height && !height.includes('%'))) {
      const { lineNumber, context } = findLineInfo(el.outerHTML);
      additionalIssues.push({
        element: el.tagName.toLowerCase(),
        style: el.outerHTML,
        line: lineNumber,
        message:
          'Hardcoded dimensions detected - consider using responsive units',
        severity: 'warning',
        context
      });
    }
  });

  return additionalIssues;
};
