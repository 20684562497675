const CASProposal = {
  notes: '',
  assets: [
    {
      vat: '',
      manufacturer: '',
      model: '',
      quantity: 0,
      costExVat: 0,
      isNewAsset: true,
      supplierId: '',
      description: '',
      balloonAmount: 0,
      nonVatItemsAmount: 0,
      yearOfManufacture: 0
    }
  ],
  remoteId: '',
  sourceId: '',
  directors: [
    {
      nameFirst: '',
      nameLast: '',
      email: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        postCode: '',
        date: '',
        occupancy: ''
      },
      typename: '',
      dateOfBirth: '',
      isGuarantor: true,
      nationality: '',
      contactNumber: '',
      consentedToSearch: true
    }
  ],
  suppliers: [
    {
      id: '',
      name: '',
      address: {
        city: '',
        country: '',
        postCode: '',
        addressLine1: ''
      },
      type: '',
      registrationNumber: ''
    }
  ],
  typename: '',
  background: '',
  salesPerson: {
    name: '',
    email: '',
    contactNumber: ''
  },
  brokerReference: '',
  customerCompany: {
    name: '',
    type: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      postCode: '',
      typename: ''
    },
    contactNameFirst: '',
    contactNameLast: '',
    contactNumber: '',
    contactEmail: '',
    registrationNumber: '',
    dateTrading: ''
  },
  facility: {
    fees: [
      {
        name: '',
        amount: 0
      }
    ],
    term: {
      frequency: '',
      number_of_advance_installments: 0,
      number_of_periodic_installments: 0
    },
    deposit: 0,
    commission: 0,
    lenderYield: 0,
    vatDeferral: '',
    typeOfProduct: '',
    balloonPayment: 0,
    repaymentAmount: 0
  },
  guarantors: [
    {
      name: '',
      type: '',
      address: {
        city: '',
        country: '',
        postCode: '',
        addressLine1: '',
        addressLine2: '',
        date: '',
        occupancy: ''
      },
      registrationNumber: '',
      nameFirst: '',
      nameLast: '',
      email: '',
      dateOfBirth: '',
      nationality: '',
      contactNumber: '',
      consentedToSearch: true
    }
  ],
  rationaleForFinance: ''
};

export default CASProposal;
