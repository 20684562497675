import { globalIds } from 'helpers/globalIdConfig';
import { IUniqueRequiredInformationGroup } from '../interface';

export const dialogConfigGroups: IUniqueRequiredInformationGroup[] = [
  {
    ids: globalIds.rules.GDPR_Rules, // GDPR
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the privacy notice to the contacts.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.TOBA_Rules, // Terms of Business Agreement
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the "Terms of Business Agreement" to the customer.',
      showChecklist: true,
      showAssignedViewers: false,
      checkboxes: [
        {
          name: 'briefAndFeeScheduleCompleted',
          label:
            'Please ensure that you have completed the Brief and as much of the Fee Schedule as possible as they will be sent out with the TOBA.'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.brief_Rules, // Brief
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the Brief to the customer.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.feeSchedule_Rules, // Fee Schedule
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the "Fee Schedule" to the Customer.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.FFF_Rules, // Fact Find Form
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the customer the "Fact Find Form".',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.indicativeQuotes_Rules, // Indicative Quote
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the Indicative Quote to the Customer.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.decisionCustomer_Rules, // Decision to Customer
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the Decision to Customer to the Customer.',
      showChecklist: false,
      showAssignedViewers: true
    }
  },
  {
    ids: globalIds.rules.consumerDuly_Rules, // Lender Product Sheet
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Lender Product Sheet' to the customer.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'productSheetUploaded',
          label:
            'Please ensure that you have uploaded the correct Lender Product Sheet & have completed all the fields in the Consumer Duty section.'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.facilityAcceptanceProduct, // Facility Acceptance/Product Sheet
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Facility Acceptance/Product' to the customer.`,
      showChecklist: true,
      showAssignedViewers: true
    }
  },
  {
    ids: globalIds.rules.decisionPrinciple_Rules, // Decision in Principle
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Decision in Principle' to the customer.`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.formalOffer_Rules, // Formal Offer
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Formal Offer' to the customer.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'feeScheduleUpdated',
          label: `I have updated the 'Fee Schedule' to reflect any changes.`
        }
      ]
    }
  },
  {
    ids: globalIds.rules.satisfactionDocument_Rules, // Customer Satisfaction Document
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the customer Satisfaction Document.`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.appraisalFeeCustomer_Rules, // Appraisal Fee Invoice
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Appraisal Fee Invoice' to the customer.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'invoiceRaised',
          label: 'This invoice has been raised by accounts'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.brokerFeeCustomer_Rules, // Broker Fee Invoice
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Broker Fee Invoice' to the customer.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'invoiceRaised',
          label: 'This invoice has been raised by accounts'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.enquiryLender_Rules, // Enquiry to Lender
    config: {
      isRequired: false,
      dialogTitle: `You are about to send an Enquiry to your chosen lender`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.proposalForm_Rules, // Proposal Form to Lender
    config: {
      isRequired: false,
      dialogTitle: `You are about to send a 'Proposal Form' to your chosen lender.`,
      showChecklist: false,
      showAssignedViewers: true
    }
  },
  {
    ids: globalIds.rules.proposalToProposalContract_Rules, // Proposal to Proposal Contact
    config: {
      isRequired: false,
      dialogTitle: `You are about to send a 'Proposal' to your Proposal Contact.`,
      showChecklist: false,
      showAssignedViewers: true
    }
  },
  {
    ids: globalIds.rules.brokerFeeLender_Rules, // Broker Fee Invoice to Lender
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Broker Fee Invoice' to your chosen Lender.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'invoiceRaised',
          label: 'This invoice has been raised by accounts'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.lenderCommissionPayout_Rules, // Lender Commission Invoice to Lender
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Lender Commission Invoice' to your chosen Lender.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'invoiceRaised',
          label: 'This invoice has been raised by accounts'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.lenderCommissionPayoutContact_Rules, // Lender Commission Invoice to Lender Payout Contact
    config: {
      isRequired: false,
      dialogTitle: `You are about to send the 'Lender Commission Invoice' to your Lender Payout Contact.`,
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'invoiceRaised',
          label: 'This invoice has been raised by accounts'
        }
      ]
    }
  },
  {
    ids: globalIds.rules.requestAppraisalFee_Rules, // Request Appraisal Fee Invoice
    config: {
      isRequired: false,
      dialogTitle: `You are about to request the 'Appraisal Fee Invoice' from the accounts team.`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.requestBrokerFee_Rules, // Request Broker Fee Invoice
    config: {
      isRequired: false,
      dialogTitle: `You are about to request the 'Broker Fee Invoice' from the accounts team.`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.lenderCommission_Rules, // Request Lender Commission Invoice
    config: {
      isRequired: false,
      dialogTitle: `You are about to request the 'Lender Commission Invoice' from the accounts team.`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.updateFormalOffer, // Update Formal Offer
    config: {
      isRequired: false,
      dialogTitle: `You are about to Update the 'Formal Offer'.`,
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.quote_Rules, // Send Quote
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the Quote to the customer.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.lenderDecision_Rules, // Send Facility Acceptance & Commission disclosure
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the Facility Acceptance & Commission disclosure to the customer.',
      showChecklist: false,
      showAssignedViewers: true
    }
  },
  {
    ids: globalIds.rules.lenderDecision_Rules, // Send Lender Decision
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the Lender Decision to the customer.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.customerDocs_Rules, // Send Customer Documents
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the customer documents to the customer.',
      showChecklist: false,
      showAssignedViewers: true
    }
  },
  {
    ids: globalIds.rules.lenderPayingFee, // Send Payout Confirmation to Lender Contact
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the Payout Confirmation to the Lender.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.updateLenderDecision_Rules, // Update Lender Decision Status
    config: {
      isRequired: false,
      dialogTitle: 'You are about to update the Lender Decision Status.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.updateQuoteDecision_Rules, // Update Quote Decision Status
    config: {
      isRequired: false,
      dialogTitle: 'You are about to update the Quote Decision Status.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.referral_Rules, // Send Referral Form to Lender
    config: {
      isRequired: false,
      dialogTitle: 'You are about to send the Referral Form to the Lender.',
      showChecklist: false,
      showAssignedViewers: false
    }
  },
  {
    ids: globalIds.rules.referralFeeInvoice, // Send Referral Fee Invoice to Lender
    config: {
      isRequired: false,
      dialogTitle:
        'You are about to send the Referral Fee Invoice to the Lender.',
      showChecklist: true,
      showAssignedViewers: true,
      checkboxes: [
        {
          name: 'invoiceRaised',
          label: 'This invoice has been raised by accounts'
        }
      ]
    }
  }
];
