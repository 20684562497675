export enum UserDefinitionIds {
  CustomerUDID = 523,
  LenderUDID = 21,
  ContactUDID = 528,
  SuppliersUDID = 26,
  BrokersUDID = 522,
  AccountsUDID = 527,
  IntroducersUDID = 641
}

export enum UserDefinitionNames {
  Customers = 'Customers',
  Lenders = 'Lenders',
  Contacts = 'Contacts',
  Suppliers = 'Suppliers',
  Brokers = 'Brokers',
  Accounts = 'Accounts',
  Introducers = 'Introducers'
}

export const globalIds = {
  accountSynergyFinanceId: 7958,
  accountCommissionId: 20267,
  rules: {
    GDPR_Rules: [212, 137, 1620, 49, 1293, 145, 1424, 1327, 1481],
    TOBA_Rules: [1538, 1311, 1753],
    brief_Rules: [216, 1358],
    feeSchedule_Rules: [215, 1322],
    FFF_Rules: [1226, 109, 1621, 196, 1294, 1328],
    decisionPrinciple_Rules: [1229],
    formalOffer_Rules: [226],
    paidOut_Rules: [1334],
    referral_Rules: [1329, 1484],
    referralFeeInvoice: [1487],
    invoiceAdaptiveMoney: [1541],

    // Consumer Duly
    consumerDuly_Rules: [1611, 1624, 1612, 1613],

    // Facility Acceptance/Product
    facilityAcceptanceProduct: [1610, 1614],

    // Commission Invoice
    commissionInvoice_Rules: [
      1230, 1630, 53, 1299, 156, 1430, 1540, 1485, 1615
    ],

    // Documents
    satisfactionDocument_Rules: [228, 1234, 1635, 1235, 1306, 1546, 1547],

    // Update
    updateFormalOffer: [1605],
    updateLenderDecision_Rules: [1460, 1636, 1462, 1463, 1465, 1466],
    updateQuoteDecision_Rules: [1542, 1637, 1543, 1544],
    updateConsumerDuly_Rules: [1603],

    // Customer
    customerDocs_Rules: [138, 1625, 201, 1297, 1419, 1429, 20, 1344],
    decisionCustomer_Rules: [1623, 1296, 203, 1428],

    // Quotes
    indicativeQuotes_Rules: [221],
    quote_Rules: [17, 1622, 50, 1295, 1471, 1470],

    // Lender
    enquiryLender_Rules: [220],
    lenderCommission_Rules: [1222],
    lenderCommissionPayout_Rules: [1168, 139, 1631, 202],
    lenderCommissionPayoutContact_Rules: [1606, 1447, 1632, 1453],
    lenderDecision_Rules: [93, 200, 1343],
    lenderPayingFee: [1389],

    // Request
    requestSupplierInvoice_Rules: [205],
    requestPayoutConfirmationLender_Rules: [1303],
    requestPayoutConfirmationContact_Rules: [1455],
    requestAppraisalFee_Rules: [229, 1317],
    requestBrokerFee_Rules: [1220, 1319],

    // Proposal
    proposalForm_Rules: [225],
    proposalToProposalContract_Rules: [
      1228, 1446, 1629, 1452, 1454, 1458, 1459, 1456
    ],
    proposalLender_Rules: [1233, 1628, 51, 1298, 150, 1427, 1345],

    // Fees
    appraisalFeeCustomer_Rules: [1153, 1385],
    brokerFeeCustomer_Rules: [1161],
    brokerFeeLender_Rules: [1165, 1392, 1607]
  },
  customer: {
    addresses: {
      AddressObjectDefinition: 2854
    },
    shareholders: {
      ShareHolderObjectDefinition: 3724
    },
    directors: {
      DirectorsObjectDefinition: 2853
    },
    entity: [3464, 3602],
    lenderProposal: {
      LenderProposalObjectDefinition: [3397, 3500],
      LenderProposalFields: {
        lender: [21159, 21961]
      }
    },
    partiesAndContacts: {
      Customer: 23115,
      PrimaryContact: 23108,
      LenderProposalContact: 23116,
      LenderPayoutsContact: 23109,
      SelectedBroker: 23111
    },
    proposalComments: {
      background: [21641, 21975],
      project: [21642, 21976],
      financials: [21643, 21977],
      summary: [21644, 21978]
    },
    quotes: {
      QuotesObjectDefinition: [2840]
    },
    assets: {
      AssetsObjectDefinition: [2838]
    }
  },
  borrower: {
    companyDetails: [18318, 18319, 23280, 21881, 18448]
  },
  supplier: {
    assetDetail: {
      supplier: 21944,
      supplierContact: 23270
    }
  },
  ruleLists: {
    BorrowerContactRules: [
      216, 214, 215, 221, 1229, 226, 228, 1153, 1161, 1226, 109, 17, 1233, 205,
      93, 138, 139, 1230, 1234, 196, 50, 200, 201, 1235, 1294, 1311, 1295, 1358,
      1322, 1296, 1297, 1306, 1341, 1342, 1343, 1344, 1369, 203, 1419, 1458,
      1428, 1429, 1459, 1538, 1610
    ],
    LenderRules: [1233, 139, 202, 1298, 1303, 1345, 1350, 150, 1427],
    LenderProposalRules: [1228, 51],
    LenderPayoutRules: [227],
    AccountsRules: [
      1230, 229, 1220, 1222, 53, 1299, 1317, 1319, 1346, 1430, 156
    ],
    SupplierContact: [205]
  },
  userDefinitionIds: {
    customerUDID: UserDefinitionIds.CustomerUDID,
    lenderUDID: UserDefinitionIds.LenderUDID,
    contactUDID: UserDefinitionIds.ContactUDID,
    suppliersUDID: UserDefinitionIds.SuppliersUDID,
    brokersUDID: UserDefinitionIds.BrokersUDID,
    accountsUDID: UserDefinitionIds.AccountsUDID,
    introducersUDID: UserDefinitionIds.IntroducersUDID
  },
  objectDefinitionIds: {
    assetDetailOBID: 2838,
    proposalComments: [3457, 3501],
    viewer: [3482, 2839, 95]
  },
  stepProcesses: {
    supplierRequestInvoice: [1521],
    commissionInvoices: [
      1750, 1522, 1550, 2218, 2250, 1618, 2290, 2293, 2307, 2357, 2416
    ]
  },
  processInstanceStep: {
    quoteSteps: [1502, 2243, 2209, 2211]
  }
};
