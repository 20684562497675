import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useState
} from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

/**
 * Interface extending NumberFormatProps for custom number formatting
 * @interface INumberFormat
 * @extends {NumberFormatProps}
 */
interface INumberFormat extends NumberFormatProps {
  /**
   * onChange handler for the input
   * @param {Object} event - The change event
   * @param {Object} event.target - The target of the event
   * @param {string} event.target.name - The name of the input field
   * @param {string} event.target.value - The new value of the input field
   */
  onChange: (event: { target: { name: string; value: string } }) => void;
  /** The name of the input field */
  name: string;
}

/**
 * Interface for PercentageField props
 * @interface IPercentageField
 * @extends {Omit<TextFieldProps, 'onChange'>}
 */
export interface IPercentageField extends Omit<TextFieldProps, 'onChange'> {
  /**
   * onChange handler for the input
   * @param {Object} event - The change event
   * @param {Object} event.target - The target of the event
   * @param {string} event.target.name - The name of the input field
   * @param {string} event.target.value - The new value of the input field
   */
  onChange: (event: { target: { name: string; value: string } }) => void;
  /** Minimum allowed percentage */
  minPercentage?: number;
  /** Maximum allowed percentage */
  maxPercentage?: number;
  /** The value of the input field */
  value: unknown;
}

/**
 * Custom percentage format component for NumberFormat
 * @component
 * @param {INumberFormat} props - The props for the component
 * @param {React.Ref<NumberFormat>} ref - The ref for the NumberFormat component
 * @returns {React.ReactElement} A NumberFormat component configured for percentage input
 */
const CustomPercentageFormat: ForwardRefExoticComponent<
  INumberFormat & RefAttributes<NumberFormat>
> = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      suffix="%"
      decimalScale={6}
      fixedDecimalScale
      allowNegative={false}
    />
  );
});

/**
 * PercentageField component for inputting percentage values
 * @component
 * @param {IPercentageField} props - The props for the component
 * @returns {React.ReactElement} A TextField component configured for percentage input
 */
export const LenderPercentageField: React.FC<IPercentageField> = ({
  onChange,
  value,
  InputLabelProps,
  minPercentage,
  maxPercentage,
  ...other
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);

  /**
   * Converts the input value to a display value
   * @param {unknown} val - The value to convert
   * @returns {string} The formatted display value
   */
  const convertToDisplayValue = (val: unknown): string => {
    if (val === null || val === undefined || val === '') return '';
    const numVal = typeof val === 'string' ? parseFloat(val) : Number(val);
    return isNaN(numVal) ? '' : numVal.toString();
  };
  /**
   * Handles the change event of the input
   * @param {Object} event - The change event
   * @param {Object} event.target - The target of the event
   * @param {string} event.target.name - The name of the input field
   * @param {string} event.target.value - The new value of the input field
   */

  const handleChange = (event: { target: { name: string; value: string } }) => {
    const numValue = parseFloat(event.target.value);
    let errorMessage;

    if (minPercentage !== undefined && numValue < minPercentage) {
      errorMessage = `Percentage must be at least ${minPercentage}%`;
    } else if (maxPercentage !== undefined && numValue > maxPercentage) {
      errorMessage = `Percentage must not exceed ${maxPercentage}%`;
    }

    setError(errorMessage);
    onChange({
      target: {
        name: event.target.name,
        value: numValue.toString()
      }
    });
  };

  return (
    <TextField
      {...other}
      value={convertToDisplayValue(value)}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      InputProps={{
        inputComponent: CustomPercentageFormat as any
      }}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      variant="outlined"
      error={!!error}
      helperText={error}
    />
  );
};
