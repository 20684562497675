import { useState } from 'react';
import {
  Grid,
  Button,
  useTheme,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Rule } from 'types/interfaces';
import { useIsAuthenticated } from '@azure/msal-react';

interface IEnhancedGridItemProps {
  rule: Rule;
  handleRuleClick: (rule: Rule) => Promise<void>;
  disabledState: any;
  regulatedStatus: boolean;
}

export const EnhancedGridItem = ({
  rule,
  handleRuleClick,
  disabledState,
  regulatedStatus
}: IEnhancedGridItemProps) => {
  const theme = useTheme();
  const isAuth = useIsAuthenticated();
  const [loading, setLoading] = useState(false);

  const isDisabled = () => {
    if (!disabledState) return false;
    if (
      typeof disabledState === 'object' &&
      Object.keys(disabledState).length === 0
    )
      return false;

    const { isRegulated_Only_Required, isNon_Regulated_Only_Required } =
      disabledState?.overallStatus || {};

    const isRegulatedOnly = isRegulated_Only_Required && !regulatedStatus;
    const isNonRegulatedOnly = isNon_Regulated_Only_Required && regulatedStatus;
    const isRegulatedFlag = disabledState?.regulated && !regulatedStatus;
    const isNonRegulatedFlag = disabledState?.nonRegulated && regulatedStatus;

    return (
      isRegulatedOnly ||
      isNonRegulatedOnly ||
      isRegulatedFlag ||
      isNonRegulatedFlag ||
      !disabledState.success
    );
  };

  const generateTooltipTitle = (): string => {
    if (!disabledState || disabledState.success) {
      return !isAuth
        ? 'Please Connect To Microsoft 365 To Enable This Rule.'
        : rule.Title;
    }

    let messages = [`Please ensure ${rule.Title} is completed.`];

    if (disabledState.incompleteSafeguards?.length) {
      const safeguards = disabledState.incompleteSafeguards
        .map((sg) => `(Action Name: ${sg.id}, Action Needed: ${sg.action})`)
        .join(', ');
      messages.push(`Missing: ${safeguards}`);
    }

    const conditions: string[] = [];
    if (disabledState.overallStatus?.isGDPR_Required)
      conditions.push('GDPR is Required.');
    if (disabledState.overallStatus?.isGDPR_Only_Required)
      conditions.push('Only GDPR is required.');
    if (disabledState.overallStatus?.isRegulated_Only_Required)
      conditions.push('Only Regulated Deals Can Complete This Action.');
    if (disabledState.overallStatus?.isNon_Regulated_Only_Required)
      conditions.push('Only Non-Regulated Deals Can Complete This Action.');
    if (disabledState.overallStatus?.isRegulated_Field)
      conditions.push('Can Only Be Sent On Regulated Deals.');

    return messages.concat(conditions).join(' ');
  };

  const buttonDisabled = !isAuth || loading || isDisabled();
  const tooltipTitle = generateTooltipTitle();

  return (
    <Grid item>
      <Tooltip title={tooltipTitle} arrow>
        <span>
          <Button
            onClick={async () => {
              if (!buttonDisabled) {
                setLoading(true);
                await handleRuleClick(rule);
                setLoading(false);
              }
            }}
            variant="contained"
            color="secondary"
            style={{ margin: 1 }}
            disabled={buttonDisabled}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                {isDisabled() && (
                  <Warning
                    style={{
                      color: theme.palette.warning.main,
                      marginRight: theme.spacing(1)
                    }}
                  />
                )}
                {rule.Title}
              </>
            )}
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default EnhancedGridItem;
